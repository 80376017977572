import React from "react";
import headerImg from "../../images/headerImg.jpg";
import logoName from "../../images/logoName.png";
import style from './Header.module.css';


const Header = () => {
    return(
        <header className={style.header}>

            <div className={style.contact}>
                <table border="0" >
                    <tr>
                        <td width="150px"></td>
                        <td> <p > +7 9063-270-442,<br/>
                            +7 9050-210-637<br/>
                            olgagrin@mail.ru</p>
                        </td>
                        <td width="15"></td>
                        <td>
                            <img  src={headerImg} width="320" height="100" align="center"/>
                        </td>

                </tr>
                </table>

            </div>
            <div className={style.russen}>
            <img  src={logoName} width="700" height="60"/>
            </div>

        </header>
    );
}
export default Header;