import React from "react";
import header_right from "../../images/header_right.JPG";
import journal from "../../images/journal.jpg"
import book from "../../images/book.JPG";
import style from './PanelRight.module.css';
import {NavLink} from "react-router-dom";

const PanelRight = () => {
    return(
        <div className={`${style.right} ${style.font}`}>

        <div>
            <img  src={header_right}/>
        </div>
            <div><br></br></div>

            <div className={style.fontLink}><NavLink to="/Book">Книга О.Гринь <br/>"Разведение ротвейлеров.
                <br/>История и современность"<br></br></NavLink></div>
            <div><br/></div>
            <div><img  src={book}/></div>
            <div><br/></div>
            <div>Приобрести <br/>ранее вышедшие номера<br/> журнала "Ротвейлер"</div>
            <div><br></br><img  src={journal} width="245"/></div>
        </div>
    );
}
export default PanelRight;