import React from "react";

import style from "./Articles.module.css";
import {NavLink} from "react-router-dom";
import RottweilerChampionshipInBrazil from "./RottweilerChampionshipInBrazil/RottweilerChampionshipInBrazil";

const Articles = () =>{
    return (
        <div className={style.fontLink}>
        <ul activeClassName={style.activeLink}>
            {/*<li><p className={style.p}>Общие статьи</p></li>*/}
            <li><p className={style.p}>Статьи о выставках</p>
            <ul>
                <li className={style.nested}><NavLink to="Exhibition" >38 выставка Всегерманского Ротвейлер Клуба</NavLink></li>
            </ul></li>
            <li><p className={style.p}>Статьи о питомниках</p></li>
            <ul>
            <li className={style.nested}><NavLink to="AboutNurseries" >Питомник ротвейлеров Schwaiger Wappen (ADRK)</NavLink></li>
            </ul>
            <li><p className={style.p}>Статьи о воспитании и дрессировке</p>
                <ul>
            <li className={style.nested}><NavLink to="/RottweilerEducation" >Воспитание ротвейлером</NavLink></li>
            <li className={style.nested}><NavLink to="Apportirovka" >Аппортировка без вопросов</NavLink></li>
                </ul>
            </li>
            <li><p className={style.p}>Отчеты о моей экспертизе</p></li>
            <ul>
                <li className={style.nested}><NavLink to="RottweilerChampionshipInBrazil" >Чемпионат ротвейлеров в Бразилии «TRIANGULO de OURO 2005» "Золотой треугольник 2005"</NavLink></li>
                <li className={style.nested}><NavLink to="InterviewWithOlgaGrin" >Интервью c Ольгой Гринь. </NavLink></li>
                <li className={style.nested}><NavLink to="NationalRottweilerShowInAustralia" >Национальная выставка ротвейлеров в Австралии 2011 </NavLink></li>
            </ul>
            {/*<li><p className={style.p}>Статьи других авторов</p></li>*/}
        </ul>
        </div>

    );
}
export default Articles;