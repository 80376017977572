import React from "react";
import style from "./Souvenirs.module.css"
import NotFount from "../NotFount/NotFount";

const Souvenirs = () => {
    return(
        <div>
            <NotFount/>

        </div>
    );
}
export default Souvenirs;