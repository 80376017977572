import React from "react";
import RussenPAOLA from "../../../images/dogs/PAOLA.jpeg"
import style from "../Dogs.module.css"
const Paola = () => {
    return(

        <div>
            <div>

            <div>
                <h1 align="center" className={style.H1}>RUSSEN ROTTENSCHILD PAOLA</h1></div>
            <img src={RussenPAOLA} className={style.img}/>
            </div>
            <div>
                <h3 align="center" className={style.H3}>Pedigree</h3>
            </div>
            <div className={style.fontTable} align="right">
                <table border="3" width="900" height= "640">
                    <tbody>
                    <tr>
                        <td rowSpan="8" >
                            <p align="center">
                                CHERNOE BRATSTVO LANCELOT
                                ч-п
                            </p>
                        </td>
                        <td rowSpan="4" >
                            <p align="center">
                                JUDA FLASH ROUSE</p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                MERLIN FLASCH ROUSE
                            </p>
                        </td>
                        <td  height="12">
                            MERLIN FLASCH ROUSE
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            RAMONA V.D. CROSSENER RANCH
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                RAMONA V.D. CROSSENER RANCH</p>
                        </td>
                        <td  height="12">
                            DACK FLASH ROUSE
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            BASRA GERMAN BLOOD LINE
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="4" >
                            <p align="center">
                                CHERNOE BRATSTVO KRALJA
                            </p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                FLEGEL V. TEGLER FLIESS </p>
                        </td>
                        <td  height="12">
                            AMBASSADOR VON SHAMBALA
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            VETZI VOM WOLFERT TURM
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                OLBURD TEKILA </p>
                        </td>
                        <td  height="12">
                            DOLF V.D. BLOKHUTTE
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            INNES
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="8" >
                            <p align="center">
                                RUSSEN ROTTENSCHILD MIRTA</p>
                        </td>
                        <td rowSpan="4" >
                            <p align="center">
                                CH RUS, RKF
                                RUSSEN ROTTENSCHILD PRINZ ARON  ч-п
                            </p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                CHRIS VON BICKESHEIM</p>
                        </td>
                        <td  height="12">
                            ENDY V.D. KURPFALZ
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            SINA V. BIKESHEIM
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                ГЕЙЛ ЧУДНАЯ</p>
                        </td>
                        <td  height="12">
                            ДЖОЙ ЯКУШИН
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            ГЛЕДИС ВУСТИН
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="4" >
                            <p align="center">
                                RUS CH
                                HELLA V.D. CROSSENER RANCH  ч-п
                                Grin O.A.& Donzov V.A.</p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                MAMBO VON DER CROSSENER RANCH  ч-п, </p>
                        </td>
                        <td  height="12">
                            RICK V. BURGHTANN
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            EVI V.D. CROSSENER RANCH
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                KIRA V. MUHLBERG  ч-п</p>
                        </td>
                        <td  height="12">
                            ORLANDO V.. HAUSE NEUBRAND
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            ORLANDO V.. HAUSE NEUBRAND
                        </td>
                    </tr>
                    </tbody>
                </table>

            </div>
        </div>
    );
}
export default Paola;