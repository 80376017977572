import React from "react";
import Logo from "../../images/Logo.png";
import style from './NavBar.module.css';
import {NavLink} from "react-router-dom";
import saleNews from "../../images/saleNews.jpg"

const NavBar = () => {
    return (
        <div className={style.nav}>
                <div className={style.logoImg}><img src={Logo} /></div>
            <div><br/><br/><br/></div>
            <nav>
            <div className={style.fontLink}>
                <NavLink to="/Content" activeClassName={style.activeLink}>Главная</NavLink>
            </div>
            <div className={style.fontLink}>
                <NavLink to="/About" activeClassName={style.activeLink}>О нас</NavLink>
            </div>
            <div className={style.fontLink}>
                <NavLink to="/Dogs" activeClassName={style.activeLink}>Наши собаки</NavLink></div>
            <div className={style.fontLink}>
                <NavLink to="/Champions" activeClassName={style.activeLink}>Наши чемпионы</NavLink></div>
            <div className={style.fontLink}>
                <NavLink to="/Puppies" activeClassName={style.activeLink}>Пометы</NavLink></div>
            <div className={style.fontLink}>
                <NavLink to="/Gallery" activeClassName={style.activeLink}>Галерея</NavLink></div>
            <div className={style.fontLink}>
                <NavLink to="/Sale" activeClassName={style.activeLink}>Продажа</NavLink></div>
            <div className={style.fontLink}>
                <NavLink to="/Articles" activeClassName={style.activeLink}>Статьи</NavLink></div>
            <div className={style.fontLink}>
                <NavLink to="/Story" activeClassName={style.activeLink}>История</NavLink></div>
            <div className={style.fontLink}>
                <NavLink to="/References" activeClassName={style.activeLink}>Наш клуб</NavLink></div>
            <div className={style.fontLink}>
                <NavLink to="/Souvenirs" activeClassName={style.activeLink}>Сувениры</NavLink></div>
            <div className={style.fontLink}>
                <NavLink to="/Сontacts" activeClassName={style.activeLink}>Контакты</NavLink></div>
            </nav>
            <div>
                <h2 className={style.FontNews}>У НАС ЕСТЬ <br/> ЩЕНКИ!</h2>
                <img  src={saleNews} className={style.imgNews}/>
            </div>

        </div>
    );
}
export default NavBar;