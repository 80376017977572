import React from "react";
import style from "./Sale.module.css"
import NotFount from "../NotFount/NotFount";
import {NavLink} from "react-router-dom";
import DOR_ava from "../../images/russen_rottenschild_dorhan.png";
import RUSSEN_ROTTENSCHILD_YAVA from "../../images/RUSSEN_ROTTENSCHILD_YAVA.png";
import VIVA from "../../images/dogs/RR_VIVA.JPG";
import PuppyDorhanYava from "./SaleDog/PuppyDorhanYava";
import SherifrotthausHoward from "../../images/Sherifrotthaus_Howard.JPG";
import DOMINATOR from "../../images/dogs/DOMINATOR.JPG";
import RR_DIAMANTA from "../../images/dogs/RR_DIAMANTA.JPG";

const Sale = () => {
    return(


    <div>
        <div><h2>На данный момент, в продаже щенков нет</h2></div>
        {/*  <div className={`${style.blokName} ${style.blokNameFont}`}>*/}
        {/*    <p align="center">RUSSEN ROTTENSCHILD DOMINATOR & RUSSEN ROTTENSCHILD DIAMANTA</p>*/}

        {/*</div>*/}
        {/*<div className={`${style.blokFont} ${style.fontLinkSale}`}>*/}
        {/*    <NavLink to="/PuppyDOMINATORDIAMANTA" >*/}
        {/*        <p className={style.NEWS} align="center" >Щенков от пары:*/}
        {/*            <br/>щенки родились: 23.02.2021 </p></NavLink>*/}
        {/*</div>*/}
        {/*<div className={`${style.blokFont} ${style.fontLink}`}>*/}
        {/*    <p align="center">*/}
        {/*        <NavLink to="/DOMINATOR" activeClassName={style.activeLink}>RUSSEN ROTTENSCHILD DOMINATOR</NavLink> &*/}
        {/*        <NavLink to="/DIAMANTA" activeClassName={style.activeLink}>RUSSEN ROTTENSCHILD DIAMANTA</NavLink> </p>*/}
        {/*    <img src={DOMINATOR} width="300" height="300" hspace="100"/>*/}
        {/*    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<img src={RR_DIAMANTA} width="300" height="300"/>*/}
        {/*</div>*/}



    </div>
    );
}
export default Sale;