import React from "react";
import style from "../Content/Content.module.css";
import {NavLink} from "react-router-dom";
import SherifrotthausHoward from "../../images/Sherifrotthaus_Howard.JPG";
import Koks from "../../images/dogs/Russen Rottenschild Koks.JPG";
import DOR_ava from "../../images/russen_rottenschild_dorhan.png";
import KRETA from "../../images/dogs/KRETA.JPG";
import KAYRA from "../../images/dogs/KAYRA.JPG";
import RR_KONCORDIA from "../../images/dogs/RR_KONCORDIA.JPG";
import RR_THEODORA from "../../images/dogs/RR_THEODORA.JPG";
import RUSSEN_ROTTENSCHILD_YAVA from "../../images/RUSSEN_ROTTENSCHILD_YAVA.png";
import RussenRottenschildMarylin from "../../images/RussenRottenschildMarylin.jpg";
import RUSSEN_ROTTENSCHILD_PAOLA from "../../images/dogs/RUSSEN_ROTTENSCHILD_PAOLA.png";
import RR_VIVA from "../../images/dogs/RR_VIVA.JPG";
import Doutcher from "../../images/dogs/Doutcher.jpg";
import DOMINATOR from "../../images/dogs/DOMINATOR.JPG";
import HANITA from "../../images/dogs/HANITA.JPG";
import TAIRA from "../../images/dogs/TAIRA.JPG";
import GRIM_AVA from "../../images/dogs/GRIM/GRIM_AVA.JPG";
import DexterHead from "../../images/dogs/Dexter/Dexter_head.JPG";
import Diksy_Head from "../../images/dogs/Diksy/Diksy_Ava.jpg";
import RUSTANA from "../../images/dogs/RUSRANA/russana_head.png";
import RIVA from "../../images/dogs/RIVA/RVA_HEAD.png";
import TEYA from "../../images/dogs/RUSSEN ROTTENSCHILD TEYA/head.jpg";
import daimonHead from "../../images/dogs/daimon(magnum)/head.jpg";


const Dogs = () => {
    return(
        <div >

            <div className={`${style.blokName} ${style.blokNameFont}`}>
                <p align="center" color="white">Кобели
                </p>
            </div>

            {/*Первый блок с кобелями*/}
            <div className={`${style.blokFont} ${style.fontLink}`} >
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <NavLink to="/Dexter" activeClassName={style.activeLink} >DEXTER V.D. WEISSENSTADT</NavLink>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <NavLink to="/magnum" activeClassName={style.activeLink}>RUSSEN ROTTENSCHILD MAGNUM</NavLink>
            </div>
            <div>
                <img src={DexterHead} width="300" height="300" hspace="100"/>
                <img src={daimonHead} width="300" height="300"/>

            </div>

            {/*Второй блок с кобелями*/}
            <div className={`${style.blokFont} ${style.fontLink}`} >

                &nbsp; &nbsp; &nbsp; &nbsp;<NavLink to="/Dorhan" activeClassName={style.activeLink}>RUSSEN ROTTENSCHILD DORHAN</NavLink>
                &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;<NavLink to="/GRIM" activeClassName={style.activeLink} >GRIM JUNIOR MONTAGNA</NavLink>
            </div>
            <div>
                <img src={DOR_ava} width="300" height="300" hspace="100"/>
                <img src={GRIM_AVA} width="300" height="300" />

            </div>
            {/*Второй блок с  кобелями */}

            <div className={`${style.blokFont} ${style.fontLink}`} >



                &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;<NavLink to="/Koks" activeClassName={style.activeLink} >RUSSEN ROTTENSCHILD KOKS</NavLink>
                &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;<NavLink to="/Highlander" activeClassName={style.activeLink}>RUSSEN ROTTENSCHILD HIGHLANDER</NavLink>
            </div>
            <div>
                <img src={Koks} width="300" height="300" hspace="100"/>
                <img src={SherifrotthausHoward} width="300" height="300"/>
                {/*Перенести в архив собак. */}
                {/*<img src={LLOYD} width="300" height="300"/>*/}

            </div>

            {/*Треттий блок с кобелями*/}
            <div className={`${style.blokFont} ${style.fontLink}`} >
                &nbsp;<NavLink to="/Doutcher" activeClassName={style.activeLink} >RUSSEN ROTTENSCHILD DEUTSCHER</NavLink>
                &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;<NavLink to="/DOMINATOR" activeClassName={style.activeLink}>RUSSEN ROTTENSCHILD DOMINATOR</NavLink>
            </div>
            <div>
                <img src={Doutcher} width="300" height="300" hspace="100"/>
                <img src={DOMINATOR} width="300" height="300"/>

            </div>


            <div><br/></div>
            {/*Блок с суками */}
            <div className={`${style.blokName} ${style.blokNameFont}`}>
                <p align="center" color="white">Суки
                </p>
            </div>
            <div className={`${style.blokFont} ${style.fontLink}`} >

                &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;<NavLink to="/THEODORA" activeClassName={style.activeLink} >RUSSEN ROTTENSCHILD THEODORA</NavLink>
                &nbsp; &nbsp;&nbsp; &nbsp;<NavLink to="/Teya" activeClassName={style.activeLink}>RUSSEN ROTTENSCHILD TEYA</NavLink>


            </div>
            <div>
                <img src={RR_THEODORA} width="300" height="300" hspace="100"/>
                <img src={TEYA} width="300" height="300"/>

            </div>
            <div className={`${style.blokFont} ${style.fontLink}`} >

                &nbsp; &nbsp; &nbsp; &nbsp;<NavLink to="/KONCORDIA" activeClassName={style.activeLink} >RUSSEN ROTTENSCHILD KONCORDIA</NavLink>
                &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;<NavLink to="/KRETA" activeClassName={style.activeLink}> RUSSEN ROTTENSCHILD KRETA</NavLink>
            </div>
            <div>
                <img src={RR_KONCORDIA} width="300" height="300" hspace="100"/>
                <img src={KRETA} width="300" height="300"/>
            </div>
            <div className={`${style.blokFont} ${style.fontLink}`} >

                &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;<NavLink to="/Yava" activeClassName={style.activeLink} >RUSSEN ROTTENSCHILD YAVA</NavLink>
                &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;<NavLink to="/Astarta" activeClassName={style.activeLink}>RUSSEN ROTTENSCHILD ASTARTA</NavLink>
            </div>
            <div>
                <img src={RUSSEN_ROTTENSCHILD_YAVA} width="300" height="300" hspace="100"/>
                <img src={RussenRottenschildMarylin} width="300" height="300"/>
            </div>
            <div className={`${style.blokFont} ${style.fontLink}`} >

                &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;<NavLink to="/PAOLA" activeClassName={style.activeLink} >RUSSEN ROTTENSCHILD PAOLA</NavLink>
                &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;<NavLink to="/VIVA" activeClassName={style.activeLink}>RUSSEN ROTTENSCHILD
                INFINITA SEMPRE VIVA</NavLink>
            </div>
            <div>
                <img src={RUSSEN_ROTTENSCHILD_PAOLA} width="300" height="300" hspace="100"/>
                <img src={RR_VIVA} width="300" height="300"/>
            </div>
            <div className={`${style.blokFont} ${style.fontLink}`} >

                &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;<NavLink to="/HANITA" activeClassName={style.activeLink} >RUSSEN ROTTENSCHILD HANITA</NavLink>
                &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;<NavLink to="/TAIRA" activeClassName={style.activeLink}>RUSSEN ROTTENSCHILD TAIRA</NavLink>
            </div>
            <div>
                <img src={HANITA} width="300" height="300" hspace="100"/>
                <img src={TAIRA} width="300" height="300"/>
            </div>

            {/*Четвертый блок с суками*/}
            <div className={`${style.blokFont} ${style.fontLink}`} >
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <NavLink to="/RIVA" activeClassName={style.activeLink} >DRUSSEN ROTTENSCHILD RIVA</NavLink>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <NavLink to="/RUSTANA" activeClassName={style.activeLink}>RUSSEN ROTTENSCHILD RUSTANA</NavLink>
            </div>
            <div>
                <img src={RIVA} width="300" height="300" hspace="100"/>
                <img src={RUSTANA} width="300" height="300"/>

            </div>

            {/*пятый блок с суками*/}
            <div className={`${style.blokFont} ${style.fontLink}`} >
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <NavLink to="/Diksy" activeClassName={style.activeLink} >DRUSSEN ROTTENSCHILD DIKSY</NavLink>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                <NavLink to="/KAYRA" activeClassName={style.activeLink}>RUSSEN ROTTENSCHILD  KAYRA</NavLink>
            </div>
            <div>
                <img src={Diksy_Head} width="300" height="300" hspace="100"/>

                <img src={KAYRA} width="300" height="300"/>

            </div>


        </div>
    );
}
export default Dogs;