import React from "react";
import style from "./References.module.css"
import NotFount from "../NotFount/NotFount";

const References = () => {
    return(
        <div>
            <NotFount/>

        </div>
    );
}
export default References;