import React from "react";
import BookOriginal from "../../images/Book_original.JPG";
import style from "../Book/Book.module.css";

const Book = () => {
    return(
        <div>
        <div className={style.h1}><h1 align="center">Книга</h1></div>
            <div className={style.purchase}> <p>Приобрести книгу можно прислав заявку по email: olgagrin@mail.ru</p></div>
            <div className={style.img}><img src={BookOriginal}/> </div>
            <div className={style.description}><p>&nbsp;&nbsp;&nbsp;&nbsp;Моя книга о разведении ротвейлеров. Анализ исторических фактов и современного состояния породы.
                Расширенная теоретическая часть, сведения о классических тезисах селекции, применительно к разведению
                ротвейлеров. Формат А4. Перепет мягкий, тип обложки лен, матовый цветной, дизайнерская версия. 192 стр,
                черно-белые фотографии. Написана для профи, но надеюсь, что будет интересна и полезна новичкам!
            </p>
            </div>
        </div>
    );
}
export default Book;