import React from "react";
import style from "../Dogs.module.css";
import RussenHighlander from "../../../images/dogs/Sherifrotthaus_Howard.JPG"


const Highlander = () => {
    return(
        <div>
            <div>
                <h1 align="center" className={style.H1}>RUSSEN ROTTENSCHILD HIGHLANDER</h1>
                <img src={RussenHighlander} className={style.img}/>
            </div>

            <div>
                <h3 align="center" className={style.H3}>Pedigree</h3>
            </div>
            <div className={style.fontTable} align="left">
                <table border="3" width="900" height= "640">
                    <tbody>
                    <tr>
                        <td className="parent" valign="middle" align="center">
                            <p>
                                I. generation</p>
                        </td>
                        <td className="parent" valign="middle" align="center">
                            <p>
                                II. generation</p>
                        </td>
                        <td className="parent" valign="middle" align="center">
                            <p>
                                III.generation</p>
                        </td>
                        <td className="parent" valign="middle" align="center">
                            <p>
                                IV. generation</p>
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="8" >
                            <p align="center">
                                Lex vom Hause Edelstein
                            </p>
                        </td>
                        <td rowSpan="4" >
                            <p align="center">
                                Brabus vom Hause Edelstein</p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                Uzi Flash Rouse
                            </p>
                        </td>
                        <td  height="12">
                            Merlin Flash Rouse
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            Tika Flash Rouse
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                Chiquita vom Hause Edelstein</p>
                        </td>
                        <td  height="12">
                            Mambo v.d. Crossener Ranch
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            Boa vom Bonzi Star
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="4" >
                            <p align="center">
                                Cym od Vadanora
                            </p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                Bronx od Vadanora</p>
                        </td>
                        <td  height="12">
                            Gonsalo Buoso Da Dovara
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            Bo od Vadanora
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                Patrissiya from House Rotvis</p>
                        </td>
                        <td  height="12">
                            Morro von der Scherau
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            Doroti from House Rotvis
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="8" >
                            <p align="center">
                                Russen Rottenschild Elma</p>
                        </td>
                        <td rowSpan="4" >
                            <p align="center">
                                Felix vom Haus Zschammer</p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                Cujo von der Burg Weibertreu</p>
                        </td>
                        <td  height="12">
                            Akino von der Lauterbrücke
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            Briska vom Kümmelsee
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                Baccara vom Haus Zschammer</p>
                        </td>
                        <td  height="12">
                            King vom Schwaiger Wappen
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            Bea vom Pellerschloss
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="4" >
                            <p align="center">
                                Maggy </p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                Billy von der Elsteraue</p>
                        </td>
                        <td  height="12">
                            Rasty von Burgthann
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            Eilika von  Elsteraue
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                Blacky </p>
                        </td>
                        <td  height="12">
                            Gray Elit
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            Lotta
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div className={style.font}>
                <p>
                    RUSSEN ROTTENSCHILD HIGHLANDER «ХЕМ» ,
                    РОЖД. 16.05. 2015.
                    ОДИН ИЗ ЯРКИХ ПРЕДСТВАИТЕЛЕЙ НАШЕГО ПИТОМНИКА.
                    ПОЛУЧЕН ОТ ВЯЗКИ НАШЕЙ ПРОИЗВОДИТЕЛЬНИЦЫ
                    RR ELMA В ХОРВАТИИ С ВЫДАЮЩИМСЯ ПРИЗВОДИТЕЛЕМ
                    LEX V. HAUSE EDELSTEIN .
                    HIGHLANDER БЫЛ ОСТАВЛЕН В ПИТОМНИКЕ КАК ЛУЧШИЙ
                    ЩЕНОК.
                    ЕГО РОДОСЛОВНАЯ АУТБРЕДНАЯ, ПОСТРОЕНА НА
                    НАИБОЛЕЕ ПЕРСПЕКТИВНЫХ И СТАБИЛЬНЫХ КРОВЯХ.
                    ХEM ПОЛНОСТЬЮ ОПРАВДАЛ НАШИ ОЖИДАНИЯ, ИМЕЕТ
                    ТИТУЛЫ ЧЕМПИОН РОССИИ И РКФ, СВОБОДЕН ОТ ЛОКТЕВОЙ
                    И ТАЗОБЕДРЕННОЙ ДИСПЛАЗИИ.
                    ХЭМ ОКАЗАЛСЯ УСПЕШНЫМ ПРОИЗВОДИТЕЛЕМ,
                    ОТЛИЧИТЕЛЬНАЯ ЧЕРТА ЕГО ПОТОМКОВ — КРАСИВЫЕ
                    ГОЛОВЫ , КРЕПКОЕ, В МЕРУ МАССИВНОЕ ТЕЛОСЛОЖЕНИЕ,
                    СТАБИЛЬНЫЙ УРАВНОВЕШЕННЫЙ ХАРАКТЕР .
                    ХЕМ ИМЕЕТ ОТЛИЧНЫЕ ХАРАКТЕРИСТИКИ ПРОМЕРОВ И
                    ПИГМЕНТАЦИИ : ВЫСОТА В ХОЛКЕ 67 СМ. ГЛАЗА 1В, ПЯСТЬ
                    14СМ.
                    ХЕМ ДОСТУПЕН ДЛЯ ВЯЗОК С СУКАМИ ИМЕЮЩИХ
                    РОДОСЛОВНУЮ РКФ И ДОПУСК К ПЛЕМЕННОМУ
                    РАЗВЕДЕНИЮ РКФ.

                </p>
            </div>
        </div>
    );

}
export default Highlander;