import React from "react";
import style from "../Dogs.module.css";
import RussenAstarta from "../../../images/dogs/RussenAstarta.jpg"

const Astarta = () => {
    return (
        <div>
                <div>
                    <h1 align="center" className={style.H1}>RUSSEN ROTTENSCHILD ASTARTA</h1>
                    <img src={RussenAstarta} className={style.img}/>
                </div>

            <div>
                <h3 align="center" className={style.H3}>Pedigree</h3>
            </div>
            <div className={style.fontTable} align="right">
                <table border="1" cellPadding="0" width="900" height= "640">
                    <tbody>
                    <tr>
                        <td rowSpan="8" >
                            <p align="center">
                                RUS<br/>
                                CH, RUS JUN CH RKF CH<br/>
                                Russen Rottenschild<br/>
                                ASTARTA II
                            </p>
                        </td>
                        <td rowSpan="4" >
                            <p align="center">
                                Rus CH Chernoe Bratstvo
                                Lancelot
                            </p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                Juda Flasch Rouse</p>
                        </td>
                        <td height="12" >
                            <p>Merlin Flasch Rouse</p>
                        </td>
                    </tr>
                    <tr>
                        <td height="12" >
                            <p>Fuksa German Blood Line</p>
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                Chernoe Bratstvo Kralja</p>
                        </td>
                        <td height="12" >
                            <p>Flegel v. Tegler Fliess</p>
                        </td>
                    </tr>
                    <tr>
                        <td height="12" >
                            <p>Olburd Tekila </p>
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="4" >
                            <p align="center">
                                RUS<br/>
                                CH, RUS JUN CH RKF CH<br/>
                                SHERIFFROTHAUS DOROTY
                            </p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                Russen Rottenschild CАESAR</p>
                        </td>
                        <td height="12" >
                            <p>FELIX  von HAUS ZSCHAMMER</p>
                        </td>
                    </tr>
                    <tr>
                        <td height="12" >
                            <p>Pandora vom Rauberweg</p>
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                Cherny Legion Lira</p>
                        </td>
                        <td height="12" >
                            <p>INDIO OF NOCOLAS LION </p>
                        </td>
                    </tr>
                    <tr>
                        <td height="12" >
                            <p>Cherny Legion Honda  </p>
                        </td>
                    </tr>
                    </tbody>
                </table>

            </div>
        </div>
    );
}
export default Astarta;