import React from "react";
import style from "./Contacts.module.css"

const Сontacts = () => {
    return(
        <div>
            <div>
                <h2 className={style.H2} align="center">
                    Контакты
                </h2>
                <div>
                    <h2 className={style.h2} align="center">Республика Татарстан <br/>город Казань, <br/>ул. Павлюхина д. 100<br/>
                         ул. Ферма 2 д. 76 (Учебный центр)<br/>
                        +7 9063-270-442<br/>
                        +7 9050-210-637<br/>
                        &nbsp;&nbsp;+7 (843)-264-0559<br/>
                        olgagrin@mail.ru</h2>
                </div>
            </div>
        </div>
    );
}
export default Сontacts;