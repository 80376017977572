import React from "react";
import RR_HANITA from "../../../images/dogs/RR_HANITA.JPG"
import style from "../Dogs.module.css"
const HANITA = () => {
    return(

        <div>
            <div>

            <div>
                <h1 align="center" className={style.H1}>RUSSEN ROTTENSCHILD HANITA</h1></div>
                <p align='center'>
            <img src={RR_HANITA} className={style.img}/>
                </p>
            </div>
            <div>
                <h3 align="center" className={style.H3}>Pedigree</h3>
            </div>
            <div className={style.fontTable} align="center">
                <table border="3" width="900" height= "640">
                    <tbody>
                    <tr>
                        <td className="parent" valign="middle" align="center">
                            <p>
                                I. generation</p>
                        </td>
                        <td className="parent" valign="middle" align="center">
                            <p>
                                II. generation</p>
                        </td>
                        <td className="parent" valign="middle" align="center">
                            <p>
                                III.generation</p>
                        </td>
                        <td className="parent" valign="middle" align="center">
                            <p>
                                IV. generation</p>
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="8" >
                            <p align="center">
                                SHERIFROTTHAUS  HOWARD
                                ч-п
                            </p>
                        </td>
                        <td rowSpan="4" >
                            <p align="center">
                                FILOU VOM RAUBERWEG</p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                BRUTUS V RAUBERWEG
                            </p>
                        </td>
                        <td  height="12">
                            CHAMP VON DER SCHERAU
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            ARABELLA VOM  KATZERBACHTAL
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                MONA VON OSTSACHSEN
                            </p>
                        </td>
                        <td  height="12">
                            BEN  V.D. SCHERAU
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            ARANKA VON ILMBRUCKE
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="4" >
                            <p align="center">
                                CHERNY LEGION LIRA  ч-п
                            </p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                INDIO OF NICOLAS LION  ч-п</p>
                        </td>
                        <td  height="12">
                            MAMBO OF  NICOLAS LION
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            WOLGA OF NICOLAS LION
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                CHERNY LEGION HONDA  ч-п</p>
                        </td>
                        <td  height="12">
                            CHERNY LEGION ARMSTRONG
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            HOLGERLAND EVI
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="8" >
                            <p align="center">
                                RUSSEN ROTTENSCILD YUNITA</p>
                        </td>
                        <td rowSpan="4" >
                            <p align="center">
                                CHERNOE BRATSTVO LANCELOT  ч-п</p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                JUDA FLASH ROUSE
                            </p>
                        </td>
                        <td  height="12">
                            MERLIN FLASCH ROUSE
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            FUKSA GERMAN BLOOD LINE
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                CHERNOE BRATSTVO KRALJA  ч-п</p>
                        </td>
                        <td  height="12">
                            FLEGEL V. TEGLER FLIESS
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            OLBURD TEKILA
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="4" >
                            <p align="center">
                                RUSSEN ROTTENSCHILD  CESA</p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                FELIX V. HAUS ZSCHAMMER  ч-п Дипл: SchH -I, II,III, BH  ZTP HD-A ED-I Grin O.A.& Donzov V.A.
                            </p>
                        </td>
                        <td  height="12">
                            CUJO  V.D. BURG WEIBERTREU Дипл: SchHIII
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            BACCARA VOM  HAUS ZSCHAMMER
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                PANDORA VOM RAUBERWEG  ч-п, </p>
                        </td>
                        <td  height="12">
                            MAMBO VON DER CROSSENER RANCH
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            FREYA VOM RAUBERWEG   A. Schmidt
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}
export default HANITA;