import React from "react";
import style from "./Apportirovka.module.css"


const Apportirovka = () => {
    return(
        <div>
            <div ><h1 >Статьи</h1></div>
            <div ><h1 >Аппортировка без вопросов</h1></div>
            <div ><h1 >АППОРТИРОВКА БЕЗ ВОПРОСОВ  Ольга Гринь</h1></div>
            <div  align="right">
                Каждый имеет ту собаку, <br/>которую заслужил. Х.Й. Сваровски
            </div>
            <div className={style.font}>
                <p align="justify">
                    &nbsp;&nbsp;&nbsp;Общеизвестно, что дрессировка собаки требует от человека большого терпения, настойчивости и, 
                    безусловно, специальных знаний. Дрессируя ротвейлера, специальных знаний вам потребуется примерно
                    столько же, сколько потребовалось бы при обучении собаки любой другой породы, а вот терпением и
                    настойчивостью придется, конечно, запастись. Ротвейлеры, как правило, обладают очень сильным
                    характером, они настойчивы и смелы от рождения. Их ярко выраженная индивидуальность и мощное "EGO"
                    частенько ставили в тупик многих владельцев: пытаясь подойти к дрессировке своего подростка-ротвейлера
                    стандартными средствами, они терпели фиаско, а у общественности формировалось мнение о ротвейлере,
                    как об упрямой, тупой и недалекой собаке.  К счастью, это, конечно же, не так. Просто, взявшись за
                    дрессировку ротвейлера, человек должен знать - ни шагу назад, если процесс дрессировки начат, бросать
                    его ни в коем случае нельзя. Зато, обучив и подчинив себе ротвейлера, вы найдете в нем идеального
                    спутника жизни - спокойную, уверенную в себе и близких ему людях, думающую и интеллигентную собаку...
                    Эта статья об обучении собаки аппортировке не случайна.В последнее время появилось огромное количество
                    ротвейлеров без необходимой дрессировки, что ведет к деградации популяции породы в целом. Некоторые
                    владельцы не дрессируют своих питомцев из-за неких ложных принципов: "Мне это не нужно - он (она) и
                    так умный". Другие же, начиная тренировки, бросают их на полпути, столкнувшись с объективными
                    трудностями (кстати, чаще всего с аппортировкой). В любом случае отмечу -дрессировать ротвейлера и
                    сдавать экзамен по послушанию с ним необходимо. Это необходимо в той же мере, что и обучать наших
                    детей в средней школе. Ротвейлер, вовремя не обученный правильному сосуществованию с человеком и
                    подчинению владельцу, может стать большой проблемой для окружающих его людей и животных.  Итак,
                    "апорт". Наиболее сложный в отработке и трудно усваиваемый собакой навык. Ко мне часто обращались
                    и обращаются владельцы, собаки которых идеально лежат, сидят, стоят, подходят "ко мне" и идут вперед
                    или на место, ходят рядом и преодолевают все мыслимые препятствия, но когда дело касается "апорта",
                    собаки преображаются совершенно демоническим образом, отказываются стабильно выполнять сей коварный
                    навык.  Именно на этом этапе у многих владельцев опускаются руки, и они прекращают занятия, так и не
                    узнав, какое удовольствие они могли бы получить от безотказной аппортировки любых предметов своим
                    питомцем.  Как известно, обучение собаки аппортировке проводится двумя способами:  - метод на основе
                    игры;  - принудительный метод. Большинство людей пытаются обучать собак именно через игру, а точнее
                    они предлагают собаке приносить предмет, играя с ним. Именно этот метод трактовался из поколения в
                    поколение в российской системе дрессировки как основной. "Игровая" методика безусловно имеет право
                    на существование, если занятия проводятся с совсем еще юной собакой, или если ваш пес врожденно
                    еистовый аппортировщик. (Кстати, "неистовое" желание аппортировать встречается у ротвейлеров достаточно
                    редко, т. к. у этой породы сильно развит прежде всего инстинкт охраны добычи - "Взял, спрячу, буду
                    охранять".) В связи с этим мне приходилось встречать владельцев ротвейлеров, годами "играющих в апорт"
                    со своими питомцами, но так и не достигших желанной цели. Обучение молодой и более взрослой собаки,
                    тем более ротвейлера, с помощью игры представляется весьма сомнительным занятием по многим причинам:
                    - собака будет приносить предмет, если у нее есть настроение к игре, если нет — возможны любые
                    отклонения от желаемого поведения; - для достижения конечного результата "игровым" методом дрессировщику
                    потребуется неопределенное количество времени — это могут быть месяцы или, в худших случаях, годы!
                    Гораздо более эффективным и доступным является "принудительный" метод, предложенный и описанный
                    немецким кинологом Мостом более 60 лет назад. С тех пор и до наших дней ротвейлеров (и большинство
                    других пород) на западе, в частности в Германии, обучают именно подобным образом. В некоторых
                    оригинальных немецких источниках метод обозначается как "болевой" или "механический". Автор метода
                    советует точно придерживаться последовательности действий и неукоснительно соблюдать их очередность.
                    Лучший возраст для обучения собаки этим методом - от 11 до 18 месяцев. Между занятиями нельзя делать
                    длительных пауз - заниматься следует 2 раза в день с начала до конца обучения. Для начала следует
                    проверить "восприимчивость шеи" животного. Для этого на собаку надевается удавка (мертвое кольцо) и
                    производится резкий рывок вверх. Если собака никак не реагирует на это воздействие, следует повторить
                    то же самое, надев строгий ошейник. В большинстве случаев для этого метода достаточно использования
                    удавки, но иногда встречаются очень жесткие, слабовосприимчивые собаки, в этих случаях стоит прибегнуть
                    к шипованному ошейнику. Обучение проводится строго поэтапно и включает в себя 5 фаз:<br/><br/>

                    &nbsp;&nbsp;&nbsp;1 фаза. Собака сидит слева от обучающего в цепочке (строгом ошейнике) и на коротком поводке.
                    Поводок - в левой руке дрессировщика, а в правой — аппортировочный предмет (наиболее удобна в этом
                    плане деревянная гантель). После короткого и очень жесткого рывка поводком вверх собака открывает
                    пасть. Точно в это время громко подается команда "Апорт!". После того как предмет оказывается в
                    пасти, давление поводка тут же прекращается, и собаку интенсивно хвалят, одновременно поглаживая
                    спинку носа и нижнюю челюсть, заставляя собаку удерживать предмет во рту. Подобный контраст показывает
                    собаке: без апортировочного предмета - боль, с "апортом" - похвала и приятные ощущения. Удерживать
                    предмет в пасти собака должна до 1 минуты. Потом подается команда "Дай", и предмет забирается. За
                    одно занятие следует повторить воздействие 5-8 раз. Когда собака начнет осознанно удерживать предмет
                    в пасти, следует немного подвигаться с ней на поводке "рядом".<br/><br/>

                    &nbsp;&nbsp;&nbsp;Длительность первой фазы в среднем от 3 до 6 дней, но не более одной недели. В конце фазы собака,
                    сидящая рядом с дрессировщиком, по команде "апорт" берет предмет в пасть и удерживает желаемое время.<br/><br/>

                    2 фаза. Строится на первой и является ее продолжением. Поводок чуть натянут вверх в левой руке
                    обучающего. Правой рукой предмет показывают собаке на расстоянии 10 см на высоте ее глаз. Она
                    должна схватить предмет по команде "апорт" самостоятельно.<br/><br/>

                    3 фаза. Обучающий держит собаку за поводок снизу левой рукой, а правой показывает ей предмет в
                    20 - 30 см от земли. Собака должна немедленно схватить предмет по команде "апорт".<br/><br/>

                    4 фаза. Близка по сути к третьей. Поводок провисает свободно. Правой рукой апортировочный предмет
                    удерживается за один конец, другой уже касается земли. Угол между землей и предметом приблизительно
                    45 градусов. По команде "апорт" собака безотказно берет предмет.<br/><br/>

                    &nbsp;&nbsp;&nbsp;5 фаза. Предмет бросают на землю на расстояние 50 см (не более 1м). По команде "апорт" собака берет
                    предмет и подносит владельцу.<br/><br/>

                    &nbsp;&nbsp;&nbsp; Закрепив собаку в пятой фазе, прибегают к усложнению навыка - увеличивают дальность броска предмета,
                    обучают собаку преодолению различных препятствий с предметом и т.д.<br/><br/>

                    &nbsp;&nbsp;&nbsp;При интенсивных тренировках (2 раза в день) этим методом успех приходит обычно через 2 недели,
                    шлифовка и совершенствование завершаются еще через 1-2.<br/><br/>

                    &nbsp;&nbsp;&nbsp;Таким образом, описанный метод обеспечивает владельцу следующие гарантии:<br/><br/>

                    1. Надежная и "чистая" апортировка.<br/><br/>

                    2. Достижение желаемой цели в кратчайшие сроки.<br/><br/>

                    3. Выполнение навыка при любых условиях.<br/><br/>

                    &nbsp;&nbsp;&nbsp; От себя лично могу добавить, что на жестких, с низким порогом агрессивности ротвейлеров, особенно
                    кобелей (были в моей практике и такие) подобный метод влиял подобно смирительной рубашке.
                    "Поставленные" на апортировку этим методом, они становились в целом более покладистыми и
                    управляемыми, а в исполнении навыка зарекомендовали себя просто блестяще.<br/><br/>

                    &nbsp;&nbsp;&nbsp; Вот коротко и все об основных принципах старинного и в то же время очень современного немецкого
                    способа Моста.<br/><br/>

                    &nbsp;&nbsp;&nbsp;Возможно, что у любого владельца и дрессировщика найдутся свои, опробованные и интересные методики,
                    позволяющие достичь оптимального эффекта при обучении. Но если описанный здесь опыт поможет кому-то
                    справиться с неразрешимой ранее проблемой апортировки в кратчайшие сроки - превосходно! Но важно
                    самое главное - никогда не останавливайтесь в работе с собакой на полпути, ваш ротвейлер не простит
                    слабости и лени. И помните - "Вы достойны той собаки, которую заслужили!"
                </p>
            </div>
            <div ><p>Удачи и успехов!<br/>
                Из журнала "Ротвейлер" № 1 ( 13 ) / 2001</p></div>
        </div>
    );
}
export default Apportirovka;