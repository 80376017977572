import React from "react";
import RR_KONCORDIA from "../../../images/dogs/RUSSEN_ROTTENSCHILD_KONCORDIA.jpg";
import style from "../Dogs.module.css";

const RUSSEN_ROTTENSCHILD_KONCORDIA = () => {
    return(
        <div>
            <div>

                <div>
                    <h1 align="center" className={style.H1}>RUSSEN ROTTENSCHILD KONCORDIA </h1></div>
                <img src={RR_KONCORDIA} className={style.img}/>
            </div>
        {/*    <div>*/}
        {/*        <h3 align="center" className={style.H3}>Pedigree</h3>*/}
        {/*    </div>*/}
        {/*<div className={style.fontTable} align="right">*/}
        {/*    <table border="3" width="900" height= "640">*/}
        {/*        <tbody>*/}
        {/*        <tr>*/}
        {/*            <td className="parent" valign="middle" align="center">*/}
        {/*                <p>*/}
        {/*                    I. generation</p>*/}
        {/*            </td>*/}
        {/*            <td className="parent" valign="middle" align="center">*/}
        {/*                <p>*/}
        {/*                    II. generation</p>*/}
        {/*            </td>*/}
        {/*            <td className="parent" valign="middle" align="center">*/}
        {/*                <p>*/}
        {/*                    III.generation</p>*/}
        {/*            </td>*/}
        {/*            <td className="parent" valign="middle" align="center">*/}
        {/*                <p>*/}
        {/*                    IV. generation</p>*/}
        {/*            </td>*/}
        {/*        </tr>*/}
        {/*        <tr>*/}
        {/*            <td rowSpan="8" width="30%" valign="middle">*/}
        {/*                <p>*/}
        {/*                    Felix vom Haus Zschammer</p>*/}
        {/*                <p>*/}
        {/*                    Dt. VDH-Champion, ADRK-Champion ZB-Nr.: 109174,<br/>*/}
        {/*                    HD frei/ED I, BH, AD, ZTP, VPG1,2,3</p>*/}
        {/*            </td>*/}
        {/*            <td rowSpan="4" width="23%">*/}
        {/*                Cujo von der Burg Weibertreu<br/>*/}
        {/*                Dt. VDH-Champion, KS 03</td>*/}
        {/*            <td rowSpan="2" width="24%">*/}
        {/*                Akino von der Lauterbrücke<br/>*/}
        {/*                Dt.VDH-Ch</td>*/}
        {/*            <td width="20%">*/}
        {/*                Mambo von der Teufelsbrücke<br/>*/}
        {/*                Ö-BJS'95</td>*/}
        {/*        </tr>*/}
        {/*        <tr>*/}
        {/*            <td width="20%">*/}
        {/*                Onda von der Teufelsbrücke<br/>*/}
        {/*                BJS'96, Ö-KJS'96</td>*/}
        {/*        </tr>*/}
        {/*        <tr>*/}
        {/*            <td rowSpan="2" width="24%">*/}
        {/*                Briska vom Kümmelsee*/}
        {/*            </td>*/}
        {/*            <td width="20%">*/}
        {/*                Jero vom Kressbach<br/>*/}
        {/*                INT. CH, EJS'88</td>*/}
        {/*        </tr>*/}
        {/*        <tr>*/}
        {/*            <td width="20%">*/}
        {/*                Conny von der Berghalbinsel*/}
        {/*            </td>*/}
        {/*        </tr>*/}
        {/*        <tr>*/}
        {/*            <td rowSpan="4" width="23%">*/}
        {/*                Baccara vom Haus Zschammer<br/>*/}
        {/*                Dt.VDH-Ch</td>*/}
        {/*            <td rowSpan="2" width="24%">*/}
        {/*                King vom Schwaiger Wappen<br/>*/}
        {/*                INT./Dt.VDH-CH</td>*/}
        {/*            <td width="20%">*/}
        {/*                Noris vom Grüntenblick<br/>*/}
        {/*                INT./Dt.VDH-CH., ES'93, KS'91'92, KJS'90</td>*/}
        {/*        </tr>*/}
        {/*        <tr>*/}
        {/*            <td width="20%">*/}
        {/*                Rena vom Schwaiger Wappen*/}
        {/*            </td>*/}
        {/*        </tr>*/}
        {/*        <tr>*/}
        {/*            <td rowSpan="2" width="24%">*/}
        {/*                Bea vom Pellerschloss*/}
        {/*            </td>*/}
        {/*            <td width="20%">*/}
        {/*                Ken vom Schwaiger Wappen<br/>*/}
        {/*                INT./Dt.VDH-CH, WS'94, WJS'91, ES'94, BS'94, KS'93, Ö-BS'93</td>*/}
        {/*        </tr>*/}
        {/*        <tr>*/}
        {/*            <td width="20%">*/}
        {/*                Hexe von Burgthann*/}
        {/*            </td>*/}
        {/*        </tr>*/}
        {/*        <tr>*/}
        {/*            <td rowSpan="8" width="20%" valign="middle">*/}
        {/*                Russen Rottenschild Korsa<br/>*/}
        {/*                RUS j CH</td>*/}
        {/*            <td rowSpan="4" width="21%">*/}
        {/*                Dt/VDH Ch<br/>*/}
        {/*                Olburd Aron</td>*/}
        {/*            <td rowSpan="2" width="27%">*/}
        {/*                Russ Ch /<br/>*/}
        {/*                Russ Club young winner<br/>*/}
        {/*                Chris v. Bickesheim</td>*/}
        {/*            <td width="30%">*/}
        {/*                Dt/VDH Ch<br/>*/}
        {/*                Endy v.d. Kurpfalz</td>*/}
        {/*        </tr>*/}
        {/*        <tr>*/}
        {/*            <td width="30%">*/}
        {/*                Sina v. Bickesheim*/}
        {/*            </td>*/}
        {/*        </tr>*/}
        {/*        <tr>*/}
        {/*            <td rowSpan="2" width="27%">*/}
        {/*                Geil*/}
        {/*            </td>*/}
        {/*            <td width="30%">*/}
        {/*                Torro v. Zimmerplatz*/}
        {/*            </td>*/}
        {/*        </tr>*/}
        {/*        <tr>*/}
        {/*            <td width="30%">*/}
        {/*                Erni v.d. Kohlplatte*/}
        {/*            </td>*/}
        {/*        </tr>*/}
        {/*        <tr>*/}
        {/*            <td rowSpan="4" width="21%">*/}
        {/*                Russ CH<br/>*/}
        {/*                Ingolda</td>*/}
        {/*            <td rowSpan="2" width="27%">*/}
        {/*                Inter CH, Russ, Ukr Ch<br/>*/}
        {/*                ADRK KSZ V2, V2<br/>*/}
        {/*                Olburd Gotz</td>*/}
        {/*            <td width="30%">*/}
        {/*                Waro von Burgthann*/}
        {/*            </td>*/}
        {/*        </tr>*/}
        {/*        <tr>*/}
        {/*            <td width="30%">*/}
        {/*                Germania Flash*/}
        {/*            </td>*/}
        {/*        </tr>*/}
        {/*        <tr>*/}
        {/*            <td rowSpan="2" width="27%">*/}
        {/*                Russ CH<br/>*/}
        {/*                Erle*/}
        {/*            </td>*/}
        {/*            <td width="30%">*/}
        {/*                Ben v. Lerchenfeld*/}
        {/*            </td>*/}
        {/*        </tr>*/}
        {/*        <tr>*/}
        {/*            <td width="30%">*/}
        {/*                Harbina*/}
        {/*            </td>*/}
        {/*        </tr>*/}
        {/*        </tbody>*/}
        {/*    </table>*/}
        {/*</div>*/}
            <div className={style.font}>
                <p>RUSSEN ROTTENSCHILD  KONCORDIA  ( КОРА) Д.Р.25 .04. 2019 (НА ФОТО 14 МЕС )
                    F.  RUSSEN ROTTENSCHILD HIGHLANDER
                    M. RUSSEN ROTTENSCHILD  TEODORA
                    ИНБРИДИНГ  III-III  НА INTER GERMAN, RUSS CHAMPION FELIX VOM  HAUSE ZSCHAMMER
                    КОРА   РОЖДЕНА В НАШЕМ ПИТОМНИКЕ, ИМЕЕТ ПЕРВОКЛАССНУЮ РОДОСЛОВНУЮ , В КОТОРОЙ ПРЕДСТАВЛЕНЫ ЛУЧШИЕ
                    РОИЗВОДИТЕЛИ ЕВРОПЫ.
                    КОРА  ИМЕЕТ ОПТИМАЛЬНЫЕ ХАРАКТЕРИСТИКИ РОСТА  И  КРЕПОСТИ , УРАВНОВЕШЕННЫЙ, СТАБИЛЬНЫЙ ХАРАКТЕР ,
                    ХОРОШО УПРАВЛЯЕМА, ОЧЕНЬ ПОЗИТИВНА В ДРЕССИРОВКЕ, ГОТОВА К  ПРОХОЖДЕНИЮ ЭКЗАМЕНА ПО  ОКД.
                    РОСТ 59 СМ, ПЯСТЬ 13,5 СМ.  ГЛАЗА 2А, ПИГМЕНТАЦИЯ ПАСТИ ТЕМНАЯ.
                    ОЧЕНЬ ЯРКИЙ  НАСЫЩЕННЫЙ ПОДПАЛ ЧЕТКОГО РИСУНКА.
                </p>
            </div>

        </div>
    );
}
export default RUSSEN_ROTTENSCHILD_KONCORDIA;