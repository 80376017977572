import React from "react";
import style from "./AboutNurseries.module.css";


const AboutNurseries = () => {
    return(
        <div>
            <div ><h1 align="center">Статьи</h1></div>
            <div ><h3>Питомник ротвейлеров Schwaiger Wappen (ADRK)</h3></div>
            {/*<div ><h3>19-20 августа 2006 г.<br/>Окружная группа Alb-Donau, Riedlingen, Германия</h3></div>*/}

            <div className={style.font}>
                {/*<p align="justify">*/}
                <p >
                    &nbsp;&nbsp;&nbsp;Швайгер Ваппен. Имя знакомое всем, кто интересуется породой ротвейлер. История Schwaiger Wappen
                    насчитывает больше трех десятков лет успешного разведения ротвейлеров. Имя питомника было
                    зарегистрировано в начале 70-х годов нюрнбергским мясником Ксавьером Майкснером. История ротвейлеров
                    из Schwaiger Wappen началась с приобретения суки ротвейлер Anka vom LohauserholzAnka vom Lohauserholz (1972).
                    Она происходила от сильнейших ротвейлеров-производителей того времени Dack von der Meierei и Tilla vom Kursaal.<br/><br/>

                    &nbsp;&nbsp;&nbsp;Портрет Анки, выполненный маслом, украшает сегодня стену гостиной семейства Майкснеров. Под ним
                    стоит надпись "Анка Лохаузерхольц, прародительница питомника". Выставочная карьера Anka vom
                    Lohauserholz сложилась очень удачно. В 1974 году в возрасте двух лет она завоевывает титул
                    победительницы ротвейлер-клуба ADRK в Ротвайле. Интересно, что ее родная сестра Asta vom
                    Lohauserholz становится двукратной чемпионкой ротвейлер-клуба ADRK в 1976 и 1977 годах.<br/><br/>

                    &nbsp;&nbsp;&nbsp;Первый помет ротвейлеров был получен в Schwaiger Wappen от Анки и Attila von der Vierten Donaubrücke
                    в 1974 году. Из него Майкснер оставляет себе щенка - суку Anja vom Schwaiger Wappen. Позднее
                    питомник пополнит еще одна дочь Анки - Froni vom Schwaiger Wappen (1978). Но первой настоящей
                    величайшей удачей питомника стал D-помет, появившийся на свет 17 октября 1976 года. Это была первая
                    вязка Anja vom Schwaiger Wappen и известного производителя Ives Eulenspiegel - основоположника целой
                    линии ротвейлеров. ротвейлер Dingo vom Schwaiger WappenИменно от этого сочетания произошел один из
                    основных продолжателей линии Ives Eulenspiegel - легендарный Dingo vom Schwaiger Wappen. При жизни
                    Dingo имел высшие титулы Интерчемпиона, Чемпиона Европы 1982, Германии 1980 и 1981, Чемпиона
                    ротвейлер-клуба ADRK 1980, пожизненный керунг. Dingo, также как и его братья по матери из предыдущего
                    С-помета, создал питомнику ротвейлеров очень хорошую репутацию, которую Schwaiger Wappen удается
                    поддерживать до начала XXI века.<br/><br/>

                    &nbsp;&nbsp;&nbsp;Удачным сочетанием стал в 1981 году К-помет от австрийского ротвейлера Cork von der Hahnenbalz и
                    Froni vom Schwaiger Wappen. Из этого помета произошел Karo vom Schwaiger Wappen
                    (Чемпион Германии по FH 1985), основатель маточного поголовья питомника ротвейлеров Burgthann).<br/><br/>

                    &nbsp;&nbsp;&nbsp;В конце 70-х начинается тесное сотрудничество Schwaiger Wappen со знаменитым питомником ротвейлеров
                    Zimmerplatz. Так в 1979 году Ксавьер Майкснер берет в свой питомник суку Itta vom Zimmerplatz: дочь
                    Cuno vom Schwaiger Wappen и великолепной выставочной модели тех лет Assy vom Haugenfeld
                    (Чемпионка ротвейлер-клуба ADRK 1979 и юной Чемпионки ADRK 1978). Судьба Итты сложилась уникально -
                    этой собаке суждено было стать знаковой фигурой в жизни питомника ротвейлеров Schwaiger Wappen.
                    ротвейлер Itta vom ZimmerplatzВ двухлетнем возрасте Itta vom Zimmerplatz становится Чемпионкой мира
                    (1981), в 1982 году - Чемпионкой ротвейлер-клуба ADRK и Интерчемпионкой.<br/><br/>

                    &nbsp;&nbsp;&nbsp;Itta vom Zimmerplatz была крупной впечатляющей ротвейлершей (рост 61 см, вес 45 кг). Итта имела
                    большой успех и популярность у судей и любителей породы ротвейлер. В 1981 году Итта родила свой
                    первый помет от Dingo vom Schwaiger Wappen. Из этого сочетания в питомнике осталась Nancy vom
                    Schwaiger Wappen, удачная во всех отношениях матка. Сочетание Динго x Итта дублировалось позднее
                    еще 2 раза - это выдающиеся О-помет 1982 года (Orfeus, Osco vom Schwaiger Wappen,
                    Olfa vom Schwaiger Wappen) и S-помет 1983 года (Santo vom Schwaiger Wappen, Simmy, Sandra, Solvay,
                    Sita, Sascha). Сочетание Dingo и Itta можно назвать "золотой" гениально подобранной парой ротвейлеров
                    прошедшего века, так как потомки, полученные в результате этой комбинации, оказали и продолжают
                    через более чем 15 поколений оказывать мощное положительное влияние на породу ротвейлер.<br/><br/>

                    &nbsp;&nbsp;&nbsp;Потомки Динго и Итты уже тогда, в начале 80-х обладали всем набором качеств настоящего ротвейлера,
                    которые мы хотим видеть и сейчас в плодах современной селекции - это были крупные, костистые,
                    объемные собаки с хорошими углами поясов и отличной психикой. Большинство потомков Динго и Итты
                    имели успешно сданный керунг, а наиболее титулованным из них стал легендарный ротвейлер Santo vom
                    Schwaiger WappenSanto vom Schwaiger Wappen - Чемпион Мира 1986 и Интерчемпион (впоследствии Санто
                    передаст эстафету чемпионства своему великому сыну - Чемпиону Мира и Европы 1990, Чемпиону
                    Германии 1987 - Benno von der Schwarzen Heide).<br/><br/>

                    &nbsp;&nbsp;&nbsp;Не менее известны были в свое время ротвейлеры Orfeus и Osco vom Schwaiger Wappen - они оставили
                    обширное потомство отличного качества. Сука Simmy vom Schwaiger Wappen стала одной из важнейших
                    производительниц питомника ротвейлеров Zimmerplatz, дав миру замечательных производителей Torro и
                    Timm vom Zimmerplatz. Впоследствии Итта дала Х-помет 1986 года от Erasmus vom Mägdeberg. Сука
                    Xenia vom Schwaiger Wappen из этого помета оказала большое влияние на формирование известного
                    питомника ротвейлеров Oberhausener Norden (она мать знаменитого ротвейлера Greif vom Oberhausener
                    Norden). Последние 2 помета Итта родила от Hassan vom Königsgarten. Наиболее известной сукой из
                    помета 1986 года стала Zecke vom Schwaiger Wappen (Чемпионка Европы и Австрии, Интерчемпионка).<br/><br/>

                    &nbsp;&nbsp;&nbsp; Итта закончила свою племенную деятельность в 1987 году, дав 6 великолепных по качеству пометов.
                    В доме семейства Майкснеров до сих пор бережно хранятся реликвии - изобилие кубков Итты и ее лучшие
                    фото. Но лучшим памятником этой замечательной суке еще при жизни стали ее потомки, в которых она
                    живет до сих пор.<br/><br/>

                    &nbsp;&nbsp;&nbsp;ротвейлер Karo vom Schwaiger Wappen В питомнике была оставлена дочь Динго и Итты из первого помета
                    Нэнси. Это (также как и ее родители) была достаточно крупная и импозантная сука (она имела высоту
                    в холке 61,5 см и вес 45 кг), от которой Майкснер получил 6 пометов, три из которых от хорошо
                    известного Karo vom Schwaiger Wappen и два от не менее знаменитого Hassan vom Königsgarten.
                    Громких ротвейлеров от Нэнси получено не было, однако из пометов Каро и Нэнси в питомнике были
                    оставлены суки Vicky и Vinny. В 1987 году в успешном сочетании Hassan vom Königsgarten и Vicky
                    vom Schwaiger Wappen на свет появляется D-помет, из которого вышли замечательные ротвейлеры -
                    кобели Danjo vom Schwaiger Wappen, Don vom Schwaiger Wappen, Dingo II и Dux, а также суки Donna и
                    Dina. Наибольшую известность получил Danjo vom Schwaiger Wappen (Чемпион Ротвейлер-клуба ADRK 1989
                    и Чемпион Германии 1989, давший в свою очередь плеяду отличных ротвейлеров по всему миру.<br/><br/>

                    &nbsp;&nbsp;&nbsp;ротвейлер Ken vom Schwaiger Wappen Дубль F-помет 1988 года от Hassan и Vicky дал в основном
                    интересных сук. 1990 год стал не менее удачным для племенной карьеры Vicky - от Benno von der
                    Schwarzen Heide (в инбридинге 2-2 на родительскую пару Динго - Итта через Санто и Нэнси) Vicky
                    рождает К-помет, откуда происходит очень титулованный ротвейлер топ-кобель Ken vom Schwaiger Wappen.
                    Ken был очень красивым ротвейлером, экстерьер которого многие специалисты рассматривали как
                    идеальную модель современного стандарта ротвейлера - хороший средний рост, очень крепкий во всех
                    деталях костяка и связочного аппарата, пластично двигающийся, идеально пигментированный, он
                    производил впечатление того желанного для многих селекционеров баланса костяка, анатомии поясов и
                    породности, который и отличает ротвейлера чемпиона от простого ротвейлера. В возрасте 1 года Ken
                    vom Schwaiger Wappen стал Юным Чемпионом Мира 1991, в 3 года - Чемпионом Ротвейлер-клуба ADRK и
                    Австрийского Ротвейлер-клуба, а в 4 года получил титулы Чемпион Мира, Чемпион Германии, Чемпион
                    Европы, Интерчемпион. Позднее Ken был продан в Швецию, где он получил титулы Чемпиона Швеции,
                    Норвегии, Дании.<br/><br/>

                    &nbsp;&nbsp;&nbsp;Год спустя Майкснер делает дубль-помет Benno и Vicky, но столь блестящего результата не получает.
                    В 1992 году Vicky дает свой последний помет - от Doc von der Teufelsbrücke (сына Benno von der
                    Schwarzen Heide), от этой пары в питомнике остается сука Rena vom Schwaiger Wappen. ротвейлер
                    Benno von der Schwarzen HeideВ начале 90-х годов питомник ротвейлеров Schwaiger Wappen начинает
                    интенсивно сотрудничать с начинающим, но уверенно набирающим обороты питомником ротвейлеров
                    Teufelsbrücke. Происходит плодотворный обмен щенками ротвейлеров. Тогда к семейству Майкснеров
                    переходят суки Claudia von der Teufelsbrücke (Benno von der Schwarzen Heide x Golda vom Sonnenberg)
                    и Dorle von der Teufelsbrücke (однопометница Doc von der Teufelsbrücke). Приблизительно в это же
                    время в питомник поступила Bessy vom Talblick - еще одна дочь Benno от Holli vom Amselhof. Таким
                    образом, к середине 90-х годов питомник ротвейлеров Schwaiger Wappen был полностью укомплектован
                    исключительно дочерьми всемирно известного ротвейлера Benno von der Schwarzen Heide, именно на них
                    строилось то поголовье, на котором питомник стал работать дальше.<br/><br/>

                    &nbsp;&nbsp;&nbsp;Из наиболее значительных сочетаний середины 90-х годов явились сочетания Bessy vom Talblick с Willi
                    vom Hause Neubrand (питомник ротвейлеров Haus Neubrand) и известного ротвейлера Greif vom
                    Oberhausener Norden. ротвейлер Aki vom Schwaiger Wappen IIВ 1994 году партнером Bessy vom Talblick
                    стал Greif vom Oberhausener Norden (сын известного своими рабочими качествами ротвейлера Ken vom
                    Sternbogen и вышеупомянутой Xenia vom Schwaiger Wappen). От этого сочетания была получена известная
                    Интерчемпионка и Чемпионка Германии Aki vom Schwaiger Wappen II, которая стала одной из основных
                    производительниц питомника. В это же году от Dorle von der Teufelsbrücke и популярного
                    ротвейлера-производителя Orso vom Kressbach был получен С-помет, из которого в питомнике ротвейлеров
                    Schwaiger Wappen остается роскошная сука Cleo vom Schwaiger Wappen, а в Венгии используется в
                    разведении ее родной брат Charly vom Schwaiger Wappen.<br/><br/>

                    &nbsp;&nbsp;&nbsp;В 1996 году имя питомника ротвейлеров Schwaiger Wappen переходит к дочери Ксавьера Майкснера -
                    Клаудии, а разросшуюся племенную базу "делят" между собой уже три родственных питомника из города
                    Швайг - непосредственно Schwaiger Wappen (вл. Клаудиа Майкснер), Schwaiger Rathaus
                    (вл. Ксавьер и Лени Майкснер) и Schwaiger Schloss. ротвейлер King vom Schwaiger WappenНаиболее
                    удачными пометами конца 90-х стали пометы от Noris vom Grüntenblick и Rena vom Schwaiger Wappen -
                    хорошо был известен сын этой пары King vom Schwaiger Wappen, а также помет 1997 года от Aki vom
                    Schwaiger Wappen II и Brutus von der Hammerschmiede - оттуда был получен Чемпион Мира 2000 года
                    ротвейлер Onex vom Schwaiger Wappen. Также были получены потомки от одного из наиболее известных
                    ротвейлеров современности Rick von Burgthann и Pascha vom Obergrombacher Schloss.<br/><br/>

                    &nbsp;&nbsp;&nbsp;За долгие годы работы в питомнике ротвейлеров Schwaiger Wappen были выращены десятки выдающихся
                    ротвейлеров наших дней. Это как минимум 5 Чемпионов Мира, 6 Чемпионов Ротвейлер-клуба ADRK, 8
                    Чемпионов Европы, 10 Интерчемпионов, не считая чемпионов других стран, там, где живут и радуют
                    глаз питомцы семейства Майкснеров. Ротвейлеры из Schwaiger Wappen стояли у истоков многих сильнейших
                    племенных центров Германии и мира, его ротвейлеры в свое время серьезно повлияли на выбор известных
                    ныне заводчиков Burgthann (первым ротвейлером Эрвина Бушля был Karo vom Schwaiger Wappen),
                    Hirschenrangen (именно у семейства Ридель жил и побеждал легендарный ротвейлер Dingo vom Schwaiger
                    Wappen), Oberhausener Norden (сука Xenia vom Schwaiger Wappen была долгие годы лучшей
                    производительницей этого питомника ротвейлеров).<br/>
                </p>
            </div>
            <div ><p>Ольга Гринь </p></div>
        </div>
    );
}
export default AboutNurseries;