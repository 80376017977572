import React from "react";

const NationalRottweilerShowInAustralia = () => {
    return(
        <div>
            <div ><h1 align="center">Статьи</h1></div>
            <div ><h3>Национальная выставка ротвейлеров в Австралии 2011<br/>
                WWW.rottweiler1.ru | Ольга Гринь </h3></div>
            <div ><h3> Национальная выставка ротвейлер клуба<br/> Австралии 2011г. Lancefield park , Melbourn, Victoria, 27-31 октября 2011г.</h3></div>
            <div ><h3>Судья Ольга Гринь</h3></div>

            <div>
                <p>&nbsp;&nbsp;&nbsp;Так сложилось, что мои австралийские поездки приходились на пасмурную русскую осень, и тем более
                    приятно было перенестись хотя бы и ненадолго в вечное лето южного полушария. Национальная выставка
                    ротвейлер клуба Австралии проводилась вблизи Мельбурна, штат Виктория, одного из крупнейших городов
                    континента. Главная выставка ротвейлеров этой страны была учреждена в 1980 году и в этом году
                    проводилась в 21 раз. Специально для проведения мероприятия был арендован спортивный комплекс
                    в пригороде Мельбурна Lancefield. Заявлено 250 участников, большинство из которых представляли
                    крупнейшие питомники страны из различных регионов страны. Выставка стартовала в пятницу,
                    28 октября, были представлены все классы молодняка до 12 месяцев, кобели и суки. Следует отметить,
                    что Aвcтралийская система деления собак по классам отличается от европейской, здесь отсутствуют
                    классы рабочий и чемпионов, зато есть класс Australian breed – для собак рожденных на этом континенте.
                    Таким образом все, даже самые титулованные собаки выставляются в одних классах с новичками, что
                    в целом является интересным процессом, показывающим основные тенденции развития поголовья.
                    В сравнение на лучшего представителя пола выходят все победители классов, включая младших щенков,
                    юниоров и старших классов.<br/>
                    &nbsp;&nbsp;&nbsp;Качество и ринговая подготовка лидирующего молодняка впечатлили. Наибольшее количество участников
                    собралось в классах щенков кобелей и сук minor pappy dogs (14) и minor pappy bitches (17) .
                    Победителями этих классов стали однопометники, семимесячные брат и сестра - кобель Ubersein
                    Demolition Man & сука Ubersein Drop Dead Gorgeous (Gandalf Marstal+Ubersein Va Va Voom). Несомненно
                    яркие представители породы, в очень хорошем стиле, гармонично развитые, крепкие и компактные с
                    отличным костяком в очень хорошем балансе для их возраста. Приятно, что 2, 3, 4 места в классе
                    кобелей так же распределились между братьями – однопометниками победителя, которые обладали
                    сходным предпочтительным типом. Эти щенки были получены в результате процесса искусственного
                    осеменения, имеющего в Австралии широчайше распространение и позволяющего работать с кровями
                    известных производителей всего мира, в условиях жесткого карантина, по прежнему принятому в этой стране.
                    Победители в классах старших щенков кобель Blickscharf the Scoundrel & сука Blickscharf Sascha
                    (Ubersein Veni Vidi Vici+ Blickscharf Kessi) также оказались однопометниками, выдержанными в
                    одном типе и показавшие наиболее перспективные движения.<br/>
                    &nbsp;&nbsp;&nbsp; На субботу 29 октября, была назначена церемония официального открытия выставки, с приветствием к
                    участникам обратился президент ротвейлер клуба Австралии John Mac Donald, прозвучали гимны
                    России и Австралии. Был проведен блестяще организованный парад ротвейлеров - ветеранов, где о
                    жизни каждого из них была рассказана целая история. Безусловно церемония открытия никого не
                    оставила равнодушным, стадион то и дело оглашался громкими аплодисментами и подбадривающими
                    возгласами зрителей. В субботу экспертизу прошли все классы сук, а в воскресенье - кобелей.
                    Юными победителями австралийского РК также стали брат и сестра Svawill Quade& Svawill Questra
                    ( Svawill Logan+Svawill Mani), имеющие очень сходный ярко выраженный породный тип и выдающийся
                    темперамент. Их преимущество в классах было вполне очевидно. Судейство в старших классах как
                    кобелей, так и сук не было нелегким делом, большинство собак высокого экстерьерного уровня,
                    оцененных на «отлично». Небольшие группы в различных классах получившие очень хорошо и хорошо
                    имели в основном проблемы с типичностью, кондицией, пигментацией и или проявлением нехарактерного
                    темперамента. Победителями выставки стали кобель Ubersein Veni Vidi Vici & сука Ubersein Va Va
                    Voom (Wenno of Nicolas Lion+Ubersein Pucker Up), выиграв в нелегкой борьбе с резервными победителями
                    Oberschoen Black Asch ( Blicksharf Aschke+ Obershoen Absolute) и Dellahar Imme ( Vom Hause Zillas
                    Arkon+Willemstdt Slick Shik). Для абсолютного победителя BIS и ЛПП кобеля Ubersein Veni Vidi Vici
                    (с символичной кличкой в переводе с латинского – «Пришел, увидел, победил»), это значительная
                    победа, к которой он шел несколько лет. Несмотря на то, победители выставки имеют аутбредную
                    родословную, их экстерьер очень однотипен. В родословной этих собак не имеется инбридинга в
                    переделах 5 колен, и она построена на комбинации кровей широко известных европейских и
                    австралийских производителей. Победители АРК 2011 выдержаны в классическом стиле, правильного
                    роста и сложения, с достаточно сильной костью и хорошо развитой мускулатурой, имеют прочный
                    связочный аппарат и очень хорошие углы конечностей. Головы нормального рельефа с четко
                    выраженными породными деталями, очень темными глазами. Их отличает идеальное строение линии верха,
                    что как правило является «слабой» ( в прямом и переносном смысле ) стороной экстерьера нашей породы.
                    Точные пропорции габаритов корпуса и углов конечностей обеспечили этим собакам быстрые и уверенные
                    движения выдающегося баланса. Я отметила экстерьер этих ротвейлеров, как весьма стабильный и
                    предпочтительный на данном этапе селекции породы.<br/>
                    &nbsp;&nbsp;&nbsp;Феноменом этой выставки лично для меня стала стабильность побед в смежных классах (Щенки, подростки,
                    юниоры, австралийского разведения) однопометников. Эти собаки, происходящие от разных сочетаний
                    продемонстрировали удивительную однотипность и индивидуальный стиль. Интересно, что большинство
                    лидеров получены в аутбредных или топкроссовых вязках, являющимися довольно рискованными
                    инструментами подбора, но показывающие стабильные положительные результаты на данном племенном
                    материале. Для меня это качество является свидетельством сильных, продуманных программ селекции
                    в ведущих питомниках континента.<br/>
                    &nbsp;&nbsp;&nbsp;Характеристика выставки была бы неполной, если не отметить сильные и слабые стороны популяции.
                    К преимуществам стоит отнести общую стабильность поголовья – ротвейлеры Австралии представляют
                    разные, но в большинстве случаев положительные породные типы. Не наблюдалось аномалий с прикусами
                    и отсутствием зубов, статистически большинство экспонентов имели хороший средний или небольшой рост.
                    Темперамент и характер заслуживают самой высокой оценки. Так же я была приятно удивлена
                    значительным улучшением ситуации с поставом передних конечностей, случаи размета были редки,
                    но следует обратить внимание на улучшение постава локтей. Движения в большинстве случаев были
                    типичные, с правильной амплитудой и не вызвали серьезных замечаний.<br/>
                    &nbsp;&nbsp;&nbsp;Недостатки были выражены незначительно, их наличие является нормальным процессом селекционной
                    работы. На мой взгляд стоит обратить внимание на типичность голов – множество экспонентов имели
                    слабо заполненные головы небольшого объема и слабого рельефа. Не стоит забывать, что ротвейлер -
                    молосс и голова должна быть весомой деталью в его экстерьере. Следует уделить внимание
                    пигментации пасти – эта проблема остро стоит в данной популяции. Я бы отметила необходимость
                    селекции цвета глаз, мне встретились много осветленых или светлых ( до желтого оттенка). И хотя
                    пигмент пасти и цвет глаз это косметические недостатки, они значительно влияют на эстетическое
                    впечатление и суммарную оценку собаки. Проблемы корпуса – длинноватые спины и поясницы, плоские
                    ребра. Отмечу, что проблемой для меня стал рабочий момент осмотра зубной системы – многие собаки
                    были не подготовлены к этому процессу, упрямились, чем создавали очевидные задержки экспертизы.
                    Показ в стойке и движении мне понравился, была видна тщательная подготовка собак.<br/>
                    &nbsp;&nbsp;&nbsp;Работа профессиональных хендлеров была выше всяких похвал.
                    31 октября были приняты тесты на пригодность к разведению, что также стало интересными для меня
                    событием. Изменения в подходах к оценке психики ротвейлера, уже действует в Австралии.
                    Стандартный тест (промеры, взвешивание, описание, отношение к выстрелу, социализация) заканчивается
                    проверкой поведения собаки по отношению к чужаку не с помощью покусов рукава фигуранта,
                    а посредством выявления преобладающих реакций ( агрессия, безразличие, боязнь). На мой взгляд
                    это наиболее сбалансированная система, которая хорошо работает и не ставит ротвейлера в один
                    ряд с социально опасными породами, дрессируемых специально на покусы и нападение. В этот день
                    тестирование прошло успешно для всех сдающих.<br/>
                    &nbsp;&nbsp;&nbsp;В заключение хочу поблагодарить Национальный Ротвейлер Клуб Австралии и Региональный Ротвейлер
                    Клуб Виктории за приглашение провести экспертизу на этой выставке. Особая признательность всей
                    ринговой бригаде и моим помощникам в ринге Andrew Kingdom and Kareen Green. Выставка была отлично
                    организована и проведена на высоком уровне. Очень понравились красивые призы и розетки от
                    оргкомитета для победителей. Я благодарна всем заводчикам и владельцам, представивших своих
                    ротвейлеров на мою экспертизу, многие из которых прибыли за тысячи километров! Также хочу
                    особенно отметить спортивный дух участников и очень приятную дружескую атмосферу на выставке,
                    мне очень приятно, что мои судейские решения были поняты и неизменно принимались с вежливым
                    достоинством. Вне сомнения, это событие стало для меня одним из наиболее ярких, интересных и
                    значимых в моей судейской истории. И сама выставка и общение с энтузиастами нашей любимой породы
                    оставили незабываемые позитивные впечатления.<br/><br/>
                    Best regards, Olga Grin</p>
            </div>
        </div>
    );
}
export default NationalRottweilerShowInAustralia;