import React from "react";
import style from "../Dogs.module.css"
import TeyaStand from "../../../images/dogs/RUSSEN ROTTENSCHILD TEYA/stand.png";
import TeyaStand2 from "../../../images/dogs/RUSSEN ROTTENSCHILD TEYA/stand_2.png";
import TeyaStand1 from "../../../images/dogs/RUSSEN ROTTENSCHILD TEYA/stand_1.png";
import TeyaSit from "../../../images/dogs/RUSSEN ROTTENSCHILD TEYA//sit.JPG";

const Teya  = () => {
    return (
        <div>
            <div>

                <div>
                    <h1 align="center" className={style.H1}>RUSSEN ROTTENSCHILD TEYA</h1></div>
                <img  src={TeyaStand} className={style.img} />
            </div>
            <div>
                <h3 align="center" className={style.H3}>Pedigree</h3>
            </div>
            <div className={style.fontTable} align="left" >
                <table border="3" width="900" height= "640">
                    <tbody>
                    <tr>
                        <td className="parent" valign="middle" align="center">
                            <p>
                                I. generation</p>
                        </td>
                        <td className="parent" valign="middle" align="center">
                            <p>
                                II. generation</p>
                        </td>
                        <td className="parent" valign="middle" align="center">
                            <p>
                                III.generation</p>
                        </td>
                        <td className="parent" valign="middle" align="center">
                            <p>
                                IV. generation</p>
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="8" >
                            <p align="center">
                                RUSSEN ROTENSCHILD YUREN
                            </p>
                        </td>
                        <td rowSpan="4" >
                            <p align="center">
                                SHERIFROTTHAUS  HOWARD  ч-п</p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                FILOU VOM RAUBERWEG
                            </p>
                        </td>
                        <td  height="12">
                            BRUTUS V RAUBERWEG
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            MONA VON OSTSACHSEN
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                CHERNY LEGION LIRA  ч-п
                            </p>
                        </td>
                        <td  height="12">
                            INDIO OF NICOLAS LION
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            CHERNY LEGION HONDA
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="4" >
                            <p align="center">
                                RUSSEN ROTTENSCHILD MARYLIN
                            </p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                CHERNOE BRATSTVO LANCELOT  ч-п</p>
                        </td>
                        <td  height="12">
                            JUDA FLASH ROUSE
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            CHERNOE BRATSTVO KRALJA
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                SHERIFROTTHAUS  VELIKSA</p>
                        </td>
                        <td  height="12">
                            FELIX V. HAUS ZSCHAMMER Дипл: SchH -I, II,III, BH  ZTP HD-A ED-I Grin O.A.& Donzov V.A.
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            CHERNY LEGION LIRA
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="8" >
                            <p align="center">
                                RUSSEN ROTTENSCHILD  DINA</p>
                        </td>
                        <td rowSpan="4" >
                            <p align="center">
                                Inter,German, Rus CH
                                FELIX V. HAUS ZSCHAMMER  ч-п Дипл: SchH -I, II,III, BH  ZTP HD-A ED-I
                                Племкласс: Элита
                                Grin O.A.& Donzov V.A.</p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                CUJO  V.D. BURG WEIBERTREU  ч-п Дипл: SchHIII
                            </p>
                        </td>
                        <td  height="12">
                            AKINO VON DER LAUTERBRUCKE
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            BRISKA VOM KUMMELSEE
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                BACCARA VOM  HAUS ZSCHAMMER  ч-п</p>
                        </td>
                        <td  height="12">
                            KING VOM SCHWAIGER WAPPEN
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            BEA VOM PELLERSCHLOSS
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="4" >
                            <p align="center">
                                RUSSEN ROTTENSCHILD  NAVA</p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                RUSSEN ROTTENSCHILD PRINZ ARON  ч-п
                            </p>
                        </td>
                        <td  height="12">
                            Ч.OLBURD ARON Дипл: HD-
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            ВИКА КОМАР О
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                PANDORA VOM RAUBERWEG  ч-п, </p>
                        </td>
                        <td  height="12">
                            MAMBO VON DER CROSSENER RANCH
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            FREYA VOM RAUBERWEG   A. Schmidt
                        </td>
                    </tr>
                    </tbody>
                </table>
                <br/>
                <br/>
            </div>


             <div>
                <img src={TeyaStand1} className={style.img}  /><br/><br/>
                <img src={TeyaStand2} className={style.img}  /><br/><br/>
                <img src={TeyaSit} width="1046" height="1129"/>

            </div>


        </div>
    );
}
export default Teya ;