import React from "react";
import style from './Content.module.css'
import DORHAN from "../../images/dogs/RUSSEN_ROTTENSCHILD_DORHAN.jpg"
import {NavLink} from "react-router-dom";
import DexterHead from "../../images/dogs/Dexter/Dexter_head.JPG";
import TEYA from "../../images/dogs/RUSSEN ROTTENSCHILD TEYA/head.jpg";
import Nayada from "../../images/video/Nayada.mp4";

const Content = () => {
    return (
        <div>

            {/*<div className={`${style.blokName} ${style.blokNameFont}`}>*/}
            {/*    <p align="center">DEXTER V.D. WEISSENSTADT & RUSSEN ROTTENSCHILD TEYA</p>*/}
            {/*</div>*/}
            {/*<div className={`${style.blokFont} ${style.fontLinkSale}`}>*/}
            {/*    <NavLink to="/PuppyDexterTeya">*/}
            {/*        <p className={style.NEWS} align="center">Открыто бронирование на щенков от пары:*/}
            {/*            <br/>щенки родились: 16.03.2023 </p>*/}
            {/*    </NavLink>*/}
            {/*</div>*/}
            {/*<div>*/}
            {/*    <table border='0'>*/}
            {/*        <tr className={`${style.blokFont} ${style.fontLink}`}>*/}
            {/*            <td>*/}
            {/*                <NavLink to="/Dexter" activeClassName={style.activeLink}>DEXTER V.D. WEISSENSTADT</NavLink>*/}
            {/*            </td>*/}
            {/*            <td width='150'>&</td>*/}

            {/*            <td>*/}
            {/*                <NavLink to="/Teya" activeClassName={style.activeLink}>RUSSEN ROTTENSCHILD TEYA</NavLink>*/}
            {/*            </td>*/}
            {/*        </tr>*/}
            {/*        <tr>*/}
            {/*            <td width='500'><img src={DexterHead} width="400" height="400"/></td>*/}
            {/*            <td></td>*/}

            {/*            <td width='500'><img src={TEYA} width="400" height="400"/></td>*/}
            {/*        </tr>*/}
            {/*    </table>*/}
            {/*</div>*/}
            {/*<div className={`${style.blokNameZaglushka} ${style.blokNameFont}`}>*/}

            {/*</div>*/}
            {/*<br/><br/><br/>*/}

            {/*<div></div>*/}

            {/*<br/><br/><br/>*/}

            {/*Испольхуется как заглушка/ разделитель между блоками. Конец заглушки*/}
            <div className={`${style.blokName} ${style.blokNameFont}`}>
                <p align="center">RUSSEN ROTTENSCHILD DORHAN</p>
            </div>
            <div className={style.blokFont}>
                <p> &nbsp; &nbsp; &nbsp;RUSSEN ROTTENSCHILD DORHAN «ДОР» ,
                    РОЖДЕН 12.04. 2018, ОДИН ИЗ ЛУЧШИХ ПОТОМКОВ НАШЕГО
                    ПРОИЗВОДИТЕЛЯ RUSSEN ROTTENSCHILD HIGHLANDER .
                    ПОЛУЧЕН ОТ ИНБРЕДНОЙ ВЯЗКИ III:III НА НАШЕГО
                    ВЫДАЮЩЕГО ПРОИЗВОДИТЕЛЯ: ЧЕМПИОНА ГЕРМАНИИ И
                    РОССИИ, ИНТЕРЧЕМПИОНА FELIX V. HAUS ZSCHAMMER ,
                    В ПРИСУТСТВИИ КРОВЕЙ MAMBO V.D. CROSSENER RANCH И
                    INDIO OF NICOLAS LION.<br/>
                    &nbsp; &nbsp; &nbsp;ДОР БЫЛ ОСТАВЛЕН В ПИТОМНИКЕ ДЛЯ ДАЛЬНЕЙШЕЙ
                    ПЛЕМЕННОЙ РАБОТЫ . РОДОСЛОВНАЯ ДОРА ИНБРЕДНАЯ ,
                    ДОР ТОЛЬКО НАЧИНАЕТ СВОЙ ПУТЬ В РАЗВЕДЕНИИ, ИМЕЕТ
                    ОЦЕНКУ ОТЛИЧНО, УСПЕШНО СДАН ТЕСТ BH, СНИМКИ
                    ЛОКТЕВОЙ ТАЗОБЕДРЕННОЙ ДИСПЛАЗИИ
                    ПРЕДВАРИТЕЛЬНО СВОБОДЕН.<br/>
                    &nbsp; &nbsp; &nbsp;НАДЕЕМСЯ, ЧТО БЛАГОДАРЯ СБАЛАНСИРОВАННОЙ ,
                    СТАБИЛЬНОЙ РОДОСЛОВНОЙ И ЯРКИМ ХАРАКТЕРИСТАКАМ
                    ЭКСТЕРЬЕРА ДОР ОКАЖЕТСЯ ДОСТОНЫМ ПРОДОЛЖАТЕЛЕМ
                    СВОИХ ВЫДАЮЩИХСЯ ПРЕДКОВ : ТОЧЕНУЮ ГОЛОВУ ,
                    КРЕПКИЙ , АТЛЕТИЧНЫЙ , МАССИВНЫЙ КОРПУС ,
                    СТАБИЛЬНЫЙ УРАВНОВЕШЕННЫЙ ХАРАКТЕР.
                    ДОР ИМЕЕТ ОТЛИЧНЫЕ ХАРАКТЕРИСТИКИ ЭКСТЕРЬЕРА :
                    ВЫСОТА В ХОЛКЕ 65 СМ. ГЛАЗА 1В, ПЯСТЬ 14СМ.
                    ДОРХАН ДОСТУПЕН ДЛЯ ВЯЗОК С СУКАМИ ИМЕЮЩИХ
                    РОДОСЛОВНУЮ И ДОПУСК К ПЛЕМЕННОМУ РАЗВЕДЕНИЮ
                    РКФ. </p>
                <img src={DORHAN} className={style.DORHAN}/>
            </div>

        </div>
    );
}
export default Content;