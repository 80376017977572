import React from "react";
import style from "../Dogs.module.css"
import GRIM_stand from "../../../images/dogs/GRIM/GRIM_stand.jpg"
import GRIM_stand1 from "../../../images/dogs/GRIM/GRIM_stand1.JPG"
import GRIM_sid from "../../../images/dogs/GRIM/GRIM_sid.JPG"
import {NavLink} from "react-router-dom";

const GRIM = () => {
    return (
        <div>
            <div>

                <div>
                    <h1 align="center" className={style.H1}>GRIM JUNIOR MONTAGNA</h1></div>
                <img  src={GRIM_stand} className={style.img}/>
            </div>
            <div>
                <h3 align="center" className={style.H3}>Pedigree</h3>
            </div>
            <div className={style.fontTable} align="left">
                <table border="3" width="900" height= "640">
                    <tbody>
                    <tr>
                        <td className="parent" valign="middle" align="center">
                            <p>
                                I. generation</p>
                        </td>
                        <td className="parent" valign="middle" align="center">
                            <p>
                                II. generation</p>
                        </td>
                        <td className="parent" valign="middle" align="center">
                            <p>
                                III.generation</p>
                        </td>
                        <td className="parent" valign="middle" align="center">
                            <p>
                                IV. generation</p>
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="8" >
                            <p align="center">
                                MICHAEL CORLEONE V HAUS LEROY
                            </p>
                        </td>
                        <td rowSpan="4" >
                            <p align="center">
                                EDGE V CARRABBA HAUS</p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                IANS YAGO VOM HAUSE WALKER
                            </p>
                        </td>
                        <td  height="12">
                            CARL VON CARRABBA HAUS
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            FRIDA SIRMIUM KIDS
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                NURIA BUOSO DA DOVARA
                            </p>
                        </td>
                        <td  height="12">
                            GONZALO BUOZO DA DOVARA
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            URMEL VON DER SCHERAU
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="4" >
                            <p align="center">
                                FILLY VON  HAUSE LEROY
                            </p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                LEROY VAN HAYSHERAH</p>
                        </td>
                        <td  height="12">
                            MAMBO VON DER CROSSENER RANCH  ADRK 099808
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            REBECCA DELLA ROCCA BRANCALONE
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                BEA OF HAUS MISCEVIC</p>
                        </td>
                        <td  height="12">
                            RUGY VOM BONZI STAR
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            ESTER VOM BONZI STAR
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="8" >
                            <p align="center">
                                Inter CH,
                                TIARA OD DAMNJANOVICA</p>
                        </td>
                        <td rowSpan="4" >
                            <p align="center">
                                TK'S IVAN THE GREAT</p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                VIKKO DELLA VAL DEL NOTE
                            </p>
                        </td>
                        <td  height="12">
                            FANTOM DELLA VAL DEL NOTE
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            CARLA OF NICOLAS LION
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                TK'S BLACK STAR</p>
                        </td>
                        <td  height="12">
                            UZI FLASCH ROUSE
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            HONDA BLACK ALLUSION
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="4" >
                            <p align="center">
                                DUSTY OD DAMNJANOVICA</p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                FANTOM DELLA VAL DEL NOTE
                            </p>
                        </td>
                        <td  height="12">
                            COBRA DELLA VAL DE NOTO
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            WHITHNEY OF NICOLAS LION
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                BASRA GERMAN BLOOD LINE</p>
                        </td>
                        <td  height="12">
                            RAMZES CRNI LOTOS
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            MOTTE HISARLIK
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div className={style.font}>
                <div>
                    <p className={style.text}>Grim импортирован в Россию специально для использования  в нашем питомнике
                        с учетом его высокого происхождения и экстерьера.  GRIM  имеет топовую  родословную, в которой
                        представлены  высококлассные европейские и американские производители.<br/><br/>

                        Отец GRIM – Micael Сorlеrone v Haus leroy   - Ausladsieger  ADRK 2018,  многократный победитель
                        Европейских клубных выставок    сын звездной пары  от межконтинентальной  вязки –<br/><br/>

                        Edge  v. Cabarra Haus ( США ) и Filly v. Hause leroy ( Италия) , отлично зарекомендовавших себя как
                        чемпионы  и  производители.  Сам Michael отдаленно  инбреден  на  IV:V на  немецкого суперпроизводителя,
                        Чемпиона Германии AKINO V.D. LAUTERBRUKE.<br/><br/>

                        Мать GRIM - Tiara od Damnjanovica – Интерчемпионка, KS BiH 2018,  одна из сильнейших производительниц
                        Сербии, инбредна на вдающегося Итальянского производителя<br/><br/>
                        FANTOM  DELLA VAL DI NOTO, через его выдающегося сына,
                        Чемпиона мира VICCO DELLE VAL DI NOTTO.<br/>
                        Первый же помет Tiara был предопределен  стать  звездным:<br/><br/>
                        родные сестры:  GRIM - GIZA  JUNIOR MONTAGNA – Чемпионка Сербии,  Интерчемпионка,
                        GRETA JUNIOR MONTAGNA  Чемпион Сербии  CACIBx3 .
                        GRIM чемпион России, РКФ ,  CACIB, RCACIB + САС  MACEDONIA
                    </p>
                </div>
            </div>

             <div>
                <img src={GRIM_sid} width="935" height="1191"  hspace="100"/>

            </div>


        </div>
    );
}
export default GRIM;