import React from "react";
import style from "../Dogs.module.css";
import DOMINATOR_Stand from "../../../images/dogs/DOMINATOR_Stand.JPG"

const Dominator = () => {
    return(
        <div>
            <div>
                <h1 align="center" className={style.H1}>RUSSEN ROTTENSCHILD DOMINATOR</h1>
                <img src={DOMINATOR_Stand} className={style.img}/>
            </div>

            <div>
                <h3 align="center" className={style.H3}>Pedigree</h3>
            </div>
            <div className={style.fontTable} align="left">
                <table border="3" width="900" height= "640">
                    <tbody>
                    <tr>
                        <td className="parent" valign="middle" align="center">
                            <p>
                                I. generation</p>
                        </td>
                        <td className="parent" valign="middle" align="center">
                            <p>
                                II. generation</p>
                        </td>
                        <td className="parent" valign="middle" align="center">
                            <p>
                                III.generation</p>
                        </td>
                        <td className="parent" valign="middle" align="center">
                            <p>
                                IV. generation</p>
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="8" >
                            <p align="center">
                                TK S NEW YORKER
                            </p>
                        </td>
                        <td rowSpan="4" >
                            <p align="center">
                                DZOMBA V HAUS DRAZIC</p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                ARLOS MAJESTIK ROTT
                            </p>
                        </td>
                        <td  height="12">
                            LACKY V.D. SUDPFALZ
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            ATE FLASCH ROUSE
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                FELICITA TEREZSKY DVUR
                            </p>
                        </td>
                        <td  height="12">
                            MERLIN FLASCH ROUSE
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            KATJA TEREZSKY DVUR
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="4" >
                            <p align="center">
                                TK S BLACK MERSEDES
                            </p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                UZI FLASCH ROUSE</p>
                        </td>
                        <td  height="12">
                            MERLIN FLASCH ROUSE
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            TIKA FLASH ROUSE
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                HONDA BLACK ALLUSION</p>
                        </td>
                        <td  height="12">
                            BALCO AUS  DER  ESPENSTATTE
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            AKIRA OF SILVER FIELD KG
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="8" >
                            <p align="center">
                                RUSSEN ROTTENSCHILD RONDA</p>
                        </td>
                        <td rowSpan="4" >
                            <p align="center">
                                CHERNOE BRATSTVO LANCELOT  ч-п</p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                JUDA FLASH ROUSE
                            </p>
                        </td>
                        <td  height="12">
                            MERLIN FLASCH ROUSE
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            FUKSA GERMAN BLOOD LINE
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                CHERNOE BRATSTVO KRALJA  ч-п</p>
                        </td>
                        <td  height="12">
                            FLEGEL V. TEGLER FLIESS
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            OLBURD TEKILA
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="4" >
                            <p align="center">
                                RUSSEN ROTTENSCHILD NAVA </p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                RUSSEN ROTTENSCHILD PRINZ ARON  ч-п
                            </p>
                        </td>
                        <td  height="12">
                            CHRIS VON BICKESHEIM
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            ГЕЙЛ ЧУДНАЯ
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                PANDORA VOM RAUBERWEG  ч-п, </p>
                        </td>
                        <td  height="12">
                            MAMBO VON DER CROSSENER RANCH
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            FREYA VOM RAUBERWEG   A. Schmidt
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

        </div>
    );

}
export default Dominator;