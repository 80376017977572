import React from 'react';
import './App.css';
import Header from "./components/Header/Header";
import NavBar from "./components/NavBar/NavBar";
import Content from "./components/Content/Content";
import PanelRight from "./components/PanelRight/PanelRight";
import About from "./components/About/About";
import {BrowserRouter, Redirect, Route} from "react-router-dom";
import Articles from "./components/Articles/Articles";
import NotFount from "./components/NotFount/NotFount";
import Сontacts from "./components/Сontacts/Сontacts";
import Champions from "./components/Champions/Champions";
import Gallery from "./components/Gallery/Gallery";
import Dogs from "./components/Dogs/Dogs";
import Puppies from "./components/Puppies/Puppies";
import Sale from "./components/Sale/Sale";
import Story from "./components/Story/Story";
import References from "./components/References/References";
import Souvenirs from "./components/Souvenirs/Souvenirs";
import Yava from "./components/Dogs/RUSSEN_ROTTENSCHILD_YAVA/Yava";
import Footer from "./components/footer/Footer";
import Dorhan from "./components/Dogs/RUSSEN_ROTTENSCHILD_DORHAN/Dorhan";
import Book from "../src/components/Book/Book"
import Astarta from "./components/Dogs/RUSSEN_ROTTENSCHILD_ASTARTA/Astarta";
import RottweilerEducation from "./components/Articles/RottweilerEducation/RottweilerEducation";
import Apportirovka from "./components/Articles/Apportirovka/Apportirovka";
import Exhibition from "./components/Articles/Exhibition/Exhibition";
import RUSSEN_ROTTENSCHILD_LLOYD from "./components/Dogs/RUSSEN_ROTTENSCHILD_LLOYD/RUSSEN_ROTTENSCHILD_LLOYD";
import RUSSEN_ROTTENSCHILD_KOKS from "./components/Dogs/RUSSEN_ROTTENSCHILD_Koks/RUSSEN_ROTTENSCHILD_KOKS";
import RUSSEN_ROTTENSCHILD_KRETA from "./components/Dogs/RUSSEN_ROTTENSCHILD_KRETA/RUSSEN_ROTTENSCHILD_KRETA";
import RUSSEN_ROTTENSCHILD_KAYRA from "./components/Dogs/RUSSEN_ROTTENSCHILD_KAYRA/RUSSEN_ROTTENSCHILD_KAYRA";
import RUSSEN_ROTTENSCHILD_KONCORDIA
    from "./components/Dogs/RUSSEN_ROTTENSCHILD_KONCORDIA/RUSSEN_ROTTENSCHILD_KONCORDIA";
import RUSSEN_ROTTENSCHILD_THEODORA from "./components/Dogs/RUSSEN_ROTTENSCHILD_THEODORA/RUSSEN_ROTTENSCHILD_THEODORA";
import AboutNurseries from "./components/Articles/ArticlesAboutNurseries/AboutNurseries";
import DIAMANTA from "./components/Dogs/RUSSEN ROTTENSCHILD DIAMANTA/RUSSEN ROTTENSCHILD_DIAMANTA";
import RottweilerChampionshipInBrazil
    from "./components/Articles/RottweilerChampionshipInBrazil/RottweilerChampionshipInBrazil";
import InterviewWithOlgaGrin from "./components/Articles/InterviewWithOlgaGrin/InterviewWithOlgaGrin";
import NationalRottweilerShowInAustralia
    from "./components/Articles/NationalRottweilerShowInAustralia/NationalRottweilerShowInAustralia";
import Paola from "./components/Dogs/RUSSEN_ROTTENSCHILD_PAOLA/PAOLA";
import VIVA from "./components/Dogs/RUSSEN_ROTTENSCHILD_INFINITA_SEMPRE_VIVA/VIVA";
import Puppy from "./components/Sale/SaleDog/PuppyDOMINATORDIAMANTA";
import PuppyDorhanYava from "./components/Sale/SaleDog/PuppyDorhanYava";
import PuppyZHAKKONCORDIA from "./components/Sale/SaleDog/PuppyZHAKKONCORDIA";
import Highlander from "./components/Dogs/RUSSEN_ROTTENSCHILD_HIGHLANDER/Highlander";
import Doutcher from "./components/Dogs/RUSSEN_ROTTENSCHILD_DOUTCHER/Doutcher";
import PuppyDOMINATORDIAMANTA from "./components/Sale/SaleDog/PuppyDOMINATORDIAMANTA";
import Dominator from "./components/Dogs/RUSSEN ROTTENSCHILD DOMINATOR/Dominator";
import HANITA from "./components/Dogs/RUSSEN_ROTTENSCHILD_HANITA/HANITA";
import TAIRA from "./components/Dogs/RUSSEN_ROTTENSCHILD_TAIRA/TAIRA";
import PuppyGRIMKRETTA from "./components/Sale/SaleDog/PuppyGRIMKRETTA";
import GRIM from "./components/Dogs/GRIM_JUNIOR_MONTAGNA/GRIM";
import DEXTER from "./components/Dogs/DEXTER/DEXTER";
import Diksy from "./components/Dogs/RUSSEN_ROTTENSCHILD_DIKSY/Diksy";
import PuppyGRIMKDiksy from "./components/Sale/SaleDog/PuppyGRIMDiksy";
import RUSTANA from "./components/Dogs/RUSSEN_ROTTENSCHILD_RUSRANA/RUSTANA.jsx";
import RIVA from "./components/Dogs/RUSSEN ROTTENSCHILD RIVA /RIVA";
import Teya from "./components/Dogs/RUSSEN_ROTTENSCHILD_TEYA/Teya";
import Magnum from "./components/Dogs/RUSSEN_ROTTENSCHILD_MAGNUM/Magnum";
import PuppyDexterTeya from "./components/Sale/SaleDog/PuppyDexterTeya";


function App() {
    return (
        <BrowserRouter>
        <div className='app-wrapper'>
            <Header/>
            <NavBar/>
            <Footer/>

            <div class="app-wrapper-content">
                <Redirect
                    exact
                    from="/"
                    to="/Content"
                />
                <Route path="/Content" component={Content}/>
                <Route path="/About" component={About}/>
                <Route path="/NotFount" component={NotFount}/>
                <Route path="/Champions" component={Champions}/>
                <Route path="/Dogs" component={Dogs}/>
                <Route path="/Puppies" component={Puppies}/>
                <Route path="/Gallery" component={Gallery}/>
                <Route path="/Sale" component={Sale}/>
                <Route path="/Articles" component={Articles}/>
                <Route path="/Story" component={Story}/>
                <Route path="/References" component={References}/>
                <Route path="/Souvenirs" component={Souvenirs}/>
                <Route path="/Сontacts" component={Сontacts}/>
                <Route path="/Yava" component={Yava}/>
                <Route path="/Dorhan" component={Dorhan}/>
                <Route path="/Grim" component={GRIM}/>
                <Route path="/Book" component={Book}/>
                <Route path="/Astarta" component={Astarta}/>
                <Route path="/Highlander" component={Highlander}/>
                <Route path="/RottweilerEducation" component={RottweilerEducation}/>
                <Route path="/Apportirovka" component={Apportirovka}/>
                <Route path="/Exhibition" component={Exhibition}/>
                <Route path="/Koks" component={RUSSEN_ROTTENSCHILD_KOKS}/>
                <Route path="/LLOYD" component={RUSSEN_ROTTENSCHILD_LLOYD}/>
                <Route path="/KRETA" component={RUSSEN_ROTTENSCHILD_KRETA}/>
                <Route path="/KAYRA" component={RUSSEN_ROTTENSCHILD_KAYRA}/>
                <Route path="/KONCORDIA" component={RUSSEN_ROTTENSCHILD_KONCORDIA}/>
                <Route path="/THEODORA" component={RUSSEN_ROTTENSCHILD_THEODORA}/>
                <Route path="/AboutNurseries" component={AboutNurseries}/>
                <Route path="/DIAMANTA" component={DIAMANTA}/>
                <Route path="/PAOLA" component={Paola}/>
                <Route path="/RottweilerChampionshipInBrazil" component={RottweilerChampionshipInBrazil}/>
                <Route path="/InterviewWithOlgaGrin" component={InterviewWithOlgaGrin}/>
                <Route path="/NationalRottweilerShowInAustralia" component={NationalRottweilerShowInAustralia}/>
                <Route path="/VIVA" component={VIVA}/>
                <Route path="/Puppy" component={Puppy}/>
                <Route path="/PuppyDorhanYava" component={PuppyDorhanYava}/>
                <Route path="/PuppyDOMINATORDIAMANTA" component={PuppyDOMINATORDIAMANTA}/>
                <Route path="/Doutcher" component={Doutcher}/>
                <Route path="/Dominator" component={Dominator}/>
                <Route path="/HANITA" component={HANITA}/>
                <Route path="/TAIRA" component={TAIRA}/>
                <Route path="/PuppyZHAKKONCORDIA" component={PuppyZHAKKONCORDIA}/>
                <Route path="/PuppyGRIMKRETTA" component={PuppyGRIMKRETTA}/>
                <Route path="/Dexter" component={DEXTER}/>
                <Route path="/Diksy" component={Diksy}/>
                <Route path="/PuppyGRIMKDiksy" component={PuppyGRIMKDiksy}/>
                <Route path="/RUSTANA" component={RUSTANA}/>
                <Route path="/RIVA" component={RIVA}/>
                <Route path="/Teya" component={Teya}/>
                <Route path="/Magnum" component={Magnum}/>
                <Route path="/PuppyDexterTeya" component={PuppyDexterTeya}/>

            </div>
            <PanelRight/>
        </div>
        </BrowserRouter>
    );
}

export default App;
