import React from "react";
import style from "../Dogs.module.css"
import stand from "../../../images/dogs/daimon(magnum)/stand.jpg";
import sit1 from "../../../images/dogs/daimon(magnum)/sit1.jpg";
import sit2 from "../../../images/dogs/daimon(magnum)/sit2.JPG";
import sit3 from "../../../images/dogs/daimon(magnum)/sit3.JPG";

const Magnum  = () => {
    return (
        <div>
            <div>

                <div>
                    <h1 align="center" className={style.H1}>RUSSEN ROTTENSCHILD MAGNUM</h1></div>
                <img  src={stand} className={style.img} />
            </div>
            <div>
                <h3 align="center" className={style.H3}>Pedigree</h3>
            </div>
            <div className={style.fontTable} align="left" >
                <table border="3" width="900" height= "640">
                    <tbody>
                    <tr height="30px">
                        <td className="parent" valign="middle" align="center">
                                I. GENERATION
                        </td>
                        <td className="parent" valign="middle" align="center">
                                II. GENERATION
                        </td>
                        <td className="parent" valign="middle" align="center">
                                III.GENERATION
                        </td>
                        <td className="parent" valign="middle" align="center">
                                IV. GENERATION
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="8" >
                            <p align="center">
                                Rus  CH,
                                RUSSEN ROTTENSCHILD HIGHLANDER  ч-п15.05.2015
                            </p>
                        </td>
                        <td rowSpan="4" >
                            <p align="center">
                                LEX V. HAUSE EDELSTEIN</p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                BRABUS V. HAUSE EDELSTEIN
                            </p>
                        </td>
                        <td  height="12">
                            UZI FLASCH ROUSE
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            CHIQUITA VOM HAUSE EDELSTEIN
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                CYM OD VADANORA
                            </p>
                        </td>
                        <td  height="12">
                            BRONX OD VADANORA
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            PATRISSIYA FROM HOUSE ROTVIS
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="4" >
                            <p align="center">
                                RUSSEN ROTTENSCHILD ELMA
                            </p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                FELIX V. HAUS ZSCHAMMER  ч-п Дипл: SchH -I, II,III, BH  ZTP HD-A ED-I Grin O.A.& Donzov V.A.</p>
                        </td>
                        <td  height="12">
                            CUJO  V.D. BURG WEIBERTREU Дипл: SchHIII
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            BACCARA VOM  HAUS ZSCHAMMER
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                MAGGY</p>
                        </td>
                        <td  height="12">
                            BILLY V.D ELSTERAUE
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            BLAKY
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="8" >
                            <p align="center">
                                RUSSEN ROTTENSCHILD ASTARTA</p>
                        </td>
                        <td rowSpan="4" >
                            <p align="center">
                                CHERNOE BRATSTVO LANCELOT  ч-п</p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                JUDA FLASH ROUSE
                            </p>
                        </td>
                        <td  height="12">
                            MERLIN FLASCH ROUSE
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            FUKSA GERMAN BLOOD LINE
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                CHERNOE BRATSTVO KRALJA  ч-п</p>
                        </td>
                        <td  height="12">
                            FLEGEL V. TEGLER FLIESS
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            OLBURD TEKILA
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="4" >
                            <p align="center">
                                SHERIFFROTHAUS DOROTY  ч-п</p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                RUSSEN ROTTENSCHILD CAESAR II  ч-п
                            </p>
                        </td>
                        <td  height="12">
                            FELIX V. HAUS ZSCHAMMER Дипл: SchH -I, II,III, BH  ZTP HD-A ED-I Grin O.A.& Donzov V.A.
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            PANDORA VOM RAUBERWEG  РКФ 2365326
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                CHERNY LEGION LIRA  ч-п</p>
                        </td>
                        <td  height="12">
                            INDIO OF NICOLAS LION
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            CHERNY LEGION HONDA
                        </td>
                    </tr>
                    </tbody>
                </table>
                <br/>
                <br/>
            </div>
            <div>
                <p className={style.text}>
                    Представлям нашего молодого перспективного производителя!  Чемпион Росии, Юный Чемпион Росии,
                    BH  HD ED free  Russen rottenschild Magnum  ака  DAIMON . Даймон получен в нашем питомнике  от ведущего
                    производителя- Чемпиона   RR  Highlander  и Чемпионки России RR  Astarta в инбридинге III-III на
                    INTER -DT ADRK  RUS  CH  Felix vom   Haus  Zchammer.  Magnum унаследовал  наиболее яркие отличительные
                    качества  этого  стабильного производителя : крупный рост, сильный костяк, компактное сложение,
                    крепость конституции и отличный баланс. Отличительной особенностью этой линии в нашем питомнике
                    являютются  крупные, выразительные головы,  отличный пигмент, глубоко  темные глаза. Основные промеры
                    MAGNUM — высота в холке 67 см, пясть 14 см  вес  58 кг,   подпал  темно красного яркого  рисунка.
                    Отличный характер также типичен для потомков   от сочетания линий Felix —  Lex.
                    Magnum  доступен для вязок с достойными  суками, имеющими   соответствующие тесты  по здоровью и характеру.
                </p>
            </div>


             <div>
                <img src={sit1}  width="974" height="912"/><br/><br/>
                <img src={sit2}  width="955" height="865"/><br/><br/>
                <img src={sit3}  width="770" height="1004"/><br/><br/>

            </div>


        </div>
    );
}
export default Magnum ;