import React from "react";
import style from "../Sale.module.css";
import {NavLink} from "react-router-dom";
import DexterHead from "../../../images/dogs/Dexter/Dexter_head.JPG";
import TEYA from "../../../images/dogs/RUSSEN ROTTENSCHILD TEYA/head.jpg";
import one from "../../../images/dogs/Puppu/DexterTeya/1.JPG";
import two from "../../../images/dogs/Puppu/DexterTeya/2.JPG";
import two_two from "../../../images/dogs/Puppu/DexterTeya/2_2.JPG";
import three from "../../../images/dogs/Puppu/DexterTeya/3.JPG";
import four from "../../../images/dogs/Puppu/DexterTeya/4.JPG";
import five from "../../../images/dogs/Puppu/DexterTeya/5.JPG";
import six from "../../../images/dogs/Puppu/DexterTeya/6.JPG";

const PuppyDexterTeya = () => {
    return (
        <div>
            <div className={`${style.blokFont} ${style.fontLink}`}>
                <p align="center">
                    <NavLink to="/Dexter" activeClassName={style.activeLink} >DEXTER V.D. WEISSENSTADT</NavLink> &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; &  &nbsp;
                    <NavLink to="/Teya" activeClassName={style.activeLink}>RUSSEN ROTTENSCHILD TEYA</NavLink>
                </p>
                &nbsp;&nbsp;&nbsp;&nbsp;<img src={DexterHead} width="300" height="300" hspace="100"/>
                <img src={TEYA} width="300" height="300"/>
            </div>

            <div>
                <p className={style.text}>Представляем родителей помета в нашем питтомнике !<br/>
                    Дата рождения 26 марта 2023 года. 9 щенков 6 кобелей и 3 суки. <br/>
                    Доступны для бронирования  2 кобеля и 1 сука.<br/>
                    описание: Щенки получены  в аутбредной комбинации. Первый помет Dexter v Weissenstadt в России.
                    В родословной  известные Европейские производители.
                </p>
            </div>

            <div>
                <p className={style.text}><ins> Отец помета: </ins>GDEXTER V.D. WEISSENSTADT<br/>
                    {/*DEXTER VOM WEISSENSTADT  ака DARK .*/}
                    {/*Чемпион России,Чемпион РКФ, ВН, HD+\- ED – JLPP  CLEAN by PARENTS, COAT LENGTH  N\N.*/}
                </p>
            </div>

            <p className={style.text}><ins>Мать помета: </ins>RUSSEN ROTTENSCHILD TEYA</p>
            <div>

            </div>

            <div>
                <h3 align="center" className={style.H3}>Pedigree</h3>
            </div>
            <div className={style.fontTable} align="right">
                <table border="3" width="900" height="640" align="center">
                    <tbody>
                    <tr>
                        <td className="parent" valign="middle" align="center">
                            <p>
                                I. generation</p>
                        </td>
                        <td className="parent" valign="middle" align="center">
                            <p>
                                II. generation</p>
                        </td>
                        <td className="parent" valign="middle" align="center">
                            <p>
                                III.generation</p>
                        </td>
                        <td className="parent" valign="middle" align="center">
                            <p>
                                IV. generation</p>
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="8">
                            <p align="center">
                                DEXTER V.D. WEISSENSTADT
                                05.02.2022
                            </p>
                        </td>
                        <td rowSpan="4">
                            <p align="center">
                                SRECKO TIMIT TOR</p>
                        </td>
                        <td rowSpan="2">
                            <p align="center">
                                DOCTOR TIMIT TOR
                            </p>
                        </td>
                        <td height="12">
                            LUCIFER TIMIT TOR
                        </td>
                    </tr>
                    <tr>
                        <td height="12">
                            HERA TIMIT TOR
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2">
                            <p align="center">
                                NIKOLE TIMIT-TOR
                            </p>
                        </td>
                        <td height="12">
                            REX TIMIT TOR
                        </td>
                    </tr>
                    <tr>
                        <td height="12">
                            OLIMPIA TIMIT TOR
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="4">
                            <p align="center">
                                DRAMA V.D. WEISSENSTADT
                            </p>
                        </td>
                        <td rowSpan="2">
                            <p align="center">
                                DOCTOR TIMIT TOR</p>
                        </td>
                        <td height="12">
                            LUCIFER TIMIT TOR
                        </td>
                    </tr>
                    <tr>
                        <td height="12">
                            HERA TIMIT TOR
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2">
                            <p align="center">
                                OKATA TIMIT TOR</p>
                        </td>
                        <td height="12">
                            LEX V. HAUSE EDELSTEIN
                        </td>
                    </tr>
                    <tr>
                        <td height="12">
                            SEVERINA TIMIT TOR
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="8">
                            <p align="center">
                                RUSSEN ROTTENSCHILD TEYA </p>
                        </td>
                        <td rowSpan="4">
                            <p align="center">
                                RUSSEN ROTENSCHILD YUREN</p>
                        </td>
                        <td rowSpan="2">
                            <p align="center">
                                SHERIFROTTHAUS  HOWARD  ч-п
                            </p>
                        </td>
                        <td height="12">
                            FILOU VOM RAUBERWEG
                        </td>
                    </tr>
                    <tr>
                        <td height="12">
                            CHERNY LEGION LIRA
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2">
                            <p align="center">
                                RUSSEN ROTTENSCHILD MARYLIN</p>
                        </td>
                        <td height="12">
                            CHERNOE BRATSTVO LANCELOT
                        </td>
                    </tr>
                    <tr>
                        <td height="12">
                            SHERIFROTTHAUS  VELIKSA
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="4">
                            <p align="center">
                                RUSSEN ROTTENSCHILD  DINA</p>
                        </td>
                        <td rowSpan="2">
                            <p align="center">
                                FELIX V. HAUS ZSCHAMMER  ч-п Дипл: SchH -I, II,III, BH  ZTP HD-A ED-I Grin O.A.& Donzov V.A.
                            </p>
                        </td>
                        <td height="12">
                            CUJO  V.D. BURG WEIBERTREU Дипл: SchHIII
                        </td>
                    </tr>
                    <tr>
                        <td height="12">
                            BACCARA VOM  HAUS ZSCHAMMER
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2">
                            <p align="center">
                                RUSSEN ROTTENSCHILD  NAVA</p>
                        </td>
                        <td height="12">
                            RUSSEN ROTTENSCHILD PRINZ ARON
                        </td>
                    </tr>
                    <tr>
                        <td height="12">
                            PANDORA VOM RAUBERWEG  РКФ 2365326
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div>
                <div>
                    <p align='center'>
                        <img src={one} width="882" height="840"/><br/><br/>
                    </p>
                    <p align='center'>
                        <img src={two} width="862" height="998"/><br/><br/>
                    </p>
                    <p align='center'>
                        <img src={two_two} width="1200" height="825"/><br/><br/>
                    </p>
                    <p align='center'>
                        <img src={three} width="1182" height="832"/><br/><br/>
                    </p>
                    <p align='center'>
                        <img src={four} width="640" height="800"/><br/><br/>
                    </p>
                    <p align='center'>
                        <img src={five} width="754" height="877"/><br/><br/>
                    </p>
                    <p align='center'>
                        <img src={six} width="895" height="582"/><br/><br/>
                    </p>
                </div>
            </div>
            <br/>
            <br/>

        </div>
    );
}
export default PuppyDexterTeya;