import React from "react";
import style from "./Footer.module.css";
import instagram from "../../images/instagram.png";
import facebook from "../../images/facebook.png";
import vk from "../../images/vk.png";
const Footer = () => {
    return(
        <footer className={style.fotter}>
            <div>
                <table border={0} width='100%' ><tr>
                    <td><p className={style.p}> +7 (843)-264-05-59<br/>
                       г. Казань ул. Павлюхина д.100<br/>
                        г. Казань ул. Ферма 2 д. 76 (Учебный центр)</p></td>
                    <td>
                        <a href="https://www.facebook.com/olga.grin.50"> <img src={facebook} className={style.icon}/></a>
                    </td>
                    <th><a href="#"> <img src={vk} className={style.icon}/></a></th>
                    <th><a href="#"> <img src={instagram} className={style.icon}/></a></th>
                    <td><p className={style.p}> +7 9063-270-442,<br/>
                        +7 9050-210-637<br/>
                        olgagrin@mail.ru</p></td>
                </tr></table>

                         </div>
        </footer>


    );
}
export default Footer;