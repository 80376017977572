import React from "react";
import style  from  "./NotFount.module.css";

const NotFount = () =>{
    return(
        <div ><h1 className={style.H1}>NotFount</h1>
            <p className={style.font}>Данная  страничка  находиться в  разработке. </p>
        </div>
    );
}
export default NotFount;