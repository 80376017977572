import React from "react";
import style from "./Gallery.module.css"
import NotFount from "../NotFount/NotFount";

const Gallery = () => {
    return(
        <div>
            <NotFount/>

        </div>
    );
}
export default Gallery;