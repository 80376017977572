import React from "react";
import style from "../Dogs.module.css"
import Dor from "../../../images/dogs/RUSSEN_ROTTENSCHILD_DORHAN.jpg"

const Dorhan = () => {
    return (
        <div>
            <div>

                <div>
                    <h1 align="center" className={style.H1}>RUSSEN ROTTENSCHILD DORHAN</h1></div>
                <img  src={Dor} className={style.img}/>
            </div>
            <div>
                <h3 align="center" className={style.H3}>Pedigree</h3>
            </div>
            <div className={style.fontTable}>
                <table border="3" width="900" height="640">
                    <tbody>
                    <tr>
                        <td rowSpan="8">
                            <p align="center">
                                RUSSEN ROTTENSCHILD HIGHLANDER
                            </p>
                        </td>
                        <td rowSpan="4">
                            <p align="center">
                                LEX V. HAUSE EDELSTEIN</p>
                        </td>
                        <td rowSpan="2">
                            <p align="center">
                                BRABUS v.
                                HAUSE EDELSTEIN
                            </p>
                        </td>
                        <td height="12">
                            UZI FLASCH ROUSE
                        </td>
                    </tr>
                    <tr>
                        <td height="12">
                            Chiquita vom Hause Edelstein
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2">
                            <p align="center">
                                Cym od Vadanora</p>
                        </td>
                        <td height="12">
                            Bronx od Vadanora
                        </td>
                    </tr>
                    <tr>
                        <td height="12">
                            Patrissiya from House Rotvis
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="4">
                            <p align="center">
                                RUSSEN
                                ROTTENSCHILD
                                ELMA
                            </p>
                        </td>
                        <td rowSpan="2">
                            <p align="center">
                                Felix vom Haus Zschammer</p>
                        </td>
                        <td height="12">
                            Cujo von der Burg Weibertreu
                        </td>
                    </tr>
                    <tr>
                        <td height="12">
                            Baccara vom Haus Zschammer
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2">
                            <p align="center">
                                MAGGY</p>
                        </td>
                        <td height="12">
                            BILLY v.d Elsteraue
                        </td>
                    </tr>
                    <tr>
                        <td height="12">
                            BLAKY
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="8">
                            <p align="center">
                                Sheriffrothaus DOROTY</p>
                        </td>
                        <td rowSpan="4">
                            <p align="center">
                                Russen Rottenschild CAESAR II</p>
                        </td>
                        <td rowSpan="2">
                            <p align="center">
                                Felix vom Haus Zschammer</p>
                        </td>
                        <td height="12">
                            Cujo von der Burg Weibertreu
                        </td>
                    </tr>
                    <tr>
                        <td height="12">
                            Baccara vom Haus Zschammer
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2">
                            <p align="center">
                                Pandora vom Rauberweg</p>
                        </td>
                        <td height="12">
                            Mambo v.d. Crossener Ranch
                        </td>
                    </tr>
                    <tr>
                        <td height="12">
                            Freya vom Rauberwe
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="4">
                            <p align="center">
                                Cherny Legion Lira </p>
                        </td>
                        <td rowSpan="2">
                            <p align="center">
                                Indio of Nicolas Lion</p>
                        </td>
                        <td height="12">
                            Mambo of Nicolas Lion
                        </td>
                    </tr>
                    <tr>
                        <td height="12">
                            Wolga of Nicolas Lion
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2">
                            <p align="center">
                                Cherny Legion Honda </p>
                        </td>
                        <td height="12">
                            Cherny Legion Armstrong
                        </td>
                    </tr>
                    <tr>
                        <td height="12">
                            Holgerland Evi
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div className={style.font}>
                <p align="left">
                    RUSSEN ROTTENSCHILD DORHAN «ДОР» ,
                    РОЖДЕН 12.04. 2018, ОДИН ИЗ ЛУЧШИХ ПОТОМКОВ НАШЕГО
                    ПРОИЗВОДИТЕЛЯ RUSSEN ROTTENSCHILD HIGHLANDER , .
                    ПОЛУЧЕН ОТ ИНБРЕДНОЙ ВЯЗКИ III:III НА НАШЕГО
                    ВЫДАЮЩЕГО ПРОИЗВОДИТЕЛЯ: ЧЕМПИОНА ГЕРМАНИИ И
                    РОССИИ, ИНТЕРЧЕМПИОНА FELIX V. HAUS ZSCHAMMER ,
                    В ПРИСУТСТВИИ КРОВЕЙ MAMBO V.D. CROSSENER RANCH И
                    INDIO OF NICOLAS LION.
                    ДОР БЫЛ ОСТАВЛЕН В ПИТОМНИКЕ ДЛЯ ДАЛЬНЕЙШЕЙ
                    ПЛЕМЕННОЙ РАБОТЫ . РОДОСЛОВНАЯ ДОРА ИНБРЕДНАЯ ,
                    ДОР ТОЛЬКО НАЧИНАЕТ СВОЙ ПУТЬ В РАЗВЕДЕНИИ, ИМЕЕТ
                    ОЦЕНКУ ОТЛИЧНО, УСПЕШНО СДАН ТЕСТ BH, СНИМКИ
                    ЛОКТЕВОЙ ТАЗОБЕДРЕННОЙ ДИСПЛАЗИИ
                    ПРЕДВАРИТЕЛЬНО СВОБОДЕН.
                    НАДЕЕМСЯ, ЧТО БЛАГОДАРЯ СБАЛАНСИРОВАННОЙ ,
                    СТАБИЛЬНОЙ РОДОСЛОВНОЙ И ЯРКИМ ХАРАКТЕРИСТАКАМ
                    ЭКСТЕРЬЕРА ДОР ОКАЖЕТСЯ ДОСТОНЫМ ПРОДОЛЖАТЕЛЕМ
                    СВОИХ ВЫДАЮЩИХСЯ ПРЕДКОВ : ТОЧЕНУЮ ГОЛОВУ ,
                    КРЕПКИЙ , АТЛЕТИЧНЫЙ , МАССИВНЫЙ КОРПУС ,
                    СТАБИЛЬНЫЙ УРАВНОВЕШЕННЫЙ ХАРАКТЕР.
                    ДОР ИМЕЕТ ОТЛИЧНЫЕ ХАРАКТЕРИСТИКИ ЭКСТЕРЬЕРА :
                    ВЫСОТА В ХОЛКЕ 65 СМ. ГЛАЗА 1В, ПЯСТЬ 14СМ.
                    ДОРХАН ДОСТУПЕН ДЛЯ ВЯЗОК С СУКАМИ ИМЕЮЩИХ
                    РОДОСЛОВНУЮ И ДОПУСК К ПЛЕМЕННОМУ РАЗВЕДЕНИЮ
                    РКФ.
                </p>
            </div>
        </div>
    );
}
export default Dorhan;