import React from "react";
import style from "../Sale.module.css";
import {NavLink} from "react-router-dom";
import DOMINATOR from "../../../images/dogs/DOMINATOR.JPG";
import RR_DIAMANTA from "../../../images/dogs/RR_DIAMANTA.JPG";
import DOMINATORDIAMANTA from "../../../images/video/DOMINATOR&DIAMANTA.mp4";
import one from "../../../images/dogs/Puppu/DOMINATOR&DIAMANTA/1.JPG";
import two from "../../../images/dogs/Puppu/DOMINATOR&DIAMANTA/2.JPG";
import three from "../../../images/dogs/Puppu/DOMINATOR&DIAMANTA/3.JPG";
import four from "../../../images/dogs/Puppu/DOMINATOR&DIAMANTA/4.JPG";


const PuppyDOMINATORDIAMANTA = () => {
    return (

        <div>

            <div className={`${style.blokFont} ${style.fontLink}`}>
                <p align="center">
                    <NavLink to="/DOMINATOR" activeClassName={style.activeLink}>RUSSEN ROTTENSCHILD DOMINATOR</NavLink> &
                    <NavLink to="/DIAMANTA" activeClassName={style.activeLink}>RUSSEN ROTTENSCHILD DIAMANTA</NavLink> </p>
                <img src={DOMINATOR} width="300" height="300" hspace="100"/>
                <img src={RR_DIAMANTA} width="300" height="300"/>
            </div>
            <div>
                <p className={style.text}>Щенки родились 23 февраля 2021. Отец Русен Роттеншильд Доминатор, мать Руссен Роттеншильд Диаманта.
                    8 щенков. 4 кобеля, 4 суки. Для бронирования доступны  1 сука и 2 кобеля</p>
            </div>
            <div>
                <h3 align="center" className={style.H3}>Pedigree</h3>
            </div>
            <div className={style.fontTable} align="right">
                <table border="3" width="900" height= "640" align="center">
                    <tbody>
                    <tr>
                        <td className="parent" valign="middle" align="center">
                            <p>
                                I. generation</p>
                        </td>
                        <td className="parent" valign="middle" align="center">
                            <p>
                                II. generation</p>
                        </td>
                        <td className="parent" valign="middle" align="center">
                            <p>
                                III.generation</p>
                        </td>
                        <td className="parent" valign="middle" align="center">
                            <p>
                                IV. generation</p>
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="8" >
                            <p align="center">
                                RUSSEN ROTTENSCHILD DOMINATOR
                            </p>
                        </td>
                        <td rowSpan="4" >
                            <p align="center">
                                TK S NEW YORKER</p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                DZOMBA V HAUS DRAZIC
                            </p>
                        </td>
                        <td  height="12">
                            ARLOS MAJESTIK ROTT
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            FELICITA TEREZSKY DVUR
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                TK S BLACK MERSEDES
                            </p>
                        </td>
                        <td  height="12">
                            UZI FLASCH ROUSE
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            HONDA BLACK ALLUSION
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="4" >
                            <p align="center">
                                RUSSEN ROTTENSCHILD RONDA
                            </p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                CHERNOE BRATSTVO LANCELOT  ч-п</p>
                        </td>
                        <td  height="12">
                            JUDA FLASH ROUSE
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            CHERNOE BRATSTVO KRALJA
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                RUSSEN ROTTENSCHILD NAVA</p>
                        </td>
                        <td  height="12">
                            RUSSEN ROTTENSCHILD PRINZ ARON
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            PANDORA VOM RAUBERWEG
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="8" >
                            <p align="center">
                                RUSSEN ROTTENSCHILD DIAMANTA</p>
                        </td>
                        <td rowSpan="4" >
                            <p align="center">
                                Rus  CH
                                RUSSEN ROTTENSCHILD HIGHLANDER  ч-п</p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                LEX V. HAUSE EDELSTEIN
                            </p>
                        </td>
                        <td  height="12">
                            BRABUS V. HAUSE EDELSTEIN
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            CYM OD VADANORA
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                RUSSEN ROTTENSCHILD ELMA</p>
                        </td>
                        <td  height="12">
                            FELIX V. HAUS ZSCHAMMER Дипл: SchH -I, II,III, BH  ZTP HD-A ED-I Grin O.A.& Donzov V.A.
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            MAGGY
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="4" >
                            <p align="center">
                                SHERIFFROTHAUS DOROTY  ч-п</p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                RUSSEN ROTTENSCHILD CAESAR II  ч-п
                            </p>
                        </td>
                        <td  height="12">
                            FELIX V. HAUS ZSCHAMMER Дипл: SchH -I, II,III, BH  ZTP HD-A ED-I Grin O.A.& Donzov V.A.
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            PANDORA VOM RAUBERWEG
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                CHERNY LEGION LIRA  ч-п</p>
                        </td>
                        <td  height="12">
                            INDIO OF NICOLAS LION
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            CHERNY LEGION HONDA
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div></div>
            <div>
                <p align='center'>
                <video controls  muted>
                    <source src={DOMINATORDIAMANTA} type="video/mp4"></source>
                </video>
                </p>
            </div>
            <div>
                <p align='center'>
                    <img src={one} width="619" height="839"/><br/><br/>
                </p>
                <p align='center'>
                    <img src={two} width="784" height="673"/><br/><br/>
                </p>
                <p align='center'>
                    <img src={three} width="986" height="812"/><br/><br/>
                </p>
                <p align='center'>
                    <img src={four} width="619" height="839"/><br/><br/>
                </p>
            </div>

        </div>
    );
}
export default PuppyDOMINATORDIAMANTA;