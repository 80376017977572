import React from "react";
import style from "../Dogs.module.css";
import RussenDoutcher from "../../../images/dogs/RussenDoutcher.JPG"

const Doutcher = () => {
    return(
        <div>
            <div>
                <h1 align="center" className={style.H1}>RUSSEN ROTTENSCHILD DEUTSCHER </h1>
                <img src={RussenDoutcher} className={style.img}/>
            </div>

            <div>
                <h3 align="center" className={style.H3}>Pedigree</h3>
            </div>
            <div className={style.fontTable} align="left">
                <table border="3" width="900" height= "640">
                    <tbody>
                    <tr>
                        <td className="parent" valign="middle" align="center">
                            <p>
                                I. generation</p>
                        </td>
                        <td className="parent" valign="middle" align="center">
                            <p>
                                II. generation</p>
                        </td>
                        <td className="parent" valign="middle" align="center">
                            <p>
                                III.generation</p>
                        </td>
                        <td className="parent" valign="middle" align="center">
                            <p>
                                IV. generation</p>
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="8" >
                            <p align="center">
                                RUSSEN ROTTENSCHILD HIGHLANDER

                            </p>
                        </td>
                        <td rowSpan="4" >
                            <p align="center">
                                LEX V. HAUSE EDELSTEIN</p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                BRABUS v. HAUSE EDELSTEIN

                            </p>
                        </td>
                        <td  height="12">
                            UZI FLASCH ROUS
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            Chiquita vom Hause Edelstein
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                Cym od Vadanora
                            </p>
                        </td>
                        <td  height="12">
                            Bronx od Vadanora
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            Patrissiya from House Rotvis
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="4" >
                            <p align="center">
                                RUSSEN ROTTENSCHILD ELMA

                            </p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                Felix vom Haus Zschammer</p>
                        </td>
                        <td  height="12">
                            Cujo von der Burg Weibertreu
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            Baccara vom Haus Zschammer
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                MAGGY</p>
                        </td>
                        <td  height="12">
                            BILLY  v.d Elsteraue
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            BLAKY
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="8" >
                            <p align="center">
                                Sheriffrothaus DOROTY</p>
                        </td>
                        <td rowSpan="4" >
                            <p align="center">
                                Russen Rottenschild CAESAR II</p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                Felix vom Haus Zschammer
                            </p>
                        </td>
                        <td  height="12">
                            Cujo von der Burg Weibertreu
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            Baccara vom Haus Zschammer
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                Pandora vom Rauberweg</p>
                        </td>
                        <td  height="12">
                            Mambo v.d. Crossener Ranch
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            Freya vom Rauberwe
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="4" >
                            <p align="center">
                                Cherny Legion Lira </p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                Indio of Nicolas Lion
                            </p>
                        </td>
                        <td  height="12">
                            Mambo of  Nicolas Lion
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            Wolga of Nicolas Lion
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                Cherny Legion Honda</p>
                        </td>
                        <td  height="12">
                            Cherny Legion Armstrong
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            Holgerland Evi
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

        </div>
    );

}
export default Doutcher;