import React from "react";
import style from "../Dogs.module.css"
import DiksyStand from "../../../images/dogs/Diksy/DiksyStand.JPG";

const Diksy  = () => {
    return (
        <div>
            <div>

                <div>
                    <h1 align="center" className={style.H1}>RUSSEN ROTTENSCHILD DIKSY</h1></div>
                <img  src={DiksyStand} className={style.img}/>
            </div>
            <div>
                <h3 align="center" className={style.H3}>Pedigree</h3>
            </div>
            <div className={style.fontTable} align="left">
                <table border="3" width="900" height= "640">
                    <tbody>
                    <tr>
                        <td className="parent" valign="middle" align="center">
                            <p>
                                I. generation</p>
                        </td>
                        <td className="parent" valign="middle" align="center">
                            <p>
                                II. generation</p>
                        </td>
                        <td className="parent" valign="middle" align="center">
                            <p>
                                III.generation</p>
                        </td>
                        <td className="parent" valign="middle" align="center">
                            <p>
                                IV. generation</p>
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="8" >
                            <p align="center">
                                TK S NEW YORKER
                            </p>
                        </td>
                        <td rowSpan="4" >
                            <p align="center">
                                DZOMBA V HAUS DRAZIC</p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                ARLOS MAJESTIK ROTT
                            </p>
                        </td>
                        <td  height="12">
                            LACKY V.D. SUDPFALZ
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            FATE FLASCH ROUSE
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                FELICITA TEREZSKY DVUR
                            </p>
                        </td>
                        <td  height="12">
                            MERLIN FLASCH ROUSE
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            KATJA TEREZSKY DVUR
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="4" >
                            <p align="center">
                                TK S BLACK MERSEDES
                            </p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                UZI FLASCH ROUSE</p>
                        </td>
                        <td  height="12">
                            MERLIN FLASCH ROUSE
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            TIKA FLASH ROUSE
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                HONDA BLACK ALLUSION</p>
                        </td>
                        <td  height="12">
                            BALCO AUS  DER  ESPENSTATTE
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            AKIRA OF SILVER FIELD KG
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="8" >
                            <p align="center">
                                RUSSEN ROTTENSCHILD RONDA</p>
                        </td>
                        <td rowSpan="4" >
                            <p align="center">
                                CHERNOE BRATSTVO LANCELOT  ч-п</p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                JUDA FLASH ROUSE
                            </p>
                        </td>
                        <td  height="12">
                            MERLIN FLASCH ROUSE
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            FUKSA GERMAN BLOOD LINE
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                CHERNOE BRATSTVO KRALJA  ч-п</p>
                        </td>
                        <td  height="12">
                            FLEGEL V. TEGLER FLIESS
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            OLBURD TEKILA
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="4" >
                            <p align="center">
                                RUSSEN ROTTENSCHILD NAVA</p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                RUSSEN ROTTENSCHILD PRINZ ARON  ч-п
                            </p>
                        </td>
                        <td  height="12">
                            CHRIS VON BICKESHEIM
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            ГЕЙЛ ЧУДНАЯ
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                PANDORA VOM RAUBERWEG  ч-п</p>
                        </td>
                        <td  height="12">
                            MAMBO VON DER CROSSENER RANCH
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            FREYA VOM RAUBERWEG A. Schmidt
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            {/*<div className={style.font}>*/}
            {/*    <div>*/}
            {/*        <p className={style.text}>Grim импортирован в Россию специально для использования  в нашем питомнике*/}
            {/*            с учетом его высокого происхождения и экстерьера.  GRIM  имеет топовую  родословную, в которой*/}
            {/*            представлены  высококлассные европейские и американские производители.<br/><br/>*/}

            {/*            Отец GRIM – Micael Сorlеrone v Haus leroy   - Ausladsieger  ADRK 2018,  многократный победитель*/}
            {/*            Европейских клубных выставок    сын звездной пары  от межконтинентальной  вязки –<br/><br/>*/}

            {/*            Edge  v. Cabarra Haus ( США ) и Filly v. Hause leroy ( Италия) , отлично зарекомендовавших себя как*/}
            {/*            чемпионы  и  производители.  Сам Michael отдаленно  инбреден  на  IV:V на  немецкого суперпроизводителя,*/}
            {/*            Чемпиона Германии AKINO V.D. LAUTERBRUKE.<br/><br/>*/}

            {/*            Мать GRIM - Tiara od Damnjanovica – Интерчемпионка, KS BiH 2018,  одна из сильнейших производительниц*/}
            {/*            Сербии, инбредна на вдающегося Итальянского производителя<br/><br/>*/}
            {/*            FANTOM  DELLA VAL DI NOTO, через его выдающегося сына,*/}
            {/*            Чемпиона мира VICCO DELLE VAL DI NOTTO.<br/>*/}
            {/*            Первый же помет Tiara был предопределен  стать  звездным:<br/><br/>*/}
            {/*            родные сестры:  GRIM - GIZA  JUNIOR MONTAGNA – Чемпионка Сербии,  Интерчемпионка,*/}
            {/*            GRETA JUNIOR MONTAGNA  Чемпион Сербии  CACIBx3 .*/}
            {/*            GRIM чемпион России, РКФ ,  CACIB, RCACIB + САС  MACEDONIA*/}
            {/*        </p>*/}
            {/*    </div>*/}
            {/*</div>*/}

             <div>
                {/*<img src={GRIM_sid} width="935" height="1191"  hspace="100"/>*/}

            </div>


        </div>
    );
}
export default Diksy ;