import React from "react";
import РР_КОКС from "../../../images/dogs/РР_КОКС.JPG";
import style from "../Dogs.module.css"

const RUSSEN_ROTTENSCHILD_KOKS = () => {
    return(
        <div>
            <div>
                <h1 align="center" className={style.H1}>RUSSEN ROTTENSCHILD KOKS</h1>
                <img src={РР_КОКС} className={style.img}/>
            </div>

            <div>
                <h3 align="center" className={style.H3}>Pedigree</h3>
            </div>
            <div className={style.fontTable} align="left">
                <table border="3" width="900" height= "640">
                    <tbody>
                    <tr>
                        <td className="parent" valign="middle" align="center">
                            <p>
                                I. generation</p>
                        </td>
                        <td className="parent" valign="middle" align="center">
                            <p>
                                II. generation</p>
                        </td>
                        <td className="parent" valign="middle" align="center">
                            <p>
                                III.generation</p>
                        </td>
                        <td className="parent" valign="middle" align="center">
                            <p>
                                IV. generation</p>
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="8" >
                            <p align="center">
                                Rus CH Chernoe Bratstvo<br/>
                                Lancelot
                            </p>
                        </td>
                        <td rowSpan="4" >
                            <p align="center">
                                Juda Flasch Rouse</p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                Merlin Flasch Rouse
                            </p>
                        </td>
                        <td  height="12">
                            Gringo v. Gruntenblick
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            Ramona v.d. Crossener Ranch
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                Fuksa German Blood Line</p>
                        </td>
                        <td  height="12">
                            Dack Flasch Rouse
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            Basra German Blood Line
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="4" >
                            <p align="center">
                                Chernoe Bratstvo Kralja
                            </p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                Flegel v. Tegler Fliess</p>
                        </td>
                        <td  height="12">
                            Ambassador v. Schambala
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            Vetzi v. Wolfet Turm
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                Olburd Tekila </p>
                        </td>
                        <td  height="12">
                            DOLF  v.d. Blokhutte
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            Innes
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="8" >
                            <p align="center">
                                Russ Ch
                                Hella von der Crossener Ranch<br/>
                                V-1 rated
                                HD-frei, ED-frei</p>
                        </td>
                        <td rowSpan="4" >
                            <p align="center">
                                Mambo v.d. Crossener Ranch</p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                Rick von Burgthann</p>
                        </td>
                        <td  height="12">
                            Doc von der Teufelsbrucke
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            Hope von Burgthann
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                Evi von der Crossener Ranch</p>
                        </td>
                        <td  height="12">
                            Astor von Pfaffenberg
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            Bia von der Crossener Ranch
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="4" >
                            <p align="center">
                                Kira v. Muhlberg </p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                Orlando vom Hause Neubrand</p>
                        </td>
                        <td  height="12">
                            Amadeus vom Silberpfeil
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            Cora vom Rosslesgarten
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                Elvi v.d. Crossener Ranch</p>
                        </td>
                        <td  height="12">
                            Astor von Pfaffenberg
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            Bia von der Crossener Ranch
                        </td>
                    </tr>
                    </tbody>
                </table>

            </div>
            <div className={style.font}>
                <p></p>
            </div>
        </div>
    );
}
export default RUSSEN_ROTTENSCHILD_KOKS;