import React from "react";
import style from "./Story.module.css"
import NotFount from "../NotFount/NotFount";

const Story = () => {
    return(
        <div>
            <div ><h1 align="center">История</h1></div>

            <div>
                <p>&nbsp;&nbsp;&nbsp; Ротвейлер появился у нас в 1985 г. С тех пор эта порода неразрывно связана с нашей жизнью и
                    доставляет нам немало интересных и приятных минут.<br/><br/>

                    &nbsp;&nbsp;&nbsp; Первым нашим ротвейлером стал Кобель Керк (р. 1985), чемпион Татарстана по рабочим качествам и
                    экстерьеру. Родословная Керка была типичной для российского ротвейлера того времени - построена
                    на кровях ГДР-овских собак. В конце 80-х начале 90-х годов разведение ротвейлеров в России
                    переживало необычайный подъём. В те годы ринги ротвейлеров собирали по 50-70 собак одновременно,
                    а на выставках регистрировалось до 300-400 собак этой породы.Именно на то время пришёлся пик
                    подъёма породы. Поголовье Российских ротвейлеров было достаточно хорошего уровня, полученное в
                    основном на кровях ротвейлеров привезённых из ГДР . Наиболее громкие имена лучших производителей
                    прошлого России – Амур и Арко ф. Мюритцгрунд, Брикс ф. Толлензеталь, Эльх ф. Винкель. Позднее,
                    в конце 70-х годов в СССР попали собаки из "западных" областей, разведения – это были – из
                    Скандинавии: Цезарь, Аргос, Памиссен–Дарес, а также кобель, оказавший эпохальное влияние на породу:
                    Харрас ф. Штайнкопф, привезённый из ФРГ и происходивший от знаменитого немецкого чемпиона и
                    производителя Benno v. Allgouer Tor. Разведение ротвейлеров происходило тогда на базе
                    Республиканского клуба служебного собаководства ДОСААФ (РОСТО), так как ранее в России не было
                    возможности организовать свой собственный племенной питомник.<br/>
                    Разведение ротвейлеров в Татарстане.<br/><br/>

                    &nbsp;&nbsp;&nbsp; Разведение ротвейлеров в Татарстане началось в 1986 г., тогда был получен первый помет от Кайзера
                    (Юджин + Бина), привезённого из Москвы и Эсси (Рамзай + Сторри), привезенной, из С. Петербурга.
                    У этой пары родилась сука Янтра, вл. Семенов. В 1988г. от Янтры и Керка (Вэст-Рой + Мэгги)
                    был получен помет, откуда произошли сестры: Брэнда (вл. Гринь) и Берта (вл. Рахматуллин),
                    давшие впоследствии немало известных Чемпионов. Наибольшее влияние на породу в нашем питомнике
                    оказала дочь Брэнды и чемпиона Дукса (вл. Галкин) – Идетта, давшая от разных кобелей немало
                    интересных потомков, используемых нами в современном разведении. Вторая ветвь нашего разведения
                    по материнской линии пошла от купленной нами у известной заводчицы Е. Лоцмановой щенка - суки
                    Андора – Брулеф (Тэбри – Эрлих + Заль – Брунгильда). Эта собака также оказала решающее
                    положительное воздействие на развитие породы в регионе. Лучшие пометы Андоры – Брулеф были получены
                    в 1989г. от Керка и в 1991 г. от Дукса вл. Галкин. Безусловно, в нашем разведении использовались
                    и иной племенной материал, но потомки Идетты и Андоры – Брулеф стали основой нашего разведения и
                    племенной базой нашего маточногопоголовья на будущее.<br/><br/>

                    &nbsp;&nbsp;&nbsp;При достаточно большом количестве сук (например в 90-м году) в плане племенного разведения их было
                    более 20, мы уделяли должное внимание подбору кобелей. Практиковались выездные вязки к специально
                    подобранным производителям – Lord v. Haus Duffenter (Бельгия), Garfield du Chemin des Moulins
                    (Франция), Дукс (Галкин), Джой (Якушин), Робин (Бозина), Пфертнер - Харт (Ольшевская),
                    Поль Ольбурд (Пермь) и др. Также в город были завезены ценные племенные производители – победитель
                    выставок, IPO-I Mat z Glebozka (вл. Большебородова), импортированный из Польши (Ladis J:R Lilla
                    Hagsater + Alfa Remedium), Хюнцель (Elmediges Delle + Кармен) вл. Попутников. В 1993 г. мы решили
                    приобрести производителя на родине породы, в Германии. Тогда нами был куплен первый ротвейлер,
                    ввезённый в Россию с ZTP (допуском в разведение АДРК), Hasso v. Floriаn имевший яркий,
                    запоминающийся экстерьер, отличные рабочие качества (SchH 3,2,1 AD, BX) и роскошное происхождение.
                    Хассо происходил от знаменитого немецкого чемпиона CHRIS v. Obergrombacher Schloss и Blanka v.
                    Florian. По качеству потомства мы считаем Хассо одним из наших лучших производителей. Позднее, в
                    течение 94-97г. в Германии мы приобрели для разведения несколько интересных по происхождению
                    и экстерьеру кобелей. Это бы были сыновья лучших производителей Германий. Kliff v. Reisachturm
                    (сын Чемпиона Мира и Германии Ken v. Schweiger Wappen), EX v. Weissbachtal и Anderl v. Lorito
                    (сыновья известного производителя Ferres v. Hirscнenrangen), JONAS v. Brunnenweible
                    (cын Интерчемпиона и двухкратного победителя АДРК Falko v. d. Teufelsbruke, Gismo v. Goldsteintor
                    (сын Интерчемпиона Noris v. Gruntenblik ), Bully v. Siebersberg (сын знаменитого Orso v. Кressbach).
                    Фотографии и родословные этих собак вы найдёте в разделе "Наши собаки". В 1997г. мы приобрели
                    в Германии у заводчика Р. Jennemen'a, юного победителя земельных выставок Германии, многообещающего
                    молодого кобеля Ben. v. Lerchenfeld (Inter. Ch. Dick v. Rhongeist + Ines v.d. Lichten Aue).<br/><br/>

                    &nbsp;&nbsp;&nbsp; В России Бена ждала блестящая карьера чемпиона и производителя. Его титулы, фото, родословную
                    вы можете увидеть в разделе "Наши собаки". Бен и другие наши кобели дали замечательное потомство,
                    обеспечив нас тем самым отличным генофондом и маточным составом на несколько лет вперед.<br/><br/>

                    &nbsp;&nbsp;&nbsp;В 2001 г. мы решаем вернуться в разведение к работе с линией Хассана ф. Кенгсгартен и приобретаем
                    для этих целей молодого перспективного кобеля GERBERT IZ TADDI. В кратчайшие сроки Герберт
                    становится Чемпионом России и Чемпионом Национального Ротвейлер клуба России, а сегодня, спустя
                    год с небольшим после начала его племенного использования дети Герберта успешно начали выставочную
                    карьеру и радуют нас своими успехами. Титулы, фото, родословную вы можете увидеть в разделе "Наши собаки".<br/><br/>

                    &nbsp;&nbsp;&nbsp; Квинтэссенцией успеха своего собственного разведения мы считаем сегодня одну из наших
                    молодых сук Чемпионку России и РФСС Ингольду, которая является продуктом нашего разведения в
                    5 поколениях по материнской части родословной. Экстерьер Ингольды был высоко оценен ведущими
                    российскими и зарубежными экспертами на самых престижных кинологических мероприятиях 2001-2002 г.<br/><br/>

                    &nbsp;&nbsp;&nbsp; К настоящему времени накопив определенный опыт в разведении ротвйлеров мы посчитали возможным
                    зарегистрировать свой собственный питомник и разводить собак под именем "Russen Rottenschild".
                    Надеемся что наши питомцы будут достойны этого имени!

                    &nbsp;&nbsp;&nbsp;Триумфальная победа нашего питомника на Евразии-2008.<br/>

                    &nbsp;&nbsp;&nbsp; 23-24 февраля 2008 года в Москве состоялась традиционная выставка Евразия-2008, самый крупный и
                    престижный 2xCACIB в России и СНГ.<br/>

                    &nbsp;&nbsp;&nbsp; В первый день под судейством эксперта из Италии Guido Perosini титул Лучшего Юниора и Лучшего
                    Представителя Породы завоевала наша совсем ещё юная (13 месяцев) сука Russen Rottenschild Kora.
                    Кора является собакой разведения нашего питомника в 5 генерации по материнской линии.
                    В конкуренции присутствовало более 140 ротвейлеров высочайшего класса из крупнейших питомников СНГ.
                    Кора покорила эксперта фантастическими движениями и безупречным показом в стойке, что с восторгом
                    было отмечено в описании.<br/><br/>

                    &nbsp;&nbsp;&nbsp;Во второй день выставки под судейством эксперта из Боснии, признанного специалиста по породе
                    ротвейлер, Refet Hadzic титул Победителя класса Чемпионов, Лучшего Кобеля, Чемпиона РКФ и
                    Лучшего Представителя Породы получил в жесточайшей конкуренции приобретённый нами в ноябре 2007
                    года Чемпион Германии - Filou vom Rauberweg. Этой громкой победой Филу закрыл титул
                    Интернационального Чемпиона, с чем мы также поздравляем заводчика Филу - Andreas Schmidt,
                    питомник vom Rauberweg (Германия).<br/><br/>

                    &nbsp;&nbsp;&nbsp;Также успешным для нас было выступление нашего производителя, Чемпиона Германии
                    Felix von Haus Zschammer, который оба дня стал призёром рабочего класса: 1 день (Guido Perosini)
                    - отлично 4, 2 день (Refet Hadzic) - отлично 2 , r.CAC.<br/><br/>

                    &nbsp;&nbsp;&nbsp;Коллектив питомника Russen Rottensch выражает огромную признательность всем, кто "болел" за нас
                    и поддерживал наших питомцев словом и делом на этой ответственной выставке!</p>
            </div>
        </div>
    );
}
export default Story;