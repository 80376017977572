import React from "react";
import style from "../Dogs.module.css"
import Russana from "../../../images/dogs/RUSRANA/russana_stay.JPG";

const RUSTANA  = () => {
    return (
        <div>
            <div>

                <div>
                    <h1 align="center" className={style.H1}>RUSSEN ROTTENSCHILD RUSTANA</h1></div>
                <img  src={Russana} className={style.img}/>
            </div>
            <div>
                <h3 align="center" className={style.H3}>Pedigree</h3>
            </div>
            <div className={style.fontTable} align="left">
                <table border="3" width="900" height= "640">
                    <tbody>
                    <tr>
                        <td className="parent" valign="middle" align="center">
                            <p>
                                I. generation</p>
                        </td>
                        <td className="parent" valign="middle" align="center">
                            <p>
                                II. generation</p>
                        </td>
                        <td className="parent" valign="middle" align="center">
                            <p>
                                III.generation</p>
                        </td>
                        <td className="parent" valign="middle" align="center">
                            <p>
                                IV. generation</p>
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="8" >
                            <p align="center">
                                RUSSEN ROTTENSCHILD  DORHAN
                            </p>
                        </td>
                        <td rowSpan="4" >
                            <p align="center">
                                Rus  CH
                                RUSSEN ROTTENSCHILD HIGHLANDER  ч-п</p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                LEX V. HAUSE EDELSTEIN
                            </p>
                        </td>
                        <td  height="12">
                            BRABUS V. HAUSE EDELSTEIN
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            CYM OD VADANORA
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                RUSSEN ROTTENSCHILD ELMA
                            </p>
                        </td>
                        <td  height="12">
                            FELIX V. HAUS ZSCHAMMER Дипл: SchH -I, II,III, BH  ZTP HD-A ED-I Grin O.A.& Donzov V.A.
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            MAGGY
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="4" >
                            <p align="center">
                                SHERIFFROTHAUS DOROTY  ч-п
                            </p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                RUSSEN ROTTENSCHILD CAESAR II  ч-п</p>
                        </td>
                        <td  height="12">
                            FELIX V. HAUS ZSCHAMMER Дипл: SchH -I, II,III, BH  ZTP HD-A ED-I Grin O.A.& Donzov V.A.
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            PANDORA VOM RAUBERWEG
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                CHERNY LEGION LIRA  ч-п</p>
                        </td>
                        <td  height="12">
                            INDIO OF NICOLAS LION
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            CHERNY LEGION HONDA
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="8" >
                            <p align="center">
                                RUSSEN ROTTENSCHILD YAVA  ч-п</p>
                        </td>
                        <td rowSpan="4" >
                            <p align="center">
                                SHERIFROTTHAUS  HOWARD  ч-п</p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                FILOU VOM RAUBERWEG
                            </p>
                        </td>
                        <td  height="12">
                            BRUTUS V RAUBERWEG
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            MONA VON OSTSACHSEN
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                CHERNY LEGION LIRA  ч-п</p>
                        </td>
                        <td  height="12">
                            INDIO OF NICOLAS LION
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            CHERNY LEGION HONDA
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="4" >
                            <p align="center">
                                RUSSEN ROTTENSCHILD YUSTA</p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                CHERNOE BRATSTVO LANCELOT  ч-п
                            </p>
                        </td>
                        <td  height="12">
                            JUDA FLASH ROUSE
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            CHERNOE BRATSTVO KRALJA
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                RUSSEN ROTTENSCHILD  CESA</p>
                        </td>
                        <td  height="12">
                            FELIX V. HAUS ZSCHAMMER Дипл: SchH -I, II,III, BH  ZTP HD-A ED-I Grin O.A.& Donzov V.A.
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            PANDORA VOM RAUBERWEG
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            {/*<div className={style.font}>*/}
            {/*    <div>*/}
            {/*        <p className={style.text}>Grim импортирован в Россию специально для использования  в нашем питомнике*/}
            {/*            с учетом его высокого происхождения и экстерьера.  GRIM  имеет топовую  родословную, в которой*/}
            {/*            представлены  высококлассные европейские и американские производители.<br/><br/>*/}

            {/*            Отец GRIM – Micael Сorlеrone v Haus leroy   - Ausladsieger  ADRK 2018,  многократный победитель*/}
            {/*            Европейских клубных выставок    сын звездной пары  от межконтинентальной  вязки –<br/><br/>*/}

            {/*            Edge  v. Cabarra Haus ( США ) и Filly v. Hause leroy ( Италия) , отлично зарекомендовавших себя как*/}
            {/*            чемпионы  и  производители.  Сам Michael отдаленно  инбреден  на  IV:V на  немецкого суперпроизводителя,*/}
            {/*            Чемпиона Германии AKINO V.D. LAUTERBRUKE.<br/><br/>*/}

            {/*            Мать GRIM - Tiara od Damnjanovica – Интерчемпионка, KS BiH 2018,  одна из сильнейших производительниц*/}
            {/*            Сербии, инбредна на вдающегося Итальянского производителя<br/><br/>*/}
            {/*            FANTOM  DELLA VAL DI NOTO, через его выдающегося сына,*/}
            {/*            Чемпиона мира VICCO DELLE VAL DI NOTTO.<br/>*/}
            {/*            Первый же помет Tiara был предопределен  стать  звездным:<br/><br/>*/}
            {/*            родные сестры:  GRIM - GIZA  JUNIOR MONTAGNA – Чемпионка Сербии,  Интерчемпионка,*/}
            {/*            GRETA JUNIOR MONTAGNA  Чемпион Сербии  CACIBx3 .*/}
            {/*            GRIM чемпион России, РКФ ,  CACIB, RCACIB + САС  MACEDONIA*/}
            {/*        </p>*/}
            {/*    </div>*/}
            {/*</div>*/}

             <div>
                {/*<img src={GRIM_sid} width="935" height="1191"  hspace="100"/>*/}

            </div>


        </div>
    );
}
export default RUSTANA ;