import React from "react";
import style from "./Exhibition.module.css";


const Exhibition = (props) => {
    return(
        <div className={style.font}>
            <div ><h1 align="center">Статьи</h1></div>
            <div ><h3>38 выставка Всегерманского Ротвейлер Клуба</h3></div>
            <div ><h3>19-20 августа 2006 г.<br/>Окружная группа Alb-Donau, Riedlingen, Германия</h3></div>

            <div>
                {/*<div ><h1 className={style.H1}>NotFount</h1>*/}
                {/*    <p className={style.font}>Данная  страничка  находиться в  разработке. </p>*/}
                {/*</div>*/}
                {/*<p align="justify">*/}
                <p className={style.font}>
                    &nbsp;&nbsp;&nbsp; Вы никогда не задумывались, уважаемый читатель, о чем чаще всего грезит истинный любитель ротвейлера
                    в своих самых прекрасных снах? Конечно, он видит себя и своего любимого, полученного ценой упорных
                    трудов и творческих мук СОВЕРШЕННОГО ротвейлера в обнимку с заветным кубком Победителя
                    (или хотя бы ЮНОГО) победителя АДРК! Вокруг - восторженные аплодисменты и поздравления, а внутри -
                    гордость и абсолютное счастье от сознания того, что ты «Сделал это !» Но тут, как это обычно бывает
                    в земной жизни наш герой просыпается и вновь устремляется к своим каждодневным хлопотам – какой
                    корм лучший из лучших, почему в том же инбридинге у того получилось «то» – а у него –«сё», о ком
                    судачат нынче в мире?... Суета, суета, суета… Но стоит лишь забыться, как сладкие грёзы о победных
                    моментах в августовской Германии возвращаются к нашему целеустремленному герою вновь и вновь…<br/><br/>

                    &nbsp;&nbsp;&nbsp; И не напрасно. Потому как самое главное для нашего героя событие - Klubsiegerzuchtschau ADRK и есть
                    та вершина, покорить которую - и есть найти свой философский камень, проникнув вглубь таинств
                    селекции и пробится сквозь дебри изощренных политических хитросплетений, чаще невидимых неофитами,
                    но тем не менее реальных и коварных. Как неоспоримый факт - главная выставка национально ротвейлер
                    клуба Германии давно уже перешагнула статус банальной немецкой «националки», теперь она - самая
                    престижная профессиональная выставка ротвейлеров для всего мира. Уже само по себе участие в этой
                    выставке является престижным, я уже не говорю о том, на какую высоту поднимется ротвейлер,
                    отмеченный благосклонностью судей, или по крайней мере, понравившийся крайне взыскательной
                    аудитории!<br/><br/>

                    &nbsp;&nbsp;&nbsp;В этом году выставка проводилась 36-й раз, в 99-й год существования АДРК. Впервые за несколько
                    последних лет был заметен очевидный прогресс в количестве участников, после стагнации, связанной
                    с принятием закона о некупировании хвостов. Мир понял и принял ротвейлера таким, каким он вынужден
                    был воплотиться с новой, так сказать «статью».<br/><br/>

                    &nbsp;&nbsp;&nbsp;Но несмотря на кажущийся подъём, порода в Германии переживает самый жестокий кризис. Давление со
                    стороны правительства желающего как можно быстрее запретить ротвейлера, придав породе статус
                    бойцовой и тем самым опасной для общества породы, не замедлило принести свои горькие плоды:
                    количество пометов в АДРК сократилось почти вдвое, покупаемость щенков в Германии ощутимо снизилась.
                    Как сказал один мой знакомый немец всю жизнь державший ротвейлеров – « Моя следующая собака будет
                    точно не ротвейлер, я устал от косых взглядов в мою сторону и нервного шепота мамаш, поднимающих
                    на руки ребятишек при виде меня с ротвейлером, я чувствую себя изгоем». Пропаганда сделала свое
                    дело немцы массово напуганы и эта истерия нарастает как снежный ком. Этим можно объяснить и тот
                    факт, что из 449 участников немецкой клубной выставки более 70 процентов были иностранцами. Здесь
                    следует отметить, что этот год стал рекордным по количеству участников из СНГ. Количество собак,
                    показанных в этом году нашими соотечественниками, вполне хватило бы на квотируемую региональную
                    выставку.<br/><br/>

                    &nbsp;&nbsp;&nbsp; АДРК по-прежнему имеет достаточно мощный племенной потенциал, но большая его часть уходит за
                    пределы Германии (в страны, где закон пока более лоялен по отношению к породе) и выставляется
                    позднее уже под иностранным флагами. Этот год не стал исключением из правил последних лет.
                    Обилие качественных иностранных собак заметно теснит многие известные немецкие брэнды на звездном
                    небосклоне, однако в расстановку не попадают более 2 собак чужеземцев в классе. Как в предыдущие
                    года, судейство выставки было доверено немецким судьям. Подобная практика уже настолько прижилась
                    в Германии, что у меня даже не возникало сомнений в незыблемости традиции. Экспертизу проводили
                    многоопытные судьи Х.Ю. Радтке, В. Вальтер, У. Петерман, а также судья - дебютант Ф. Хедке, впервые
                    работающий на столь ответственном мероприятии. Председатель оргкомитета выставки - Пауль Фляйге.
                    Ринги собак до двух лет проходили в субботу.<br/><br/>

                    &nbsp;&nbsp;&nbsp;Классы щенков 6-9 мес были совершенно непрезентабельны ни по количеству, ни по качеству –
                    всего 5 кобелей и 8 сук по факту. Среди кобелей оценки «большая перспектива» удостоился лишь
                    Саймон от Драгицевича, вл п-к «Тверской Дом», у сук всего три высших оценки, также у собак
                    иностранного происхождения, причем довольно среднего качества. Надо отметить, что из 20
                    заявленных по каталогу щенков всего 1 был немецкого происхождения – недвусмысленный намек на то
                    что немцы откровенно игнорируют этот «детсадовский класс» на столь крупном и ответственном
                    мероприятии. В отличие от предыдущего класса размеры классов юниоров (9-12 мес.) поражали воображение.<br/><br/>

                    &nbsp;&nbsp;&nbsp;Как и в прошлом году, эти ринги собрали наибольшее количество участников. Рассудить 46
                    (по факту, 65 по каталогу) претендентов из класса юниоров кобелей предстояло г-ну Радке. Отмечу,
                    что несмотря на внушительное количество участников, класс показался мне довольно слабым, никто из
                    претендентов не показался однозначным лидером, а учитывая свирепствующую в этом классе разнотипность,
                    предварительно определить предпочтения эксперта оказалось практически невозможным. Дав в итоге 8
                    отлично наиболее крепким и костистым кобелям тем не менее, имеющих сильно разнящиеся по типажу и
                    выражению головы, эксперт отобрал их в претенденты на четверку, с оценкой очень хорошо из ринга
                    выбыла большая часть претендентов, имеющих разнообразные проблемы - дисбаланс роста и костяка,
                    прямоватый фронт, слабые связки конечностей, проблемы с пигментом. 7 собак получили оценки «хорошо»
                    - в эту группу попали кобели с явным нарушением признаков полового диморфизма, мелковатые,
                    «сукоподобные» или подчеркнуто вздернутые на ногах, имеющие легкие, удлиненные головы с нетипичным
                    для породы выражением. После довольно продолжительного сравнения на первое место досталось Карлу
                    ф. Хаузе Нойбранд (Ункас ф. Х.Нойбранд +Фарина ф. Грюнтенблик). Среднего роста, широкотелый,
                    крепкий, чуть излишне приземист для кобеля. Голова массивная, близка к экстремальным чертам,
                    «рубленый» стоп, объёмная, глубокая морда, хорошо посаженые, немного мясистые
                    (с толстыми у основания хрящами) уши, сильный загривок. Немного «простили» голову глаза – чуть
                    близко посажены, хотелось бы видеть их потемнее. Шерсть грубовата. Пес очень куражный, активный,
                    уверенный в себе, на мой взгляд его лидерство в этом ринге было вполне заслуженным. Учитывая
                    качественное происхождение Карла, остается надеяться не его удачное племенное использование.
                    Противоположность первому месту – второй в классе Обеликс ф. Бамбергер Таль (Балу ф. Бамбергер
                    Таль +Фее ф. Тенген), выше среднего роста, грубоватый, длинноватый по формату с крупной, но
                    совершенно простой, клиновидной формы головой. Грустную картину второго призера класса завершало
                    полное отсутствие выставочного темперамента. На третьем месте – Арко Эрл Антониус (Гонззо Эрл
                    Антониус + Брита Эрл Антониус), крепкий, среднего роста, пока суховатый, кобель в стадии
                    формирования. Голова соответствует корпусу, без излишеств. Хорошо подготовлен, уверенные движения
                    и очень экспрессивный показ. На четвертое место был поставлен участник из Франции Арамис а.д. Ланд
                    д. Мускетире ( Энцо ф. Бикесхайм + Тина а.д. Ланд д. Мускетире) – кобель среднего роста, длинноват
                    в пояснице, хотелось бы видеть более прочные связки переда – свободные локти, заметный размет.
                    Голова крупная, хороший рельеф и выражение.<br/><br/>

                    &nbsp;&nbsp;&nbsp;По большому счету четверка смотрелась как « лебедь рук и щука» - совершено разные во всех отношениях
                    собаки, не было ощущения гармонии и логики расстановки. Это явление, впрочем вполне объяснимо, если
                    учесть, что общий уровень класса был мягко говоря посредственен.<br/><br/>

                    &nbsp;&nbsp;&nbsp; Тем временем в параллельном классе сук юниоров шла отчаянная борьба за место в четверке. Количество
                    заявленных в классе собак – 54 реально (72 в каталоге) заставило заметно поволноваться судью У.
                    Петермана. И неудивительно – уровень представленных сук на порядок превосходил класс кобелей того
                    же возраста. Этот факт нашел отражение в оценках – 25 «отлично» и всего 10 «хорошо».<br/><br/>

                    &nbsp;&nbsp;&nbsp; Совершенно заслуженно первое место заняла Жози ф. Хассбергхёэн ( Акино ф. д. Лаутербрюке + Гунда ф.
                    Хассбергхёэн) годовалая, но уже вполне зрелая, очень объёмная, крепкая сука. Корпус хорошо развит,
                    компактен, углы переда развернуты достаточно, углы зада могли бы быть четче. Голова крупная, но в
                    то же время элегантная, женственная, достаточно сухая. Особое достоинство этой собаки – очень
                    энергичные, напористые движения и потрясающий кураж в стойке. Собака однозначно высочайшего
                    выставочного и племенного уровня. На втором месте – Ханна фром Хаус Ротвис (Морро ф.д. Шерау +
                    Индиана ф. Хаус Ротвис), принадлежащая известной заводчице из Санкт- Перербурга И. Поповой. Собака
                    хорошо показывалась и была в отличной физической форме. На третье место была поставлена Керима ф.д.
                    Бург Вайбертрой (Мук ф.д. Шерау + Акиа ф.д. Бург Вайбертрой) – очень крупная, массивная по росту,
                    я бы сказала несколько грубоватая сука, в целом - типичная дочь Акии, сходная по типу с матерью
                    голова, очень хорошая пигментация. Движения очень сильные, уверенные с превосходным толчком задних
                    на всех аллюрах. Замыкала четверку Яска ф.д. Шерау (Мук ф.д. Шерау + Люси ф.д. Шерау). Некрупная,
                    костистая по росту, компактная, сухая во всех деталях, крепкая сука. Голова чуть легковата, но
                    хорошего рельефа, выразительная. Так же как и у конкуренток – уверенный показ и выдающийся
                    темперамент. В четверке представлены три дочери широко используемых производителей Мука и Морро
                    ф.д. Шерау. Все они хороши по своему, но тип отцов прослеживается весьма условно – все они достаточно
                    разнотипны, в экстерьере этих сук видны приемущественно материнские черты. Тем не менее, столь
                    удачное выступление дочерей указанных производителей несомненно упрочит их позиции как наиболее
                    востребованных в современном европейском разведении кобелей.<br/><br/>

                    &nbsp;&nbsp;&nbsp; Класс кобелей интермедиа собрал всего 30 претендентов, однако качество собак вне сомнений было
                    выше класса кобелей юниоров. Судил этот класс многоопытный эксперт и успешный заводчик В. Вальтер.
                    В ринге было показано 22 кобеля (30 по каталогу) 12 из которых получили «отлично» - результат
                    неплохой, а если учесть обычную принципиальную строгость этого эксперта, то весьма успешный.
                    Для российских любителей этот ринг особенно интересен тем, что в нем конкурировали сразу 4 хорошо
                    известных по российскому выставочному сезону 2006 успешных молодых кобеля - Ольбурд Рик, Хойрейн
                    Жест, Максимус с Берега Туры, Аякс фром Лэнд Блэкмор. Первое место получил очевидный фаворит,
                    удачно прошедший по выставкам сезона 2006, Кверо ф. Вильден Вестен (Хосс ф. Хаузе Энциан + Джинни
                    ф. Вильден Вестен). Среднего роста, очень костистый, широкотелый, представительный молодой кобель.
                    Голова крупная, очень выразительная, отличный пигмент, идеально поставленные уши. На мой взгляд,
                    по ширине скул чуть не хватает объема щипца. Темперамент довольно умеренный, при показе ему чуть
                    не хватало «огонька». В целом кобель производит очень благоприятное впечатление, а учитывая его
                    весьма привлекательную родословную (редкие сегодня крови Энди ф. Курпфальц по отцу и Рик ф.
                    Бургтанн по матери) он несомненно станет востребованным производителем. На втором месте – Кседор ф.
                    Кюммельзее (Льюис ф. Кюммельзее + Никита ф. Кюммельзее) – хорошего среднего роста, среднего костяка,
                    массивный по возрасту. Показал стабильные, уверенные движения. Удача улыбнулась одному из российских
                    претендентов в этом ринге - третье место у Аякс фром Лэнд Блэкмор ( Дольф ф.д. Блокхютте + Хотрейн
                    Виктория), один из самых успешных сыновей Дольфа и внуков Герберта из Тадди, массивный, костистый
                    кобель, с очень объемной, хорошего типа и рельефа головой, типичной для представителей линии Ирк ф.
                    Обергомбахер Шлосс, через Матчо ф. Бургтанн ( Аякс инбреден на Матчо 3:4). Четвертым стал Ивес ф.
                    Хаузе Нойбранд ( Геро ф. Хассбергхёэн + Оксана ф. Хаузе Нойбранд), несмотря на «звездную» родословную
                    ( внук Балу и сын Оксаны), смотрелся довольно скромно – высоковат на ногах, весьма умеренные углы
                    конечностей, голова массивная, но достаточно простых линий. Несмотря на достаточно сильный состав
                    класса, в четверку попали разнотипные, не только по экстерьеру, но и по темпераменту кобели,
                    оставив неясной до конца (за исключением лидера ринга) мотивацию расстановки.<br/><br/>

                    &nbsp;&nbsp;&nbsp; Промежуточный класс сук судил Франк Хедке, молодой судья, не так давно получивший судейскую
                    лицензию, для которого этот класс стал своего рода профессиональной «премьерой» в достаточно
                    крупном и сильном ринге. Уровень класса был высоким, а по количеству ярких, запоминающихся
                    участниц – самым представительным из всех классов этого дня. Из 25 (по факту) претенденток
                    эксперт поставил на первое место Иветт ф. Хаузе Нойбранд ( Геро ф. Хассбергхёэн + Оксана ф.
                    Хаузе Нойбранд) – которая в отличие от брата вполне соответствовала громкому звучанию своей
                    родословной крупная, костистая по росту, темпераментная, с очень хорошими ходовыми данными.
                    Голова массивная, достаточно широкая и объемная, в целом сухая, но в деталях я бы сказала,
                    неуловимо не хватает благородства черт – чуть близко посажены глаза, несколько излишне развита
                    лобная бороздка, крупноваты уши. Но это лишь очень тонкие детали, а в целом - несомненно удачная
                    молодая сука. Второй стала Анья Эрл Антониус ( Гонззо Эрл Антониус + Бритта Эрл Антониус), хороших
                    пропорций, крепкая, основательная, полноформатная. Безупречная подготовка и желаемая физическая
                    кондиция. Голова хороших объемов, выразительная, но несколько сыровата в деталях (лоб, губы).
                    Третье место получила Панди ф. Айсплатц (Бронко ф. Ротбахталь + Астра ф. Швайгенер Хексентурм) –
                    суховатая, заметно высоконогая, с весьма умеренно развитыми поясами сука. Голова совершенно
                    типичная, но без каких либо весомых достоинств. Двигалась достаточно уверенно, но без желаемого
                    куража. Четвертой стала Хексе ф. д. Кёнигсканцель ( Чак ф.д. Бергхальбинзель+Дива Кёнигсканцель).
                    В её родословной содержится близкий инбридинг 2:2 на Акино ф.д. Лаутербрюке, соответственно типичный
                    для данной линии экстерьер – голова сухая, хороша в деталях, приятное выражение. Корпус глубокий,
                    хорошего объёма, корректные углы переда, углы зада умеренные, но достаточные. При показе очень
                    экспрессивна. Отмечу, что в этом классе ( в сравнении с предыдущими классами субботы) за пределами
                    четверки оказалось наибольшее количество очень качественных собак, которые могли бы с уверенностью
                    претендовать на место в расстановке.<br/><br/>

                    &nbsp;&nbsp;&nbsp; Молодые собаки, вышедшее в этом году в сравнение за титул Юного победителя клуба производили очень
                    приятное впечатление – породные, головастые, крепкие, лидеры рингов до двух лет были однозначно на
                    высоте, в очередной раз доказав всему миру, что немецкое разведение «живее всех живых» и сдавить
                    позиции не собирается. Юными победителями клуба 2006 заслуженно стали Кверо ф.д. Вильден Вестен и
                    Жози ф.д. Хассбергхёэн, а титулов Юных победителей среди иностранных участников удостоились Арко и
                    Анья Эрл Антониус. Субботний день подошел к концу, праздничный вечер состоялся прямо на выставочной
                    площадке в помещении ярмарочного комплекса. Здесь не обошлось без курьеза. После непродолжительных
                    приветственных речей на сцену вышел ансамбль немецкой народной самодеятельности. Но только
                    закрутился хоровод в ярких национальных костюмах, как, не выдержав потоков бушующего на улице
                    дождя, прямо над танцорами потекла крыша и холодная уличная влага щедро оросила выступающих.
                    Тем не менее музыка продолжала бодро наигрывать, а участники концерта ловко уворачивающиеся от
                    освежающих струй сверху и перепрыгивающие через мини-озерца на полу, пытались выглядеть
                    «комильфо», что честно говоря, удавалось им не очень успешно. Посмотрев на эту удивительную для
                    немецкой реальности картину, я решила, что потеряю совсем немного, если не увижу развязки столь
                    интригующего представления и покинула «бал».<br/><br/>

                    &nbsp;&nbsp;&nbsp;Воскресное утро встретило участников по осеннему хмуро, но погода не смогла охладить накал
                    выставочных страстей – более 200 участников боролись сегодня за титул Клубного победителя 2006.
                    Несмотря на ненастье, ринги стартовали вовремя, работа закипела. Кобелей открытого класса
                    (53 заявлено, 32 по факту) судил Ф. Хедке. Как и водится в таких классах, состав был откровенно
                    посредственный, ярких собак - единицы. Практически все они и оказались в расстановке или совсем
                    близко – на 5, 6 позициях. Основным критерием судейства стал довольно продолжительный бег.
                    В результате – первое место у представителя Бельгии Куно ф. Рабеншлосс ( Один ф. Фляйшер +
                    Ксамура ф. Рабеншлосс). Кобель крупный, на пределе стандарта, плосковатый, угластый, показавший
                    стабильные, уверенные движения с сильным толчком и хорошим балансом на всех аллюрах. Голова
                    развита по корпусу, лишена каких-либо экстремальных проявлений, приятное выражение, очень
                    насыщенный подпал, несколько грубоватая, плотная шерсть. На второе место был поставлен Бен ф.
                    Ланген Грунд ( Ксамос ф. Вольферт Турм + Каша ф. Базальтзее), в прошлом году еще у прежнего
                    владельца он так же был призером открытого класса (3 место) под судейством г-на Тэшке. Кобель
                    крепкий, среднего роста и костяка подчеркнуто удлиненного формата, спина недостаточно прочная,
                    но в силу хорошо развитых поясов продемонстрировал уверенные, быстрые движения на всех стадиях
                    показа. Голова правильной формы, глаза могли бы быть темнее. На третьем месте Рекс ф.д. Бляйштрассе
                    ( Эмиль ф.д. Бляйштрассе + Гвенн ф.д. Бляйштрассе) - некрупный, компактный, широкотелый, прочный
                    как в стойке так и движении. Хорошее плечо и форбруст, скромные углы колена и скакательного суставов.
                    Голова наиболее интересная из всей четверки, хороший объём, рельеф, «точёная», желаемое выражение.
                    Четвертым стал Маркос Ти эн Мэн (Атос Ти эн Мэн+ Бескиа Ти эн Мэн), крупный простоватый кобель с
                    массивной грубоватой головой. К сожалению за пределами четверки остался Пьюзо с Берега Туры
                    (Иван Генезис II +Кити с Берега Туры), достойно, хотя излишне эмоционально показавший себя в ринге.<br/><br/>

                    &nbsp;&nbsp;&nbsp;Тем временем интрига развивалась в открытом классе сук, самом многочисленном в воскресенье
                    и по каталогу (66) и по факту - 49 участниц. Судил Уве Петерманн. На первое место эксперт
                    поставил Чанси ф. Босниа (Чан ф.д. Бляйштрассе + Кала ф. Босниа) владелец и заводчик хорошо
                    известный последнее время в России эксперт Р. Хаджич. Добротная, не более того сука, крепкая,
                    широкотелая, в остальном - средний рост, средний костяк, средняя голова, все среднее, среднее,
                    среднее… Но в принципе этой собакой эксперт достаточно четко показал публике свой взгляд на
                    предпочитаемый им тип. Вторая - Уэсси ф. Кригсдамм ( Амбассадор ф. Шамбала + Мира ф. Рингмауэр) –
                    крепкокостная, достаточно крупная, голова выразительная, хорошего объема. Третьей стала Изабелла
                    ф. Рёйбервег ( Мамбо ф. д. Кроссенер Ранч + Мона ф. Остзаксен). Собака предпочтительного типа,
                    Гармоничная, сухая, крепкая, компактная, экспрессивная, с отлично развитыми поясами.
                    Голова благородная, очень хороших пропорций. Очень похожа на свою знаменитую мать - Мону,
                    практически её копия, только темнее по подпалу и более выразительная в деталях головы.
                    На четвертое место эксперт поставил Жой ф. Зомервайде (Тореадор из Рояус Сленио + Тесса
                    ф. Зомервайде) удачная молодая сука из Италии, ближе по типу ко второй собаки в ринге,
                    но заметно мягковата спина.<br/><br/>

                    &nbsp;&nbsp;&nbsp; Наибольший накал страстей вызвали в этот день классы рабочий и чемпионов. Как известно,
                    именно победители этих серьезных рингов становятся победителями клубной выставки.
                    Рабочий класс кобелей судил Х.Ю. Радке. Из 37 заявленных выставлялись 32 участника.
                    Традиционно продолжительный для рабочего класса бег явно выигрывал по всем позициям Хенри
                    ф. Хассбергхёэн (Акино ф.д. Лаутербрюке + Эспе ф. Обергромбахер Шлосс) – мужественный,
                    крупный, костистый, при этом очень компактный, пес. Но практически на завершающем круге
                    он вдруг неожиданно остановился, сел и… стал громогласно и очень настойчиво облаивать
                    своего хэндлера в соответствии с лучшими традициями немецкой дрессировки! Честно признаться
                    ситуация выглядела столь же смешно, сколь и трагично («И смех и грех», как говориться.) Эксперт,
                    опешивший сначала от такого нахальства немецкой собаки в рабочем(!) классе потребовал прекратить
                    неуместные «показательные выступления», но хэндлер был явно не в состоянии управится со своим
                    подопечным и под всеобщий вздох разочарования уныло покинул ринг. Первое место в этом престижном
                    классе занял Херби ф. Грюнтенблик (Мамбо ф.д. Кроссенер Ранч + Тога ф. Кюммельзее). Крупный,
                    среднего костяка, показанный в рабочей кондиции. Голова достаточная по корпусу, правильной формы,
                    сухая. Показал превосходные ходовые данные. Второй - Рикко Эрл Антониус ( Долло ф. Хаус Наги +Тайга
                    ф. Бонзи Стар) стабильно занимающий места в расстановке в течении последних лет, лучшая иностранная
                    собака 2005 года. Некрупный, костистый, прочноверхий, скромные углы зада, голова массивная, объёмная,
                    глаза могли бы быть темнее. Безукоризненно подготовлен, великолепная кондиция, экспрессия показа.
                    Третье место у Валентино ф. Хаузе Нойбранд (Эйк ф. Тенген + Оксана ф. Хаузе Нойбранд) –
                    также уверенно занимающий места в расстановке последние года ( второй в рабочем после Филу на КSZ 05).
                    Крупный, среднего костяка, с соответствующей корпусу головой – очень схож по типу с победителем
                    этого класса –Херби. На четвертое место эксперт поставил Орка ф. Хаузе Энциан ( Доннер ф.
                    Херренгартен + Исмай ф. Хаузе Энциан ). Массивный широкотелый кобель, костистый, длинноват в
                    пояснице, хорошо развернуты пояса, плоская холка. Голова крупная, но грубая, клиновидная, с
                    близко посаженными глазами. За пределами четверки оказалось немало качественных кобелей –
                    Дэнди ф. Хаузе Нойбранд, Даско ф. д. Блокхютте, Один ф. Бикесхайм II, Вико ф. Крессбах.<br/><br/>

                    &nbsp;&nbsp;&nbsp;Рабочий класс сук судил В. Вальтер. Из 27 записанных участниц выставлялись 19. Класс не
                    оставил сильного впечатления, масса собак довольно «разношерстная» и в целом безликая,
                    за очень редким исключением. В этом ринге лидировала Фрея ф.д. Лаутербрюке (Куджо ф.д. Бург
                    Вайбертрой + Онда ф.д. Тойфельсбрюке), близко инбредная 2:1 на Онду ф. Лаутербрюке. В прошлом
                    году Фрея выиграла открытый класс. Небольшая крепкая сука, голова выразительная, некрупная,
                    сухая. Много проблем в строении корпуса – длинная поясница, н едостаточно прочная спина. Углы
                    умеренные, но достаточные для стабильных движений. Вторая в этом классе - Хондра дель Дардо Неро
                    (Энцо ф. Бикесхайм +Умбра ф. Бургтанн) участница из Италии, происходящая от родителей АДРК. Крупная,
                    среднего ко росту костяка, выразительная, объемная голова, но также, как и у первой собаки – проблемы
                    в строении корпуса - переслежина, длинная поясница. Третье место у Тесс ф.д. Шерау (Акино ф.д.
                    Лаутербрюке + Люси ф.д. Шерау) сука хорошего типа, крепкая, очень прочная, голова лучшя в четверке
                    . За ней прошла четвертой в расстановке Фетте ф. Круммен Акер (Мамбо ф.д. Тойфельсбрюке + Азиза ф.
                    Круммен Акер). Крупная, высоконогая, плосковатая, для пятилетней суки ей явно недоставало объемов,
                    голова объемная в черепе с легковатой мордой. Не могу сказать, что у меня остались сожаления по
                    поводу кого то из сук в этом классе, не попавших в расстановку. Классы чемпионов традиционно
                    вызывают неизменный интерес публики и ажиотаж среди участников. В этом году класс чемпионов
                    кобелей - был достаточно многочисленный - 28 по каталогу и 25 по факту. Судья - В. Вальтер
                    отобрал в четверку кобелей, показавших наилучшие ходовые данные в сочетании с общей гармонией
                    экстерьера. Победителем класса чемпионов стал Дьюк ф. Фильсталер Ланд (Клифф ф.д. Кенигсканцель
                    + Скарлет ф. Фильсталер Ланд), достаточно известный и титулованный кобель. Он давно и уверенно
                    шел к этой победе. Компактный, крупный, костистый по росту, находился на пике физической кондиции
                    к моменту выставки. Показал наиболее уверенные движения.<br/><br/>

                    &nbsp;&nbsp;&nbsp;Второй - Арзадон Тайсон (Барни од Драгицевича +Арзадон Нэнси) так же широко известный в
                    профессиональных кругах, представитель датского питомника - массивный широкотелый, объемный.
                    Крупная, выразительная голова. Несмотря на внушительные объёмы - хороший темперамент и баланс.
                    Третьим эксперт поставил прошлогоднего фаворита Филу ф. Рёйбервег (Брутус ф. Рёйбервег + Мона ф.
                    Остзаксен). За прошедший год Филу заметно заматерел, прибавил в объёмах, но в то же время из-за
                    недостаточного физического тренинга стал уступать конкурентам по силе и энергичности движений.
                    Четвертое место досталось Нико ф. д. Шерау (Рекс ф. Хаузе Фрик + Краббе ф.д. Шерау). Крупный,
                    для четырех лет несколько плосковата грудная клетка. Показан в рабочей кондиции. В целом класс
                    чемпионов кобелей можно охарактеризовать, как слабый, действительно выдающихся собак было меньше
                    половины, а многие известные собаки пребывали не в лучшей кондиции. Что поразило особенно неприятно
                    - более половины класса – безголовые, мелковатые кобели, непонятно как получившие титул национального
                    чемпиона, я уже не говорю о большем.. Та же ситуация с классом чемпионов сук. Немногочисленный,
                    средний по качеству класс. 15 собак по каталогу, выставились всего 13. Судил Х.Ю.Радке. Первое
                    место он отдал Сидни ф. Хаузе Нойбранд (Хейко ф. Хаузе Энциан + Кора ф. Ресслесгартен). Не вижу
                    смысла описывать эту суку – она прекрасно известна всем любителям ротвейлера, кто хотя бы немного
                    интересуется породой. Её титулов не счесть, причем полученных в острейшей кокурентной борьбе.
                    Сидни очень хороша по типу, но в этом году она находилась не в лучшей физической форме – очевидно,
                    как последствие ложной щенности, сильно отвисшее вымя, несколько неуместно смотрелось у собаки,
                    стоящий на первом месте в классе чемпионов. Второе место заняла Шарон делль’Антико Гверро (Клифф ф.д.
                    Кёнигсканцель + Июма делль’Антико Гверро) очень породная сука, находящаяся на пике физической формы.
                    Она так же многократно и успешно показывалась на АДРК в предидущие годы. Собака высочайшего класса,
                    очень жизнерадостная, куражная. Этот год вполне мог бы стать её звездным часом в карьере… но класс
                    чемпионов на выставке АДРК это не совсем то место, где возможна победа иностранной собаки, пусть даже
                    трижды топ модели... В принципе, серьезными конкурентами были по большому счету лишь первые две собаки,
                    остальные напоминали группу достаточно разношерстных статистов. Тем не менее – третья в классе
                    Мальве ф. Обергромбахер Шлосс (Рик ф. Бургтанн + Дженни ф. Обергромбахер Шлосс) костистая,
                    крепкая сука с обширным белёсым подпалом. Четветое место также у итальянской участницы -
                    Дида (Аркон делль’Антико Гверро + Кора ф. Хаузе Энциан) Правильно сложена, хорошего костяка,
                    голова без излишеств, умеренный темперамент. В классе ветеранов был показан легендарный
                    производитель, отличная рабочая и выставочная собака – девятилетний Акино ф.д. Лаутербрюке.
                    Глядя на него, задумываешься о том, что не каждому суждено стать легендой при жизни – это
                    приоритет избранных. Конкурс питомников собрал 7 участников - рекордное для последних лет количество.<br/><br/><br/>

                    Места распределились так:<br/><br/><br/>

                    &nbsp; «Арзадон» (Дания)
                    &nbsp; «ф. Хаузе Нойбранд» (Германия)
                    &nbsp;«ф.д. Шерау» (Германия)
                    &nbsp; «Эрл Антониус» (Сербия)
                    &nbsp; «Чрни Лотос» (Сербия)
                    &nbsp;«Делль’Антико Гверро» (Италия)
                    &nbsp; «Берега Туры» ( Россия)<br/><br/><br/>

                    &nbsp;&nbsp;&nbsp; На мой взгляд очень сильную и однотипную группу представил в этом году Б. Кильмер («ф.д. Шерау»).
                    Было отчетливо виден вкус заводчика, очень высокое качество и однотипность представителей этого
                    известного питомника. По этой группе можно было точно понять, к чему стремиться заводчик в своём
                    разведении У многих конкурсантов в группах были представлены качественные, но очень противоречивые
                    собаки. Тем не менее выбор эксперта не подлежит обсуждению, тем более в таком субъективном деле,
                    как выбор лучшего питомника. В конкурсе производителей «солировал» Гонззо Эрл Антониус, показавший
                    ровную, тщательно подобранную группу потомков. При полном отсутствии конкурентов он получил титул
                     лучшего производителя АДРК 2006. На этом экспертиза в классах закончилась, сравнительные ринги
                    завершились вполне прогнозируемо – клубными победителями 2006 стали Дьюк ф. Фильсталер Ланд и Сидни
                    ф. Хаузе Нойбранд. В год празднования 30 летия питомника семьи Нойбрандов, победа Сидни стала для
                    них лучшим подарком. Лучшими иностранными собаками выбрали Чанси ф. Босниа и Куно ф. Рабеншлосс.
                    Нововведенный в этом году титул «Kombisieger» - комбинированный победитель получил Juri v.d.
                    Bleichstrasse, особенно известный отличными рабочими качествами и стабильными выставочными успехами.
                    Кратко резюмируя прошедшую выставку, хотелось бы отметить несколько важных моментов. Наиболее
                    значимыми и востребованными современными направлениями является использование производители линий
                    Рик ф. Бургтанн, Нориса ф. Грюнтенблик, еще более усилилось влияние линии Торро ф. Циммерплатц, в
                    основном через потомков Мамбо ф.д. Тойфельсбрюке. По прежнему значима и сильна линия Хасан ф.
                    Кенигсгартен через Ирка ф. Обергромбахер Шлосс (в Германии) и его старшего брата Криса ф.
                    Обергромбахер Шлосс (в странах Восточной Европы). Довольно заметно прогрессирует еще одна ветвь
                    Хассана, через Мирко ф. Бикесхайм –Энди ф.д. Курпфальц. Стоит особо подчеркнуть, что прошедшее
                    мероприятие состоялось в 99 год существования АДРК. В 2007 году клуб отпразднует свое столетие.
                    Конечно, любое крупное событие вызывает бурю эмоций в душе всех, кто неравнодушен к вопросу. Любой
                    владелец или зритель мечтает, чтобы хоть на мгновение события развернулись так, как страстно желает
                    именно он! Но – победитель и судья – всегда один на один и невозможно быть собаке- абсолютным
                    победителем, а судье – мессией. Почему? – да просто потому, что за самым венценосным победителем
                    всегда будет стоять тот самый проигравший, владелец которого горько посетует на неудачу, а за
                    спиной матерого судьи – сотни «злобных зрителей» в умах которых конечно всегда есть план безупречного
                    судейства. Тут, как нельзя, кстати вспомнились слова из старой детской книжки: « Так как же быть,
                    коль ничего мы изменить не можем?! Себе мы сами тут поможем!» В этом смысле стоит научиться читать
                    и смотреть на явления, как говориться, «между строк» - ведь часто совсем не победители становятся
                    главными на празднике жизни, а те кто возможно где то рядом? И не умение свистеть и кидать сливы в
                    судей (история АДРК знавала и такое!) отличает беспристрастного и интеллектуального зрителя от
                    озлобленного дурака, а способность анализировать и думать, думать, думать…. А стремиться к цели и
                    работать на свою мечту стоит всегда, как например наш герой, тот самый истинный поклонник породы,
                    грёзы которого, я уверена, обязательно сбудутся...
                </p>
            </div>
            <div ><p>Ольга Гринь </p></div>
        </div>
    );
}
export default Exhibition;