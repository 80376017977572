import React from "react";
import RussenVIVA from "../../../images/dogs/RUSSEN_ROTTENSCHILD_INFINITA_SEMPRE_VIVA.JPG"
import style from "../Dogs.module.css"
const VIVA = () => {
    return(

        <div>
            <div>

            <div>
                <h1 align="center" className={style.H1}>RUSSEN ROTTENSCHILD INFINITA SEMPRE VIVA</h1></div>
            <img src={RussenVIVA} className={style.img}/>
            </div>
            <div>
                <h3 align="center" className={style.H3}>Pedigree</h3>
            </div>
            <div className={style.fontTable} align="right">
                <table border="3" width="900" height= "640">
                    <tbody>
                    <tr>
                        <td rowSpan="8" >
                            <p align="center">
                                RKF CH,
                                RUSSEN ROTTENSCHILD КОКS
                                ч-п
                            </p>
                        </td>
                        <td rowSpan="4" >
                            <p align="center">
                                CHERNOE BRATSTVO LANCELOT</p>
                            <p align="center">ч-п</p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                JUDA FLASH ROUSE
                            </p>
                        </td>
                        <td  height="12">
                            MERLIN FLASCH ROUSE
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            FUKSA GERMAN BLOOD LINE
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                CHERNOE BRATSTVO KRALJA  ч-п</p>
                        </td>
                        <td  height="12">
                            FLEGEL V. TEGLER FLIESS
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            OLBURD TEKILA
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="4" >
                            <p align="center">
                                RUS CH
                                HELLA V.D. CROSSENER RANCH  ч-п
                                Grin O.A.& Donzov V.A.
                            </p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                MAMBO VON DER CROSSENER RANCH</p>
                                <p align="center">ч-п</p>
                        </td>
                        <td  height="12">
                            RICK V. BURGHTANN
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            EVI V.D. CROSSENER RANCH
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                KIRA V. MUHLBERG </p>
                                <p align="center"> ч-п </p>
                        </td>
                        <td  height="12">
                            ORLANDO V. HAUSE NEUBRAND
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            ELVI V.D. CROSSENER RANCH
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="8" >
                            <p align="center">
                                RUSSEN ROTTENSCHILD TANA</p>
                        </td>
                        <td rowSpan="4" >
                            <p align="center">
                                SHERIFROTTHAUS  HOWARD  ч-п
                            </p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                FILOU VOM RAUBERWEG</p>
                        </td>
                        <td  height="12">
                            BRUTUS V RAUBERWEG
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            MONA VON OSTSACHSEN
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                CHERNY LEGION LIRA  ч-п</p>
                        </td>
                        <td  height="12">
                            INDIO OF NICOLAS LION
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            CHERNY LEGION HONDA
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="4" >
                            <p align="center">
                                RUSSEN ROTTENSCHILD ZOLLY</p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                RUSSEN ROTTENSCHILD KARAT</p>
                        </td>
                        <td  height="12">
                            Ч.OLBURD ARON Дипл: HD-
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            INGOLDA
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                RUSSEN ROTTENSCHILD DEBORA</p>
                        </td>
                        <td  height="12">
                            ELEFANT S BEREGA TURY
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            MARISKA
                        </td>
                    </tr>
                    </tbody>
                </table>

            </div>
        </div>
    );
}
export default VIVA;