import React from "react";
import style from "./Champions.module.css"
import NotFount from "../NotFount/NotFount";
import KRETA from "../../images/dogs/RUSSEN_ROTTENSCHILD_KRETA.jpg";
import {NavLink} from "react-router-dom";

const Champions = () => {
    return(
        <div>
            <NotFount/>
            {/*<div >*/}
            {/*<img src={KRETA}  className={style.img}/>*/}
            {/*    <div />*/}
            {/*    <div className={style.text}>*/}
            {/*<p >Чемпион России</p>*/}
            {/*<p >RUSSEN ROTTENSCHILD KRETA</p>*/}
            {/*<p >(RUSSEN ROTTENSCHILD KOKS + RUSSEN ROTTENSCHILD YAVA)</p>*/}
            {/*</div>*/}
            {/*<div>*/}
            {/*    <img src={KRETA} className={style.img}/>*/}
            {/*</div>*/}
            {/*    <div className={style.text}>*/}
            {/*    <p color="RED">Чемпион России</p>*/}
            {/*    <p color="RED">RUSSEN ROTTENSCHILD KRETA</p>*/}
            {/*    <p color="RED">(RUSSEN ROTTENSCHILD KOKS + RUSSEN ROTTENSCHILD YAVA)</p>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>

    );
}
export default Champions;