import React from "react";
import style from "../Sale.module.css";
import {NavLink} from "react-router-dom";
import DOR_ava from "../../../images/russen_rottenschild_dorhan.png";
import oneOrange from "../../../images/dogs/Puppu/DORHANYAVA/males/1 m orange.JPG";
import oneMaleOrange from "../../../images/dogs/Puppu/DORHANYAVA/males/1 male orange.JPG";
import twoYellow from "../../../images/dogs/Puppu/DORHANYAVA/males/2 m yellow.JPG";
import twoMaleYellow from "../../../images/dogs/Puppu/DORHANYAVA/males/2 male yellow.JPG";
import threeGreen from "../../../images/dogs/Puppu/DORHANYAVA/males/3 m green.JPG";
import threeMaleGreen from "../../../images/dogs/Puppu/DORHANYAVA/males/3 male green.JPG";
import fourBlue from "../../../images/dogs/Puppu/DORHANYAVA/males/4 m blue.JPG";
import fourMaleBlue from "../../../images/dogs/Puppu/DORHANYAVA/males/4 male blue.JPG";
import cablesGeneral from "../../../images/dogs/Puppu/DORHANYAVA/males/5 males Dorhan Yava  22.07.20.JPG";
import sixBlue from "../../../images/dogs/Puppu/DORHANYAVA/bitches/6 FEMALE BLUE.JPG";
import eightGreen from "../../../images/dogs/Puppu/DORHANYAVA/bitches/8 FEM GREEN.JPG";
import tenRosa from "../../../images/dogs/Puppu/DORHANYAVA/bitches/10 F ROSA.JPG";
import FEMALES from "../../../images/dogs/Puppu/DORHANYAVA/bitches/R - FEMALES.JPG";
import elevenOrange from "../../../images/dogs/Puppu/DORHANYAVA/bitches/11 F ORANGE.JPG";
import RED from "../../../images/dogs/Puppu/DORHANYAVA/bitches/12. F. RED.JPG";
import RUSSEN_ROTTENSCHILD_YAVA from "../../../images/RUSSEN_ROTTENSCHILD_YAVA.png";

const PuppyDorhanYava = () => {
    return(

        <div>

            <div className={`${style.blokFont} ${style.fontLink}`}>
                <p align="left">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <NavLink to="/Dorhan" activeClassName={style.activeLink}>RUSSEN ROTTENSCHILD DORHAN</NavLink> & &nbsp;&nbsp;&nbsp;
                    <NavLink to="/Yava" activeClassName={style.activeLink}>RUSSEN ROTTENSCHILD YAVA</NavLink> </p>
                <img src={DOR_ava} width="300" height="300" hspace="100"/>
                <img src={RUSSEN_ROTTENSCHILD_YAVA} width="300" height="300"/>
            </div>
            <div className={style.fontTable} align="left">
                <table border="3" width="1000" height= "640">
                    <tbody>
                    <tr>
                        <td rowSpan="8" >
                            <p align="center">
                                RUSSEN ROTTENSCHILD  DORHAN
                            </p>
                        </td>
                        <td rowSpan="4" >
                            <p align="center">
                                Rus  CH
                                RUSSEN ROTTENSCHILD HIGHLANDER  ч-п</p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                LEX V. HAUSE EDELSTEIN
                            </p>
                        </td>
                        <td  height="12">
                            BRABUS V. HAUSE EDELSTEIN
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            CYM OD VADANORA
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                RUSSEN ROTTENSCHILD ELMA</p>
                        </td>
                        <td  height="12">
                            FELIX V. HAUS ZSCHAMMER Дипл: SchH -I, II,III, BH  ZTP HD-A ED-I Grin O.A.& Donzov V.A.
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            MAGGY
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="4" >
                            <p align="center">
                                SHERIFFROTHAUS DOROTY  ч-п
                            </p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                RUSSEN ROTTENSCHILD CAESAR II  ч-п</p>
                        </td>
                        <td  height="12">
                            FELIX V. HAUS ZSCHAMMER Дипл: SchH -I, II,III, BH  ZTP HD-A ED-I Grin O.A.& Donzov V.A.
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            PANDORA VOM RAUBERWEG
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                CHERNY LEGION LIRA <br/> ч-п</p>
                        </td>
                        <td  height="12">
                            INDIO OF NICOLAS LION
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            CHERNY LEGION HONDA
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="8" >
                            <p align="center">
                                RUSSEN ROTTENSCHILD YAVA
                                ч-п</p>
                        </td>
                        <td rowSpan="4" >
                            <p align="center">
                                SHERIFROTTHAUS  HOWARD<br/>  ч-п
                            </p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                FILOU VOM RAUBERWEG</p>
                        </td>
                        <td  height="12">
                            BRUTUS V RAUBERWEG
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            MONA VON OSTSACHSEN
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                CHERNY LEGION LIRA<br/>  ч-п</p>
                        </td>
                        <td  height="12">
                            INDIO OF NICOLAS LION
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            CHERNY LEGION HONDA
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="4" >
                            <p align="center">
                                RUSSEN ROTTENSCHILD YUSTA</p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                CHERNOE BRATSTVO LANCELOT  ч-п</p>
                        </td>
                        <td  height="12">
                            JUDA FLASH ROUSE
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            CHERNOE BRATSTVO KRALJA
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                RUSSEN ROTTENSCHILD  CESA</p>
                        </td>
                        <td  height="12">
                            FELIX V. HAUS ZSCHAMMER Дипл: SchH -I, II,III, BH  ZTP HD-A ED-I Grin O.A.& Donzov V.A.
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            PANDORA VOM RAUBERWEG
                        </td>
                    </tr>
                    </tbody>
                </table><br/><br/>

            </div>
            <div className={`${style.blokName} ${style.blokNameFont}`}>
                <p align="center" color="white">Кобели<br/>
                </p>
            </div>
            <div>
                <br/><br/>
                <div className={`${style.blokName} ${style.blokNameFont}`}>
                    <p align="center" color="white">№ 1
                    </p>
                </div>
                <img src={oneOrange} width="1200" height="800"/><br/><br/>
                <p align="center"> <img src={oneMaleOrange} width="600" height="800"/></p><br/><br/>
                <br/><br/>
                <div className={`${style.blokName} ${style.blokNameFont}`}>
                    <p align="center" color="white">№ 2
                    </p>
                </div>
                <p align="center"><img src={twoMaleYellow} width="700" height="800"/></p>
                <img src={twoYellow} width="1200" height="900"/>
                <br/><br/>
                <div className={`${style.blokName} ${style.blokNameFont}`}>
                    <p align="center" color="white">№ 3
                    </p>
                </div>
                <br/>
                <img src={threeGreen} width="1200" height="900"/>
                <p align="center"><img src={threeMaleGreen} width="600" height="800"/></p>
                <br/><br/>
                <div className={`${style.blokName} ${style.blokNameFont}`}>
                    <p align="center" color="white">№ 4
                    </p>
                </div>
                <br/>
                <img src={fourBlue} width="1200" height="800"/>
                <p align="center"><img src={fourMaleBlue} width="600" height="800"/></p>
                <br/><br/>
                <div className={`${style.blokName} ${style.blokNameFont}`}>
                    <p align="center" color="white">Общее фото. Кобели.
                    </p>
                </div>
                <br/>
                <img src={cablesGeneral} width="1200" height="700"/>
                <br/><br/><br/>
            </div>
            <div className={`${style.blokName} ${style.blokNameFont}`}>
                <p align="center" color="white">Суки
                </p>
            </div>
            <br/><br/>

            <div className={`${style.blokName} ${style.blokNameFont}`}>
                <p align="center" color="white">№ 1
                </p>
            </div>
            <br/>
            <p align="center"> <img src={sixBlue} width="600" height="800"/></p>

            <div className={`${style.blokName} ${style.blokNameFont}`}>
                <p align="center" color="white">№ 2
                </p>
            </div>
            <p align="center"><img src={eightGreen} width="600" height="800"/></p>

            <div className={`${style.blokName} ${style.blokNameFont}`}>
                <p align="center" color="white">№ 3
                </p>
            </div>
            <p align="center"> <img src={tenRosa} width="600" height="800"/></p>

            <div className={`${style.blokName} ${style.blokNameFont}`}>
                <p align="center" color="white">№ 4
                </p>
            </div>
            <p align="center"><img src={elevenOrange} width="600" height="800"/></p>

            <div className={`${style.blokName} ${style.blokNameFont}`}>
                <p align="center" color="white">№ 5
                </p>
            </div>
            <p align="center"><img src={RED} width="700" height="800"/></p>

            <div className={`${style.blokName} ${style.blokNameFont}`}>
                <p align="center" color="white">Суки. общее фото.
                </p>
            </div>
            <img src={FEMALES} width="1200" height="800"/>
        </div>
    );
}
export default PuppyDorhanYava;