import React from "react";
import style from "../Sale.module.css";
import {NavLink} from "react-router-dom";
import DOMINATOR from "../../../images/dogs/DOMINATOR.JPG";
import RR_DIAMANTA from "../../../images/dogs/RR_DIAMANTA.JPG";
import DOMINATORDIAMANTA from "../../../images/video/DOMINATOR&DIAMANTA.mp4";
import one from "../../../images/dogs/Puppu/ZHAKKONCORDIA/one.JPG";
import two from "../../../images/dogs/Puppu/ZHAKKONCORDIA/two.JPG";
import three from "../../../images/dogs/Puppu/ZHAKKONCORDIA/three.JPG";
import four from "../../../images/dogs/Puppu/ZHAKKONCORDIA/four.JPG";
import five from "../../../images/dogs/Puppu/ZHAKKONCORDIA/five.JPG";
import six from "../../../images/dogs/Puppu/ZHAKKONCORDIA/six.JPG";
import seven from "../../../images/dogs/Puppu/ZHAKKONCORDIA/seven.JPG";
import eight from "../../../images/dogs/Puppu/ZHAKKONCORDIA/eight.JPG";
import nine from "../../../images/dogs/Puppu/ZHAKKONCORDIA/nine.JPG";
import ten from "../../../images/dogs/Puppu/ZHAKKONCORDIA/ten.JPG";
import ZHAK from "../../../images/dogs/ZHAK.JPG";
import RR_KONCORDIA from "../../../images/dogs/RR_KONCORDIA.JPG";


const PuppyZHAKKONCORDIA = () => {
    return (
        <div>

            <div className={`${style.blokFont} ${style.fontLink}`}>
                <p align="center">
                    <NavLink to="/ZHAK" activeClassName={style.activeLink}>ZHAK V HAUS BELOGORIE</NavLink> &
                    <NavLink to="/KONCORDIA" activeClassName={style.activeLink}>RUSSEN ROTTENSCHILD KONCORDIA</NavLink>
                </p>
                <img src={ZHAK} width="300" height="300" hspace="100"/>
                <img src={RR_KONCORDIA} width="300" height="300"/>
            </div>
            <div>
                <p className={style.text}>Щенки родились 02 Мая 2021. Отец ZHAK V HAUS BELOGORIE,
                    мать RUSSEN ROTTENSCHILD KONCORDIA.
                    9 щенков. 3 кобеля, 6 сук. Для бронирования доступны 1  кобель  и  2 суки</p>
            </div>
            <div>
                <h3 align="center" className={style.H3}>Pedigree</h3>
            </div>
            <div className={style.fontTable} align="right">
                <table border="3" width="900" height="640" align="center">
                    <tbody>
                    <tr>
                        <td className="parent" valign="middle" align="center">
                            <p>
                                I. generation</p>
                        </td>
                        <td className="parent" valign="middle" align="center">
                            <p>
                                II. generation</p>
                        </td>
                        <td className="parent" valign="middle" align="center">
                            <p>
                                III.generation</p>
                        </td>
                        <td className="parent" valign="middle" align="center">
                            <p>
                                IV. generation</p>
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="8">
                            <p align="center">
                                ZHAK V HAUS BELOGORIE
                            </p>
                        </td>
                        <td rowSpan="4">
                            <p align="center">
                                ARAMIS DAREL</p>
                        </td>
                        <td rowSpan="2">
                            <p align="center">
                                KRIS KLAID
                            </p>
                        </td>
                        <td height="12">
                            MORRO V.D. SHERAU
                        </td>
                    </tr>
                    <tr>
                        <td height="12">
                            RUSS PROZIT AIRIN
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2">
                            <p align="center">
                                XINDY V. WILDEN WESTEN
                            </p>
                        </td>
                        <td height="12">
                            JUMBO VOM KUMMELSEE
                        </td>
                    </tr>
                    <tr>
                        <td height="12">
                            PAULA VOM WILDEN WESTEN
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="4">
                            <p align="center">
                                ROSHELLE V HAUS BELOGORIE
                            </p>
                        </td>
                        <td rowSpan="2">
                            <p align="center">
                                KAMARO V. KUMMЕLSEE</p>
                        </td>
                        <td height="12">
                            ELVIS VON DER MUHLBACHSTRASSE
                        </td>
                    </tr>
                    <tr>
                        <td height="12">
                            PATIA VOM KUMMELSEE
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2">
                            <p align="center">
                                TRIXI V.D. EPER DINKEL</p>
                        </td>
                        <td height="12">
                            HENRY VON DEN HASSBERGHOHEN
                        </td>
                    </tr>
                    <tr>
                        <td height="12">
                            DIXI VOM WACHBERG
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="8">
                            <p align="center">
                                RUSSEN ROTTENSCHILD KONCORDIA</p>
                        </td>
                        <td rowSpan="4">
                            <p align="center">
                                Rus  CH
                                RUSSEN ROTTENSCHILD HIGHLANDER  ч-п</p>
                        </td>
                        <td rowSpan="2">
                            <p align="center">
                                LEX V. HAUSE EDELSTEIN
                            </p>
                        </td>
                        <td height="12">
                            BRABUS V. HAUSE EDELSTEIN
                        </td>
                    </tr>
                    <tr>
                        <td height="12">
                            CYM OD VADANORA
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2">
                            <p align="center">
                                RUSSEN ROTTENSCHILD ELMA</p>
                        </td>
                        <td height="12">
                            FELIX V. HAUS ZSCHAMMER
                        </td>
                    </tr>
                    <tr>
                        <td height="12">
                            MAGGY
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="4">
                            <p align="center">
                                RUS CH<br></br>
                                RUSSEN ROTTENSCHILD TEODORA  ч-п</p>
                        </td>
                        <td rowSpan="2">
                            <p align="center">
                                RUSSEN ROTTENSCHILD КОКS  ч-п
                            </p>
                        </td>
                        <td height="12">
                            CHERNOE BRATSTVO LANCELOT
                        </td>
                    </tr>
                    <tr>
                        <td height="12">
                            HELLA V.D. CROSSENER RANCH Grin O.A.& Donzov V.A.
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2">
                            <p align="center">
                                SHERIFFROTHAUS DOROTY  ч-п</p>
                        </td>
                        <td height="12">
                            RUSSEN ROTTENSCHILD CAESAR II
                        </td>
                    </tr>
                    <tr>
                        <td height="12">
                            CHERNY LEGION LIRA
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div></div>
            <div>
                <p align='center'>
                    <img src={one} width="688" height="486"/><br/><br/>
                </p>
                <p align='center'>
                    <img src={two} width="662" height="664"/><br/><br/>
                </p>
                <p align='center'>
                    <img src={three} width="940" height="630"/><br/><br/>
                </p>
                <p align='center'>
                    <img src={four} width="940" height="630"/><br/><br/>
                </p>
                <p align='center'>
                    <img src={five} width="940" height="630"/><br/><br/>
                </p>
                <p align='center'>
                    <img src={six} width="940" height="630"/><br/><br/>
                </p>
                <p align='center'>
                    <img src={seven} width="940" height="630"/><br/><br/>
                </p>
                <p align='center'>
                    <img src={eight} width="731" height="1121"/><br/><br/>
                </p>
                <p align='center'>
                    <img src={nine} width="835" height="576"/><br/><br/>
                </p>
                <p align='center'>
                    <img src={ten} width="795" height="1198"/><br/><br/>
                </p>

            </div>
        </div>
    );
}
export default PuppyZHAKKONCORDIA;