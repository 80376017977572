import React from "react";
import style from "../Dogs.module.css"
import stand from "../../../images/dogs/Dexter/stay.JPG"
import Dexter_stand from "../../../images/dogs/Dexter/DexterStand.JPG"
import sit1 from "../../../images/dogs/Dexter/sit1.jpg"
import sit2 from "../../../images/dogs/Dexter/sit2.jpg"
import lay from "../../../images/dogs/Dexter/lay.jpg"
import withTheOwner from "../../../images/dogs/Dexter/withTheOwner.jpg"

const DEXTER  = () => {
    return (
        <div>
            <div>

                <div>
                    <h1 align="center" className={style.H1}>DEXTER V.D. WEISSENSTADT</h1></div>
                <img  src={stand}  className={style.img}/>
            </div>
            <div>
                <h3 align="center" className={style.H3}>Pedigree</h3>
            </div>
            <div className={style.fontTable} align="left">
                <table border="3" width="900" height= "640">
                    <tbody>
                    <tr height="30px">
                        <td className="parent" valign="middle" align="center">
                            I. GENERATION
                        </td>
                        <td className="parent" valign="middle" align="center" >

                                II. GENERATION
                        </td>
                        <td className="parent" valign="middle" align="center" >

                                III.GENERATION
                        </td>
                        <td className="parent" valign="middle" align="center" >

                                IV. GENERATION
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="8" >
                            <p align="center">
                                SRECKO TIMIT TOR
                            </p>
                        </td>
                        <td rowSpan="4" >
                            <p align="center">
                                DOCTOR TIMIT TOR</p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                LUCIFER TIMIT TOR
                            </p>
                        </td>
                        <td  height="12">
                            LEX V. HAUSE EDELSTEIN
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            PAMELA TIMIT-TOR
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                HERA TIMIT TOR
                            </p>
                        </td>
                        <td  height="12">
                            HERO V.TONBERGER HOHE
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            SEVERINA TIMIT TOR
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="4" >
                            <p align="center">
                                NIKOLE TIMIT-TOR
                            </p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                REX TIMIT TOR</p>
                        </td>
                        <td  height="12">
                            RICK DAREL
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            SEVERINA TIMIT TOR
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                OLIMPIA TIMIT TOR</p>
                        </td>
                        <td  height="12">
                            LEX V. HAUSE EDELSTEIN
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            SEVERINA TIMIT TOR
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="8" >
                            <p align="center">
                                DRAMA V.D. WEISSENSTADT</p>
                        </td>
                        <td rowSpan="4" >
                            <p align="center">
                                DOCTOR TIMIT TOR</p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                LUCIFER TIMIT TOR
                            </p>
                        </td>
                        <td  height="12">
                            LEX V. HAUSE EDELSTEIN
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            PAMELA TIMIT-TOR
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                HERA TIMIT TOR</p>
                        </td>
                        <td  height="12">
                            HERO V.TONBERGER HOHE
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            SEVERINA TIMIT TOR
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="4" >
                            <p align="center">
                                OKATA TIMIT TOR</p>
                        </td>
                        <td rowSpan="2" >
                            <p align="center">
                                LEX V. HAUSE EDELSTEIN
                            </p>
                        </td>
                        <td  height="12">
                            BRABUS V. HAUSE EDELSTEIN
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            CYM OD VADANORA
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2" >
                            <p align="center">
                                SEVERINA TIMIT TOR</p>
                        </td>
                        <td  height="12">
                            STING HOUSE OF LAZIC
                        </td>
                    </tr>
                    <tr>
                        <td  height="12">
                            OLIMPIA  VOM HAUSE NADJA
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div className={style.font}>
                <div>
                    <br/>
                    <p className={style.text}>ПРЕДСТАВЛЯЕМ  МОЛОДОГО ПЕРСПЕКТИВНОГО ПРОИЗВОДИТЕЛЯ ПИТОМНИКА !

                        DEXTER VOM WEISSENSTADT  ака DARK .
                        Чемпион России,Чемпион РКФ, ВН,   HD+\- ED – JLPP  CLEAN by PARENTS, COAT LENGTH  N\N.
                        DEXTER  приобретен  длянашего  питомника  от ведущих европейских производителей, получен  на кровях  питомника TIMIT TOR
                        отец - SRECKO TIMIT TOR
                        мать  DRAMA V. WEISSENSTADT.
                        DEXTER получен в инбридинге II-II на  SERBIAN CH, BIS  DOCTOR TIMIT TOR .
                        DEXTER     является типичным потомком этого стабильного производителя:  имеет крепкий  , в меру грубый   конституциональный тип, сильный костяк, компактное сложение, хорошую длину предплечий,  отличный баланс углов  передних и задних конечностей .
                        Отличительной особенностью этой линии  и ее украшением  являются  крупные, выразительные головы, четкого рельефа,  отличный пигмент, глубоко  темные глаза.
                        Основные промеры  DEXTER VOM WEISSENSTADT :
                        высота в холке 66 см,  пясть 14 см  вес  62 кг,  цвет глаз 1B.
                        подпал  темно красный,  яркого  рисунка, идеальная шерсть правильной структуры .
                        DEXTER VOM WEISSENSTADT имеет  стабильную уравновешенную психику,  без признаков неконтролируемой  агрессии, дружелюбен в общении с детьми и  животными.
                        DEXTER VOM WEISSENSTADT  доступен для вязок с достойными  суками, имеющими   соответствующие тесты  по здоровью и  характеру.
                    </p>
                </div>
            </div>

             <div>
                 <br/><br/>
                 <img src={Dexter_stand} className={style.img}  hspace="100"/>
                 <br/><br/>
                <img src={sit1} width="940" height="1408"  hspace="100"/>
                 <br/><br/>
                <img src={sit2} width="936" height="1314"  hspace="100"/>
                 <br/><br/>
                <img src={lay} width="935" height="1191"  hspace="100"/>
                 <br/><br/>
                <img src={withTheOwner} width="930" height="1310"  hspace="100"/>

            </div>


        </div>
    );
}
export default DEXTER ;