import React from "react";

const InterviewWithOlgaGrin = () => {
    return(
        <div>
            <div ><h3>Интервью c Ольгой Гринь.</h3></div>
            <div>
                <p>
                &nbsp;&nbsp;&nbsp;1. По результатам опроса Вы признаны лучшим российским экспертом по породе ротвейлер, это событие порадовало?<br/><br/>

                &nbsp;&nbsp;&nbsp;Я делаю свою судейскую работу искренне, выражая свое мнение через оценки. Всегда задумываюсь о том,
                насколько актуальна та или иная собака для современных условий. Судья должен принимать решение в любом
                случае, опираясь на схему стандарта, собственный опыт и знания. И если при этом мое мнение и стиль
                экспертизы совпадает с приоритетами коллег - судей и ведущих заводчиков - это безусловно радует.
                Признание тех, кто действительно разбирается в принципиальных деталях, особенно приятно, тем более,
                что опрос носил открытый характер, где все желающие прямо высказали свое мнение.<br/><br/>

                &nbsp;&nbsp;&nbsp;2. Расскажите о своей судейской карьере.<br/><br/>

                &nbsp;&nbsp;&nbsp;Она началась в 1986 году, когда по окончании курсов судей- экспертов по экстерьеру и рабочим качествам
                собак при Татарском РКСС в Казани я получила удостоверение эксперта третьей категории. Практически
                одновременно я стала студенткой Казанского Государственного Университета Ветеринарной Медицины.
                Пять лет обучения в старейшем ВУЗе страны дали мне роскошную возможность глубочайшим образом постичь
                знания, необходимые для всестороннего изучения дисциплин, которые необходимы высококвалифицированному
                судье. Нормальная и патологическая анатомия, физиология, многие специальные предметы эти знания служат
                мне и сейчас. Постепенно я набиралась опыта, проходя многочисленные стажировки на выставках огромной
                страны, тогда еще СССР, стажировки по породам, семинары, кинологическая жизнь бурлила, и мне было
                искренне интересно участвовать в ней. Как и полагалось по тем временам, в течении нескольких лет я
                обрела первую категорию, а позже, став судьей FCI, после многочисленных стажировок по породам как в
                России, так и за рубежом я имею ранг судьи по всем породам FCI и Кермайстера по породе ротвейлер.
                Я прилагаю все усилия, для того чтобы быть постоянно развивающимся судьей, ведь породы не являются
                застывшей структурой, они постоянно подвергаются изменениям. Что касается экспертизы ротвейлера,
                то я считаю эту породу одной из наиболее сложных в судействе, особенно оценку головы.<br/><br/>

                &nbsp;&nbsp;&nbsp;3. Каков в вашем представлении современный ротвейлер?<br/><br/>

                &nbsp;&nbsp;&nbsp;Это несомненно крепкая, компактная собака, костистая, несколько выше среднего роста, не приземистая,
                умеренно высоконогая, обладающая классическим со стороны анатомии строением корпуса и углов конечностей,
                хорошо развитой сухой, объемной мускулатурой и прочными, упругими связками суставов конечностей.
                Половой диморфизм в породе выражен хрестоматийно - кобели крупные подчеркнуто массивнее сук,
                высокопередые с мощной холкой, детали головы более резкие, взгляд жестче, увереннее. Суки значительно
                элегантнее, рисунок линии верха мягче - не так резко выраженная холка. Голова не столь объемная, в
                сравнении с кобелем, но четкий рельеф и заполненность обязательны, взгляд мягче, более непосредственный.
                В любом случае взгляд не должен быть мрачным, угрюмым, пристальным. Ротвейлер достаточно крупноголовая
                собака, голова должна быть объемной, как можно более сухой, хорошо отточенной в деталях. Её размер
                гармонирует с корпусом. Особое значение при оценке головы, которое может правильно акцентировать
                только опытный в породе судья - желаемое породное выражение. Это трудноуловимое для новичка сочетание
                деталей черепа и морды, постав и разрез глаз, положение ушей, составляют общую картину - которая при
                оптимальном сочетании деталей представляет собой настоящий шедевр. Глаза темно коричневого цвета и
                как можно более темный пигмент пасти. Качество движений и темперамент играют важнейшую роль. На
                современных выставках ротвейлер без активного темперамента практически не имеет шансов на победу.
                Относительно необычная стать: хвост - новый предмет селекции. Предпочтительный хвост - длинный,
                саблевидный, держится предпочтительно ниже линии спины как в стойке так и в движении. Собак с
                качественными хвостами еще мало, но они уже есть и это является веским основанием для постепенного
                ужесточения судейства хвоста. На сегодняшний день дисквалификации подлежат собаки с изломами хвоста
                и те, у которых хвост закинут на спину и туго скручен в кольцо один или более раз . Таков современный
                ротвейлер в моем понимании, но оговорюсь сразу, что в мире подобных живых образцов идеала лишь
                считанные единицы, в основном чаще приходится судить вполне земных созданий. У каждого из них есть
                свои достоинства и свои недостатки я считаю это естественным явлением, порода на то и существует,
                чтобы посредством селекции заводчики возводили её к наиболее благородным чертам. Разведение животных
                высокого класса -это настоящее искусство.<br/><br/>

                &nbsp;&nbsp;&nbsp;4.Какие проблемы в отечественном поголовье Вы бы назвали наиболее существенными ?<br/><br/>

                &nbsp;&nbsp;&nbsp;Ротвейлер как порода подвергается жесточайшей селекции - чем скрупулезнее требования стандарта,
                тем труднее проводить отбор. Какими будут наши собаки - решать заводчикам, которые воплощая в жизнь
                собственные программы разведения придают породе вполне определенный колорит. Я считаю, что в России
                есть несколько талантливых заводчиков, которые точно знают, к какой цели они стремятся, чего ожидают
                от той или иной комбинации. Они дорожат своей репутацией, и стараются сделать каждый помет событием
                в жизни породы. Тем не менее, проблемы будут существовать и для их преодоления следует работать целенаправленно.<br/><br/>

                &nbsp;&nbsp;&nbsp;Мне хотелось бы обратить внимание заводчиков на проблемы повального удлинения формата - большинство
                российских ротвейлеров имеет проблемы поясницы - удлиненные, слабые, они крайне негативно влияют на
                общий баланс собаки. Проблемы фронта присутствуют в российском поголовье повсеместно. Прямые углы ПЛС,
                некорректный постав локтей, искривление и укорочение предплечий, размет, часто «козинец» .
                Также отмечу массовую безликость голов, отсутствие желаемого выражения. Положительные черты,
                присущие нашему поголовью - хороший костяк, качество шерсти, нет массовых проблем с пигментацией
                (за исключением отдельных линий) и цветом глаз.<br/><br/>

                &nbsp;&nbsp;&nbsp;Особое значение имеет сейчас проблема качества психики ротвейлеров. На выставках достаточно часто
                встречаются особи с психопатическими отклонениями, проявляющие агрессивное поведение в отношении
                судьи, владельца, собак. На мой взгляд никакие положительные свойства экстерьера не должны служить
                поводом для положительной оценки ротвейлера, и тем более его племенного использования, если его
                характер порочно агрессивен и агрессия проявляется публично. Я не считаю дисквалификацию собаки с
                единичной выставки, где была проявлена агрессия или произошел случай покуса, достаточно эффективной
                мерой защиты поголовья от данного порока. Дисквалификация должна быть долгосрочной, если по истечению
                срока дисквалификации ситуация повторяется - это должно быть достаточным основанием для принудительной
                стерилизации животного. Проблема назрела и возможности её решения пока еще в наших руках.<br/><br/>

                &nbsp;&nbsp;&nbsp;5.Какой опыт зарубежных заводчиков кажется Вам наиболее ценным?<br/><br/>

                &nbsp;&nbsp;&nbsp;Прежде всего это устоявшаяся, традиционная селекционная система Германии. Я имею в виду ZTP и KORUNG.
                Я достаточно много писала о них ранее. При высоком уровне контроля за качеством, поступающего в
                разведение племенного материала, очевидны достигнутые успехи. Из наиболее прогрессивных методик
                других стран мне интересна система “ментального теста”, принятая для ротвейлеров в Скандинавских
                странах когда по специальным критериям оценивается интеллектуальный уровень ротвейлера.<br/><br/>

                &nbsp;&nbsp;&nbsp;6. Кроме того, что Вы - состоявшийся эксперт, Вы успешный заводчик. Как Вы считаете, ценен ли опыт
                заводчика для судьи по породе?<br/><br/>

                &nbsp;&nbsp;&nbsp;Несомненно. Только зная все особенности, выращивания, проблемы роста и воспитания щенков, секреты
                качественного становления подростков, специфику содержания взрослых собак в питомнике, можно
                существенно расширить свой личный опыт и применить его на практике для проведения качественной
                экспертизы. Опыт заводчика ценен и в общем смысле, я не представляю себе судью по породе, претендующего
                на звание Кермайстера, который не вырастил лично ни одного помета и не отдрессировал собственноручно
                хотя бы одного ротвейлера.<br/><br/>

                &nbsp;&nbsp;&nbsp;7. Вы судили бесчисленное множество ротвейлеров, как в России, так и за рубежом. Какие моменты
                экспертизы, какие выставки запомнились более всего?<br/><br/>

                &nbsp;&nbsp;&nbsp;Мне довелось судить ротвейлеров во многих странах мира, где порода популярна и имеет высокий
                профессиональный уровень. Особой честью для судьи, по моему мнению, является приглашение провести
                экспертизу на выставках, уровня Национального Чемпионата. Такие приглашения никогда не бывают
                случайными и, как правило, оргкомитет таких мероприятий проводит тщательную селекцию кандидатур
                судей. Я счастлива тем, что была судьей на Национальных Чемпионатах в различных странах мира.
                Особенно важной ступенью для меня всегда было проведение экспертизы на иностранном языке - ведь в
                публичных комментариях судья должен передать публике все оттенки своего отношения к той или иной
                собаке, а сделать свою речь достаточно колоритной и убедительной в нюансах кинологической
                терминологии на иностранном языке - это высший пилотаж. Чтобы достичь его и свободно применить
                на практике - требуется немало усилий и времени.<br/><br/>

                &nbsp;&nbsp;&nbsp;По качеству проведения меня очень впечатлила крупная монопородная выставка в

                &nbsp;&nbsp;&nbsp;Рио-де-Жанейро, совмещенную с престижным САСIB, который я судила в мае этого года. Роскошное место
                проведения, хороший призовой фонд, профессиональный, фантастически красивый, показ собак самыми
                лучшими хэндлерами страны. На таком балу чувствуешь себя причастной к самому изысканному уровню
                судейского корпуса кинологического мира. Это приятно сознавать.<br/><br/>

                &nbsp;&nbsp;&nbsp;Сильные впечатления остаются у меня всегда после общения с покровителями и меценатами выставок,
                как правило, это глубоко удовлетворенные жизнью люди, для которых собаководство - вид утонченно-
                приятного способа постигнуть еще одну сторону нашего разнообразного мира. Среди таких людей мне
                довелось быть принятой в том числе и благородными титулованными особами, общение с которыми всегда
                очень увлекательно и интересно.<br/><br/>

                &nbsp;&nbsp;&nbsp;Вместе с тем, в моей душе навсегда останутся воспоминания, как в небольшие и часто очень далекие от
                центра российские города, даже, в критические для породы годы, благодаря настоящим энтузиастам и
                талантливым организаторам породы под мое судейство собирались сильнейшие заводчики и владельцы лучших
                ротвейлеров регионов.<br/><br/>

                &nbsp;&nbsp;&nbsp;Я знаю, какое количество личной энергии стоит затратить и организаторам и участникам таких выставок,
                поэтому я восхищена и глубоко тронута тем, что мое мнение важно и интересно для настоящих профессионалов
                и ответственных владельцев.<br/><br/>

                &nbsp;&nbsp;&nbsp; 8. Кого из увиденных Вами ротвейлеров Вы можете назвать приближенными к идеалу породы?<br/><br/>

                &nbsp;&nbsp;&nbsp; Я не могу назвать идеальным ни одного из увиденных мною, но встречала таких, от которых
                было невозможно оторвать взгляд. В английском языке есть очень краткий и точный термин для
                подобных случаев – «eye-catching» - притягивающий взгляд. В мире существует немного таких собак.
                Их выдающаяся породность и гармония вдохновляют, заставляют мечтать и дерзать. Не думаю, что клички
                играют какую-то конкретную роль, поскольку для каждого времени есть свои «ловцы взгляда», эти собаки
                по своему хороши и тем не менее имеют свои проблемы. Относительно понятия «тип» - считаю, что это
                условное для определенного отрезка времени сочетание предпочтительных экстерьерных характеристик или
                «течение» в породе, призванное решить определенные проблемы. Лично про себя скажу, что для меня
                положительное общее впечатление начинается с присутствия у собаки мощной породной харизмы -
                несомненно «точеная» голова с выдающимся породным выражением, общий баланс и безусловно доброжелательный характер.<br/><br/>

                &nbsp;&nbsp;&nbsp;9. Критикуют ли Вашу работу в качестве судьи и как Вы относитесь к критике?<br/><br/>

                &nbsp;&nbsp;&nbsp;Отвечу цитатой Д. Дидро: «Для истины достаточный триумф когда её принимают немногие, но
                достойные, быть угодной всем - не её удел». Тем не менее, если я являюсь публичным человеком
                и даю оценку экстерьера собак, то критика несомненно должна присутствовать в моей жизни - это
                значит, что моя работа никого не оставляет равнодушным. Это нормальное явление. Кроме того,
                любые критические замечания служат добрую службу, т.к. анализируя их, я могу возвысить уровень
                своей работы на еще более качественную ступень. Я не стесняюсь учиться и принимать что-то новое
                и полезное от моих коллег, мнение которых для меня интересно и важно. Наш мир развивается, на все
                можно посмотреть под разным углом. Все сказанное выше относится к цивилизованной критике.
                Как правило, аргументировано и взвешенно критикуют уверенные в себе, состоявшиеся в своем любимом
                деле люди. Невротически-истерично - неудачники не видящие очевидных проблем у своих собак. Если
                кто-то пытается выразить недовольство моей работой в оскорбительной форме, то я отношусь к этому
                с юмором - как сказал известный сатирик именно про этот случай - «Не могу же я вдруг, с высоты своего
                достоинства вдруг встать на четвереньки и залаять в ответ!».<br/><br/>

                &nbsp;&nbsp;&nbsp; 10. Вы прошли путь от новичка в породе до уважаемого судьи, какую линию для становления начинающих
                судей Вы считаете наиболее приемлемой?<br/><br/>

                &nbsp;&nbsp;&nbsp;По этому случаю я могу отослать Вас к статье «Жизненный цикл собаковода», я полностью разделяю с
                автором все, что написано в данной статье и здесь добавить мне нечего. Каждый когда- то начинал,
                но станет ли он признанным мастером своего дела, зависит от личных качеств человека. Повторю лишь
                то, что кажется мне важным. Для того чтобы стать хорошим судьей следует знать не только анатомию
                и прочие сопутствующие дисциплины. Вы должны любить то, чем Вы занимаетесь. Судейство собак - это
                мастерство, которому можно научиться, но стать - признанным в этом деле без искреннего отношения к
                породе нельзя.<br/><br/>

                &nbsp;&nbsp;&nbsp; 11. Что бы Вы хотели пожелать начинающим судьям и любителям ротвейлеров?<br/><br/>

                &nbsp;&nbsp;&nbsp;Начинающий судья - это для меня в большей степени теоретик. Желаю всем, кто начинает, не
                задерживаться на абстрактном этапе. Больше практики, больше собственноручных описаний, больше
                воочию увиденных и тщательно рассмотренных собак и живого общения с профессионалами, мнение
                которых представляет для вас ценность. Даже если Вы - поклонник, какой то определенной породы,
                то стоит начать свою карьеру именно с неё, но не стоит отгораживаться от огромного разнообразия
                кинологического мира. Старайтесь узнавать как можно больше и о других породах, ведь именно через
                широту взглядов и можно стать профессионалом высокой пробы, в том числе и в судействе своей породы,
                смотря на неё через призму общих кинологических ценностей. Но главное - всегда будьте самым суровым
                судьей самому себе.<br/><br/>

                &nbsp;&nbsp;&nbsp;Позвольте закончить классикой, так писал А.С. Пушкин:<br/><br/>

                &nbsp;&nbsp;&nbsp;«: Не дорожи любовию народной.<br/>

                &nbsp;&nbsp;&nbsp;Восторженных похвал пройдет минутный шум;<br/>

                &nbsp;&nbsp;&nbsp;Услышишь суд глупца и смех толпы холодной,<br/>

                &nbsp;&nbsp;&nbsp;Но ты останься тверд, спокоен и угрюм.<br/>

                &nbsp;&nbsp;&nbsp;Ты царь: живи один. Дорогою свободной<br/>

                &nbsp;&nbsp;&nbsp;Иди, куда влечет свободный ум,<br/>

                &nbsp;&nbsp;&nbsp;Усовершенствуя плоды любимых дум,<br/>

                &nbsp;&nbsp;&nbsp;НЕ требуя наград, за подвиг благородный.<br/>

                &nbsp;&nbsp;&nbsp;Они в самом тебе. Ты сам свой высший суд;<br/>

                &nbsp;&nbsp;&nbsp;Всех строже оценить умеешь ты свой труд.<br/>

                &nbsp;&nbsp;&nbsp;ТЫ ИМ ДОВОЛЕН ЛИ, взыскательный художник?<br/>

                &nbsp;&nbsp;&nbsp;Доволен?<br/>

                &nbsp;&nbsp;&nbsp;Так пускай толпа его бранит и плюет на алтарь,<br/>

                &nbsp;&nbsp;&nbsp; Где твой огонь горит,<br/>

                &nbsp;&nbsp;&nbsp;И в детской резвости колеблет твой треножник.»<br/><br/>

                &nbsp;&nbsp;&nbsp; Я желаю удачи всем, кто искренне любит ротвейлера и хочет сделать эту породу совершеннее.<br/><br/>

               Ольга Гринь<br/>

                Судья FCI<br/><br/>

                Кермайстер
                </p>

            </div>
        </div>
    );

}
export default InterviewWithOlgaGrin;