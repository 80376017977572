import React from "react";
import style from "../Sale.module.css";
import {NavLink} from "react-router-dom";
import GRIM from "../../../images/dogs/GRIM/GRIM_AVA.JPG";
import Diksy from "../../../images/dogs/Diksy/Diksy_Ava.jpg";
import Puppu1 from "../../../images/dogs/Puppu/GrimAndDiksy/female1.JPG";
import Puppu3 from "../../../images/dogs/Puppu/GrimAndDiksy/female2 .JPG";
import Puppu2 from "../../../images/dogs/Puppu/GrimAndDiksy/female3.JPG";



const PuppyGRIMKDiksy = () => {
    return (
        <div>

            <div className={`${style.blokFont} ${style.fontLink}`}>
                <p align="center">
                    <NavLink to="/GRIM" activeClassName={style.activeLink}>GRIM JUNIOR MONTAGNA</NavLink> &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; &  &nbsp;
                    <NavLink to="/Diksy" activeClassName={style.activeLink}>RUSSEN ROTTENSCHILD Diksy</NavLink>
                </p>
                &nbsp;&nbsp;&nbsp;&nbsp;<img src={GRIM} width="300" height="300" hspace="100"/>
                <img src={Diksy} width="300" height="300"/>
            </div>
            <div>
                <p className={style.text}>Представляем родителей помета в нашем питтомнике !<br/>
                    Дата рождения  22 июня 2022 года. Сободны для бронирования 3 суки.
                </p>
            </div>

            <div>
                <p className={style.text}><ins> Отец помета: </ins>GRIM JUNIOR MONTAGNA импорт Сербия,<br/>
                    Чемпион  России, РКФ, INTER CH CANDIDATE САСIB, RCACIB+CAC Macedonia
                    HD /ED B (тест FCI dr.vet med Sipos M.),
                    JLPP CLEAR BY PARENTS, LONG COAT CLEAR
                </p>
            </div>
            <div>
                <p className={style.text}>Grim импортирован в Россию специально для использования  в нашем питомнике
                    с учетом его высокого происхождения и экстерьера.  GRIM  имеет топовую  родословную, в которой
                    представлены  высококлассные европейские и американские производители.<br/><br/>

                    Отец GRIM – Micael Сorlеrone v Haus leroy   - Ausladsieger  ADRK 2018,  многократный победитель
                    Европейских клубных выставок    сын звездной пары  от межконтинентальной  вязки –<br/><br/>

                    Edge  v. Cabarra Haus ( США ) и Filly v. Hause leroy ( Италия) , отлично зарекомендовавших себя как
                    чемпионы  и  производители.  Сам Michael отдаленно  инбреден  на  IV:V на  немецкого суперпроизводителя,
                    Чемпиона Германии AKINO V.D. LAUTERBRUKE.<br/><br/>

                    Мать GRIM - Tiara od Damnjanovica – Интерчемпионка, KS BiH 2018,  одна из сильнейших производительниц
                    Сербии, инбредна на вдающегося Итальянского производителя<br/><br/>
                    FANTOM  DELLA VAL DI NOTO, через его выдающегося сына,
                    Чемпиона мира VICCO DELLE VAL DI NOTTO.<br/>
                    Первый же помет Tiara был предопределен  стать  звездным:<br/><br/>
                    родные сестры:  GRIM - GIZA  JUNIOR MONTAGNA – Чемпионка Сербии,  Интерчемпионка,
                    GRETA JUNIOR MONTAGNA  Чемпион Сербии  CACIBx3 .
                    GRIM чемпион России, РКФ ,  CACIB, RCACIB + САС  MACEDONIA
                </p>
            </div>
            <p className={style.text}><ins>Мать помета: </ins> RUSSEN ROTTENSCHILD Diksy.</p>
            <div>

            </div>



            <div>
                <h3 align="center" className={style.H3}>Pedigree</h3>
            </div>
            <div className={style.fontTable} align="right">
                <table border="3" width="900" height="640" align="center">
                    <tbody>
                    <tr>
                        <td className="parent" valign="middle" align="center">
                            <p>
                                I. generation</p>
                        </td>
                        <td className="parent" valign="middle" align="center">
                            <p>
                                II. generation</p>
                        </td>
                        <td className="parent" valign="middle" align="center">
                            <p>
                                III.generation</p>
                        </td>
                        <td className="parent" valign="middle" align="center">
                            <p>
                                IV. generation</p>
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="8">
                            <p align="center">
                                RUS CH,
                                GRIM JUNIOR MONTAGNA
                            </p>
                        </td>
                        <td rowSpan="4">
                            <p align="center">
                                MICHAEL CORLEONE V HAUS LEROY</p>
                        </td>
                        <td rowSpan="2">
                            <p align="center">
                                EDGE V CARRABBA HAUS
                            </p>
                        </td>
                        <td height="12">
                            IANS YAGO VOM HAUSE WALKER
                        </td>
                    </tr>
                    <tr>
                        <td height="12">
                            NURIA BUOSO DA DOVARA
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2">
                            <p align="center">
                                FILLY VON  HAUSE LEROY
                            </p>
                        </td>
                        <td height="12">
                            LEROY VAN HAYSHERAH
                        </td>
                    </tr>
                    <tr>
                        <td height="12">
                            BEA OF HAUS MISCEVIC
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="4">
                            <p align="center">
                                Inter CH
                                TIARA OD DAMNJANOVICA
                            </p>
                        </td>
                        <td rowSpan="2">
                            <p align="center">
                                TK'S IVAN THE GREAT</p>
                        </td>
                        <td height="12">
                            VIKKO DELLA VAL DEL NOTE
                        </td>
                    </tr>
                    <tr>
                        <td height="12">
                            TK'S BLACK STAR
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2">
                            <p align="center">
                                DUSTY OD DAMNJANOVICA</p>
                        </td>
                        <td height="12">
                            FANTOM DELLA VAL DEL NOTE
                        </td>
                    </tr>
                    <tr>
                        <td height="12">
                            BASRA GERMAN BLOOD LINE
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="8">
                            <p align="center">
                                RUSSEN ROTTENSCHILD DIKSI </p>
                        </td>
                        <td rowSpan="4">
                            <p align="center">
                                TK S NEW YORKER</p>
                        </td>
                        <td rowSpan="2">
                            <p align="center">
                                DZOMBA V HAUS DRAZIC
                            </p>
                        </td>
                        <td height="12">
                            ARLOS MAJESTIK ROTT
                        </td>
                    </tr>
                    <tr>
                        <td height="12">
                            FELICITA TEREZSKY DVUR
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2">
                            <p align="center">
                                TK S BLACK MERSEDES</p>
                        </td>
                        <td height="12">
                            UZI FLASCH ROUSE
                        </td>
                    </tr>
                    <tr>
                        <td height="12">
                            HONDA BLACK ALLUSION
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="4">
                            <p align="center">
                                RUSSEN ROTTENSCHILD RONDA</p>
                        </td>
                        <td rowSpan="2">
                            <p align="center">
                                CHERNOE BRATSTVO LANCELOT  ч-п
                            </p>
                        </td>
                        <td height="12">
                            JUDA FLASH ROUSE
                        </td>
                    </tr>
                    <tr>
                        <td height="12">
                            CHERNOE BRATSTVO KRALJA
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="2">
                            <p align="center">
                                RUSSEN ROTTENSCHILD NAVA</p>
                        </td>
                        <td height="12">
                            RUSSEN ROTTENSCHILD PRINZ ARON
                        </td>
                    </tr>
                    <tr>
                        <td height="12">
                            PANDORA VOM RAUBERWEG
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div></div>
            <br/>
            <br/>
            // <div>
            //     <img src={Puppu1} width="940" height="1408" hspace="100"/><br/><br/>
            //     <img src={Puppu2} width="800" height="1136"  hspace="100"/><br/><br/>
            //     <img src={Puppu3} width="915" height="1270"  hspace="100"/><br/><br/>
            {/*//     <img src={Puppu5} width="937" height="1130"  hspace="100"/><br/><br/>*/}
            {/*//     <img src={Puppu6} width="940" height="1232"  hspace="100"/><br/><br/>*/}
            {/*//     <img src={Puppu7} width="914" height="1065"  hspace="100"/><br/><br/>*/}
            {/*//     <img src={Puppu8} width="940" height="1408"  hspace="100"/><br/><br/>*/}
            {/*//     <img src={KretaAndGrim_Stay} width="828" height="680"  hspace="100"/>*/}
            {/*    <br/>*/}
            {/*    <br/>*/}
            {/*    <img src={KretaAndGrim} width="864" height="960"  hspace="100"/>*/}
            {/*    <br/>*/}
            {/*    <br/>*/}
            {/*    <img src={KretaAndGrim2} width="838" height="1042"  hspace="100"/>*/}
            </div>

        </div>
    );
}
export default PuppyGRIMKDiksy;