import React from "react";
import style from "./RottweilerChampionshipInBrazil.module.css"

const RottweilerChampionshipInBrazil = () => {
    return(
        <div>
            <div ><h1 align="center">Статьи</h1></div>
            <div ><h3>Чемпионат ротвейлеров в Бразилии «TRIANGULO de OURO 2005» "Золотой треугольник 2005"</h3></div>
            <div ><h3>24-25 september 2005, Belo Horizonte, Minas Gerais State</h3></div>
            <div ><h3>Организатор - ротвейлер клуб Rottminas.<br/>
                Эксперт Ольга Гринь, Россия</h3></div>

            <div className={style.font}>
                <p>&nbsp;&nbsp;&nbsp; Мое первое путешествие в Бразилию запомнилось надолго - в первую очередь, конечно приятными
                    моментами - в высшей степени доброжелательный и приветливый прием, первоклассные ротвейлеры,
                    феерическая атмосфера выставки, но покусанная рука еще почти полгода привносила грустные нотки в
                    мои бразильские воспоминания. И когда приглашение посудить в Бразилии вновь оказалось у меня на
                    письменном столе, не скрою, вихрь не самых приятных мыслей пронесся в моей душе, но как говориться,
                    "чему быть...". Знакомые шутили: "Рукав не забудь с собой прихватить! ". Но мне было не до шуток,
                    предстояла дальняя дорога и судейство на одной из крупнейших специализированных выставках
                    южноамериканского континента, проходившей в штате Минас Жерас.<br/><br/>

                    &nbsp;&nbsp;&nbsp; Итак, 19 часов чистого полета в приятном обществе "Lufthansa", по маршруту Казань- Москва-
                    Франкфурт - Рио де Жанейро - Бело Хоризонте, и я наконец то в Бразилии. Город Бело Хоризонте,
                    который располагается в центральной части страны, в 400 км от Сан Паулу и в 450 км от Рио де
                    Жанейро . В этом мегаполисе проживают около 6 млн. человек.<br/><br/>

                    &nbsp;&nbsp;&nbsp; В порту меня встретил председатель оргкомитета выставки Роджерио Лобао, к счастью,хорошо говорящий
                    по английски, так что вопрос с переводчиком был решен. Кстати, по моим наблюдениям в Бразилии совсем
                    немного людей говорят на иностранных языках, не составляет исключения и молодежь. Роджерио оказался
                    очень общительным и энергичным человеком, который провел для меня множество интересных экскурсий по
                    местности, рассказал про историю и настоящее штата, в то же время мы получили прекрасную возможность
                    поговорить о наших любимых ротвейлерах. Я прибыла в Бразилию в пятницу, надеясь немного отдохнуть
                    перед напряженным уикендом, но члены местной группы «Роттминас», немедленно подхватили меня и
                    окружили такой заботой и вниманием, что мысли о релаксации моментально покинули меня.<br/><br/>

                    &nbsp;&nbsp;&nbsp; В сентябре весна в Бразилии, как и во всем Южном Полушарии только начинается, тем не менее, природа
                    стоит в полном великолепии. Все кругом цветет, да так бурно, что просто проходя по улицам, идешь по
                    настоящему цветочному ковру, образованному из опавших цветов, совершенно немыслимых форм и оттенков.
                    Местность гористая настолько, что езда на машине кажется преодолением «американских горок».
                    В середине дня мы добрались наконец до самой высокой точки города. Когда я вышла из машины на
                    смотроровую площадку, не могу сказать, что увиденное поразило меня – нет это было нечто большее!
                    Настолько, насколько можно было видеть вдаль, простирался огромный город, расположенный в туманной
                    долине, окруженный живописными горами, растворяющимися за горизонтом. Взлянув на город отсюда,
                    можно было действительно понять азвание этого мегаполиса ( Belo Horizonte значит - Прекрасная Даль ).<br/><br/>

                    &nbsp;&nbsp;&nbsp;  Во время праздничного ужина, организованного по случаю моего прибытия, Роджерио объяснил. почему
                    выставка называется «Золотой треугольник». Я предположила, что название, возможно, определяется
                    удобным месторасположением и представляет собой географическую триаду, охватывающую наиболее
                    густонаселенные и активные в отношении разведения ротвейлеров города – Сан Пауло, Рио и Бело Хоризонте.
                    Оказалось, что идея заключается совсем в другом. Треугольник этот образован тремя ежегодными выставками,
                    проводимыми ротвейлер клубом Роттминас, под руководством Роджерио, куда, бывают приглашены лишь
                    избранные эксперты со всего мира, чья кандидатура определяется в начале года при участии главного
                    ротвейлер клуба Бразилии – APRO. Например в этом году я оказалась в «компании» с мэтрами – Фернандо
                    Лукас Мартнес основателем и президентом Ротвейлер Клуба Португалии ( он судил весеннюю часть
                    «Треугольника») и Джозеф Хэдл, ведущий эксперт по ротвейлерам из США ( летняя выставка). Надо ли
                    говорить, что предстоящая работа в столь респектабельном кругу была для меня чрезвычайно приятна.<br/><br/>

                    &nbsp;&nbsp;&nbsp;  В субботу утром стартовал первый день выставки. Ринг расположился на территории частного спортивного
                    клуба, переданного на эти выходные во владение Ротвейлер-клуба «Роттминас». Как и водится в этих
                    теплых краях, ринг был окружен благоухающей тропической растительностью и рослыми пальмами.
                    В субботу мне предстояло отсудить 48 молодых собак до 2х лет. Несмотря на то, что погода была
                    жаркая (+31градус) и влажная, никто из участников не проявил патологической усталости и с большим
                    достоинстом показывали себя в ринге.<br/><br/>

                    &nbsp;&nbsp;&nbsp; Уровень молодых собак показался мне достаточно средним, большинство собак были некрупные,
                    достаточно крепкие, с хорошим костяком по росту. Подавляющее большинство «молодежи» не отличалось
                    качеством голов, мелковатые по корпусу, простоватых форм, недостаточно скуластые, сыроватые.
                    Пигментация пасти и цвет глаз не вызывали вопросов. Корпуса удлиненные, за счет поясницы,
                    много собак с проблемными спинами. Благополучно на мой взгляд обстояли дела с поставом и углами
                    конечностей. Большинство собак были хвостатые, нареканий к ним у меня практически не было.
                    Меня впечатлила физическая форма многих молодых собак. Очевидно, что так качественно раскрыть
                    в ринге движения может только отлично тренированная собака. Несомненно на общем фоне очень
                    выделялись потомки импортированных производителей- Eyck v. Tengen, Enrico, Frisco v. Hirschenbrunnen.
                    По прежнему стабильные результаты показывают потомки Рика, в том числе инбредные на него.
                    Результаты первого дня:<br/><br/>
                    <p align="center"><b>Класс беби, кобели</b></p> <br/><br/>

                    &nbsp;&nbsp;&nbsp;  1. б.п.LORD Zorba von Cani Dracon (Zorba Earl Antonius+Handerstalls Brenda v. Wemba)<br/><br/>
                    <p align="center"><b>Класс щенков, кобели</b></p><br/><br/>

                    &nbsp;&nbsp;&nbsp; 1 б.п.Ramzes v.Hause Ditscher ( Akino v. Lauterbruke + Qenda v. Marianental)<br/><br/>

                    &nbsp;&nbsp;&nbsp; 2 б.п.Wolfsburg Black King von Storm (Black Champion Storm v. Maik + Black Champion Vick v. Uran )<br/><br/>

                    &nbsp;&nbsp;&nbsp; 3 б.п. Volf Leon v. Semble (Leon Arko v. Lexus+Daigoro”s We Golda)<br/><br/>
                    <p align="center"><b> Класс молодых кобели</b></p><br/><br/>

                    &nbsp;&nbsp;&nbsp; 1 о.х. Apollo Schutz der Seen ( Mirko Chris v. Lexus + Daigoro”s WD Donna Gaia)<br/><br/>

                    &nbsp;&nbsp;&nbsp;  2 о.х. Ylco Francelei v. Olivio (Xantos Phanthon v. Olivio +Rebecca Enrico v. Lexus)<br/><br/>

                    &nbsp;&nbsp;&nbsp; 3 о.х. Arco Frisco v. Hause Rodarte ( Frisco v. Hirschenbrunnen +Zigui Eyck v. Lexus)<br/><br/>
                    <p align="center"><b>Класс подростков кобели</b></p><br/><br/>

                    &nbsp;&nbsp;&nbsp; 1 о.х ЛЮК .Jackomo Xantos v. Olivio ( Xanthos Phanthon v. Olivio + Gioska v. Olivio)<br/><br/>

                    &nbsp;&nbsp;&nbsp; 2 о.х. Norris Morris Black Champion ( Morris del Antico Guerriero+ Petra Chris v. Haus Tannenhof)<br/><br/>
                    <p align="center"><b>Промежуточный кобели</b></p><br/><br/>

                    &nbsp;&nbsp;&nbsp;  1отл. Fausto v. Wetterling ( Hasko v. Hause Enzian + Faye Black Thunder)<br/><br/>
                    <p align="center"><b>Класс беби, суки</b></p><br/><br/>

                    &nbsp;&nbsp;&nbsp; 1б.п.Ramona Frisco Black Champion ( Frisco v. Hirschenbrunnen + Zilly v. Blackriesen)<br/><br/>

                    &nbsp;&nbsp;&nbsp; 2б.п.Linda Zorba v. Cani Dracon (Zorba Earl Antonius+Handerstalls Brenda v. Wemba)<br/><br/>

                    &nbsp;&nbsp;&nbsp; 3б.п. Ivi Maximilian v. Olivio ( Maximilian Furst zum Ritter + Rose Enrico v. Lexus)<br/><br/>
                    <p align="center"><b>Класс щенков суки</b></p><br/><br/>

                    &nbsp;&nbsp;&nbsp; 1 б.п.Ruth v.Hause Ditscher ( Akino v. Lauterbruke + Qenda v. Marianental)<br/><br/>

                    &nbsp;&nbsp;&nbsp; 2б.п. Sascha Frisco Black Champion ( Frisco v. Hirschenbrunnen + Petra Chris v. Haus Tannenhof)<br/><br/>

                    &nbsp;&nbsp;&nbsp;  3б.п. Wolfsburg Black Kira von Storm (Black Champion Storm v. Maik + Black Champion Vick v. Uran )<br/><br/>

                    &nbsp;&nbsp;&nbsp; 4б.п.Dag Schonheit v. Rottweil ( Hannegen”s Apollo + Anni Chost v. Carebru)<br/><br/>
                    <p align="center"><b> Класс молодых cуки</b></p><br/><br/>

                    &nbsp;&nbsp;&nbsp; 1о.х. Anna v. Krieger Black Champion ( Eyck v. Tengen + Spirit a.d. Wildnis)<br/><br/>

                    &nbsp;&nbsp;&nbsp; 2 о.х.Enya Enrico v. Wieseberg ( Enrico v. Hirschenbrunnen + Lina v. Brandenburger Tor)<br/><br/>

                    &nbsp;&nbsp;&nbsp;  3о.х.Lana Ghost Carebru ( Ghost Chris zum Ritter + April v. Brombachsee)<br/><br/>

                    &nbsp;&nbsp;&nbsp;4о.х.Issa Pascha v. Cani Dracon ( Pascha a.d. Wildnis + Sydney a.d. Wildnis)<br/><br/>
                    <p align="center"><b>Класс подростков суки</b></p><br/><br/>

                    &nbsp;&nbsp;&nbsp;1о.х. ЛЮС Venus zur Langzunge ( Wolf v. Hause Sommer + Zecke v. Hause Sommer)<br/><br/>

                    &nbsp;&nbsp;&nbsp;2о.х. Nura Morris Black Champion ( Morris del Antico Guerriero+ Petra Chris v. Haus Tannenhof)<br/><br/>

                    &nbsp;&nbsp;&nbsp; 3о.х.Kaiserland Victoria v. Eyck (Eyck v. Tengen + Britta v. Steinbruke)<br/><br/>

                    &nbsp;&nbsp;&nbsp;4о.х. Petra Lion Black Champion (Leon Arco v. Lexus + Lina Rocket Black Champion )<br/><br/>
                    <p align="center"><b> Промежуточный суки</b></p><br/><br/>

                    &nbsp;&nbsp;&nbsp; 1отл. Hillary Clown v. Wemba ( Clown de vereda Castro + Mira Chris v. Lexus)<br/><br/>

                    &nbsp;&nbsp;&nbsp;2 отл.Kaiserland Utta v. Hakan ( Kaiserland Hakan v. Hasko + Sara v. Ariew)<br/><br/>

                    &nbsp;&nbsp;&nbsp; 3 отл.Leonye D”or Aischa Wildnis (Rocket a.d. Wildnis + Fada Rakira v. Wemba)<br/><br/>

                    &nbsp;&nbsp;&nbsp; К воскресенью погода немного изменилась, стало чуть прохладнее, слегка, остудив выставочные страсти.
                    25 сентября выставлялись взрослые собаки, по каталогу 45 участников. Замечу, что в целом уровень
                    сук был выше уровня кобелей. Ринги были довольно разные по качеству . Например, в рабочем классе
                    сук из 16 участниц 6 имели оценки «отлично», 2 «оч. Хор»,, 7 – «хорошо» и 1 «удовлетворительно».
                    Разнотипность собак была выражена очень ярко. Как и в классах до 2х лет стабильные результаты
                    показали потомки вышеназванных производителей. Победителем выставки стала сука из класса Чемпионов
                    Olympia v.Hause Neubrand, харизматичная, зрелая сука, ротвейлер классического стиля. выдержанная
                    в лучших традициях современного европейского ротвейлера. Безупречно сложенная, желаемого маточного
                    типа, уверено показывающаяся как в стойке, так и в движении. Лучшим кобелем стал Zorba Earl Antonius,
                    победитель рабочего класса. не так давно импортированный из Югославии, кобель хорошего типа,
                    мужественный, с крупной четкого рельефа головой, компактным корпусом, но в то же время,
                    характерными для собак югославской селекции проблемами- слабоватые связки задних конечностей,
                    несколько ослабленной пигментацией пасти. Резервными победителями стали молодые собаки
                    Сhef Fanton v. Olivio ( правнук Онекса ф. Швайгер Ваппен и Zecke v. Hause Sommer, очень добротная,
                    крепкая, правильно сложенная по возрасту сука, пока не набравшая достаточных объемов корпуса,
                    тем не менее очень перспективная в дальнейшем.<br/><br/>
                    <p align="center"><b>Кобели класс открытый</b></p><br/><br/>

                    &nbsp;&nbsp;&nbsp;1 отл. Ives v. Schewerein ( Enrico v. Hirschenbrunnen + Minka v. Aero)<br/><br/>

                    &nbsp;&nbsp;&nbsp; 2отл. Kaiserland Thor v. Enrico (Enrico v. Hirschenbrunnen + Berchta von Asgard)<br/><br/>

                    &nbsp;&nbsp;&nbsp;3отл. Gordon Eyck v. Wemba ( Eyck v. Tengen+ Wemba von Marciane)<br/><br/>

                    &nbsp;&nbsp;&nbsp; 4 отл. Ace Black Sheep House (Black Champion Obelix von Conan + Black Champion Steffy von Maik)<br/><br/>
                    <p align="center"><b>Кобели класс рабочий</b></p><br/><br/>

                    &nbsp;&nbsp;&nbsp;1 отл. CAC ЛК Zorba Earl Antonius ( Bronko od Dragicevica + Gresi Crni Lotos)<br/><br/>

                    &nbsp;&nbsp;&nbsp; 2 отл. Frisco v. Hirschenbrunnen ( Amadeus v. Silberpfeil+ Aischa v. Hirschenbrunnen)<br/><br/>
                    <p align="center"><b>Кобели класс чемпионов</b></p><br/><br/>

                    &nbsp;&nbsp;&nbsp;1отл. Рез. САС Сhef Fanton v. Olivio ( Fanton Onex v. Olivio+ Qendy Armin zum Ritter)<br/><br/>

                    &nbsp;&nbsp;&nbsp;2отл.Morro Chris von Lexus ( Chris v.d. Bleistrasse+ Lina v. Brandenburger Tor)<br/><br/>

                    &nbsp;&nbsp;&nbsp;3отл.Ra von Grob Schadel (Athos de Pasco + Nirvana v. Stier)<br/><br/>

                    &nbsp;&nbsp;&nbsp; 4отл.Hasko v. Schewerein ( Maik von Heidelberger Wappen +Minka v. Aero)<br/><br/>
                    <p align="center"><b>Суки класс открытый</b></p><br/><br/>

                    &nbsp;&nbsp;&nbsp;1отл.Wendy v. Langzunge ( Wolf v. Hause Sommer + Java v. Breogan)<br/><br/>

                    &nbsp;&nbsp;&nbsp;2отл.Goldie v. Schwarzen Eiche ( Eyck v. Tengen + Aika v. Вergstrasse)<br/><br/>
                    <p align="center"><b>Суки класс рабочий</b></p><br/><br/>

                    &nbsp;&nbsp;&nbsp; 1.отл. Sahra aus der Wildnis ( Rick v. Burgthann + Yanka v.d. Silberdiestel)<br/><br/>

                    &nbsp;&nbsp;&nbsp;2.отл .Jacky Fanton v. Olivio ( Phanthon Onex v. Olivio + Jenny v. Blackriesen)<br/><br/>

                    &nbsp;&nbsp;&nbsp;3. отл.Anta v. Hundehutte ( Arnold v. Machwind + Zamba Mar Ub )<br/><br/>

                    &nbsp;&nbsp;&nbsp; 4. отл.Layla v. Germanisch (Enrico v. Hirschenbrunnen+ Heather v. Germanisch)<br/><br/>
                    <p align="center"><b> Суки класс чемпионов</b></p><br/><br/>

                    &nbsp;&nbsp;&nbsp;1 отл. Diagoro”we Golda (Enrico v. Hirschenbrunnen+Diagoro’s SB Wonka)<br/><br/>

                    &nbsp;&nbsp;&nbsp;2 отл.Paloma Pancho v. Olivio( Pancho Chan v. Olivio + Victoria v.d. Teufelsbruke)<br/><br/>

                    &nbsp;&nbsp;&nbsp;3 отл. Miss Chacal to keep Home ( Chacal Ingo Crioulus de Minas + Githa da Casa dos Contos)<br/><br/>
                    <p align="center"><b>Суки класс грандчемпионов</b></p><br/><br/>

                    &nbsp;&nbsp;&nbsp;1 отл. Рез САС Zecke v. Hause Sommer ( Odo v. Fleischer + Lucy v. Hause Sommer)<br/><br/>

                    &nbsp;&nbsp;&nbsp;2отл. April Morada dos Marques (Enrico v. Hirschenbrunnen+ Emily Black Thunder)<br/><br/>

                    &nbsp;&nbsp;&nbsp; 3 отл. Ayunni Scaliburth (Hundertalls Doddi v. Rott Sieger + Akira Hunter Dah Let)<br/><br/>

                    &nbsp;&nbsp;&nbsp;4отл. Pandora Chan v. Olivio (Chan v.d. Bleistrasse + Olympia v. Hause Neubrand)<br/><br/>
                    <p align="center"><b>Суки класс победителей</b></p><br/><br/>

                    &nbsp;&nbsp;&nbsp;1отл САС, ЛС, ЛПП Olympia v. Hause Neubrand ( Amadeus v. Silberpfeil + Cora v. Rosslesgarten)<br/><br/>

                    &nbsp;&nbsp;&nbsp;2отл. April v. Brombachsee ( King v. Schwаiger Wappen + Imme v. Brandenburger Tor)<br/><br/>

                    &nbsp;&nbsp;&nbsp; Резюмируя результаты, отмечу, что выставка была организована на очень высоком уровне.
                    Безукоризненная работа ринговой бригады, грамотный переводчик, превосходно оборудованный ринг.
                    Обширная география участников для локальной выставки – здесь присутствовали ротвейлеристы не
                    только из близлежащих городов, но даже из весьма отдаленных штатов, в частности Мато Гроссо,
                    владелец с 7 собаками в машине проехал 2 тысячи км, чтобы показать своих собак широкой публике.<br/><br/>

                    &nbsp;&nbsp;&nbsp;Как всегда после выставки состоялся праздничный вечер, где состоялся семинар, с множеством
                    вопросов посвященный результатам мероприятия, и проблемам разведения и хэндлинга, а позже было
                    сказано немало теплых слов, посвященных ротвейлеру.<br/><br/>

                    &nbsp;&nbsp;&nbsp; Неделя, проведенная в Бело- Хоризонте после выставки позволила мне приблизиться и лучше понять
                    реалии бразильского бытия. По моей просьбе, Роджерио постарался показать мне самые разные сферы
                    местной жизни. Окруженная бурным тропическим цветением весенняя Бразилия предстала передо мной в
                    совершенно разных лицах.<br/><br/>

                    &nbsp;&nbsp;&nbsp;Здесь были и роскошный центр города с колоритным соседством небоскребов и респектабельных зданий,
                    построенных в прошлом веке, и как гримаса совершенно другой жизни – «фавелы»- нищие кварталы,
                    расположенные в пригородах, населенные озлобленными, готовыми на все людьми, даже полиция старается
                    обходить эти места стороной. На фоне таких жестких социальных контрастов природа как всегда –
                    посередине. Она вне политики и вне мирских страстей. Всего в 20 км езды от Бело Хоризонте можно
                    побывать в настоящих джунглях, насладиться видом совершенно фантастического озера, на берегу
                    которого вольготно чувствуют себя капибары. А с деревьев на тебя с любопытством поглядывают птицы
                    диковинных форм и расцветок…<br/><br/>

                    &nbsp;&nbsp;&nbsp;Мои очередные «бразильские каникулы» подошли к завершению, возвращаться к обычной жизни, как всегда
                    не очень то хотелось, тем более, что перебираться из восторженной тропической весны в приземленную
                    европейскую осень всегда немного печально.<br/><br/>

                    &nbsp;&nbsp;&nbsp; Но странное дело – как только грандиозный лайнер взмыл в небо, а за окошком растворились последние
                    черты южноамериканской твердыни, легкая грусть уступила место прекрасным воспоминаниям о прошедших
                    днях, о новых знакомствах, о том, что несмотря на расстояния и границы ротвейлер по прежнему
                    объединяет и сближает всех, кто искренне любит эту замечательную породу. </p>
            </div></div>
    );

}
export default RottweilerChampionshipInBrazil;