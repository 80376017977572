import React from "react";
import Grin from "../../images/O_Grin.jpg"
import Sergey from "../../images/Sergey.jpg"
import style from "./About.module.css";
import SherifrotthausHoward from "../../images/Sherifrotthaus_Howard.JPG";

const About = () =>{
    return(
        <div>
            <div className={style.H3}><h3 align="center">О Нас</h3></div>
        <div><img src={Grin} className={style.Img}/></div>
            <div className={style.H5}><h3 align="center"> Основатель и совладелец пиитомника: Ольга Анатольевна Гринь </h3></div>
        <div className={style.font}>
            <p > Кандидат биологических наук,  закончила  Академию  ветеринарной медицины  им. Н.Э. Баумана г. Казани,   ветеринарный врач, специалист по особенностям строения и патологии опорно -двигательного аппарата собак.
                С 1986 г. я имею лицензию судьи,  в настоящее время судья  FCI (Международной Кинологической Федерации) по всем породам собак (allround), и Кермайстер (специалист по элитному отбору собак).
                Председатель  Кинологической Федерации Республики Татарстан, Ротвейлер Клуба РТ,  одна из основателей Национального Ротвейлер Клуба России.
                Проводила экспертизу собак  в качестве главного судьи  на крупных  международных и национальных выставках  в следующих странах:

                Германии,  России, Италии, Бельгии, Швеции, США (Северная Каролина, Калифорния), Австралии ( Новый Южный Уэльс и Виктория), Новой Зеландии,  Бразилии (Рио де Жанейро, Сан Паоло, Бело -Гризонте, Ресифе),  Филиппин,  Индонезии, Малайзии,  Эстонии, Литвы,  в большинстве стран СНГ .
                Я  являюсь  одним из учредителей российского журнала "Ротвейлер" и членом его редколлегии. Автор ряда статей по вопросам развития породы ротвейлер.

                Я  очень благодрна всем моим друзьям  за возможность разделить мою  страсть к нашей любимой породе  и огромному  миру собаководства.</p>
        </div>
            <div><img src={Sergey} className={style.Img}/></div>
            <div className={style.H5}><h3 align="center"> Совладелец питомника:  Лаптев Сергей Николаевич</h3></div>
            <div className={style.font}>
                <p > Инструктор -кинолог, судья-эксперт по породе ротвейлер, профессиональный хэндлер, образование высшее педагогическое, закончил Волгоградский Педагогический институт,
                    Кандидат в мастера спорта по академической гребле.
                    Сергей уделяет большое внимание физической тренировке наших собак, их подготовке и показу на выставках.</p>
            </div>

        </div>
    );
}
export default About;