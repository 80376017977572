import React from "react";
import style from "./Puppies.module.css"
import {NavLink} from "react-router-dom";
import SherifrotthausHoward from "../../images/Sherifrotthaus_Howard.JPG";
import RussenRottenschildMarylin from "../../images/RussenRottenschildMarylin.jpg";
import dorhan from "../../images/russen_rottenschild_dorhan.png";
import YAVA from "../../images/RUSSEN_ROTTENSCHILD_YAVA.png";
import ZHAK from "../../images/dogs/ZHAK.JPG";
import RR_KONCORDIA from "../../images/dogs/RR_KONCORDIA.JPG";
import RR_DIAMANTA from "../../images/dogs/RR_DIAMANTA.JPG"
import DOMINATOR from "../../images/dogs/DOMINATOR.JPG";
import GRIM_AVA from "../../images/dogs/GRIM/GRIM_AVA.JPG";
import KRETA from "../../images/dogs/KRETA.JPG";
import Rusana from "../../images/dogs/RUSRANA/russana_head.png";
import DiksyAva from "../../images/dogs/Diksy/Diksy_Ava.jpg";
import DexterHead from "../../images/dogs/Dexter/Dexter_head.JPG";
import TEYA from "../../images/dogs/RUSSEN ROTTENSCHILD TEYA/head.jpg";

const Puppies = () => {
    return(
        <div>

            <div className={`${style.blokName} ${style.blokNameFont}`}>
                <p align="center" color="white">DEXTER V.D. WEISSENSTADT & RUSSEN ROTTENSCHILD TEYA<br/>
                    д.р. 16.03.2023</p>

            </div>
            <div className={`${style.blokFont} ${style.fontLink}`}>
                <p className={style.NEWS} align="center">Все щенки проданы!</p>
                <div><p align="center">16.03.2023 Щенки от</p></div>
                <p align="center">

                    <NavLink to="/Dexter" activeClassName={style.activeLink}>DEXTER V.D. WEISSENSTADT</NavLink>
                    &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&
                    <NavLink to="/Teya" activeClassName={style.activeLink}>RUSSEN ROTTENSCHILD TEYA</NavLink></p>

                &nbsp; &nbsp; &nbsp; &nbsp; <img src={DexterHead} width="300" height="300" hspace="100"/>
                &nbsp; &nbsp;<img src={TEYA} width="300" height="300"/>

            </div>


            <div className={`${style.blokName} ${style.blokNameFont}`}>
                <p align="center" color="white">GRIM JUNIOR MONTAGNA & RRUSSEN ROTTENSCHILD DIKSY<br/>
                    д.р. 22.06.2022</p>

            </div>
            <div className={`${style.blokFont} ${style.fontLink}`}>
                <p className={style.NEWS} align="center">Все щенки проданы!</p>
                <div><p align="center">22.06.2022 Щенки от</p></div>
                <p align="center">

                    <NavLink to="/GRIM" activeClassName={style.activeLink}>GRIM JUNIOR MONTAGNA</NavLink>
                    &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&
                    <NavLink to="/Diksy" activeClassName={style.activeLink}>RUSSEN ROTTENSCHILD DIKSY</NavLink></p>

                &nbsp; &nbsp; &nbsp; &nbsp; <img src={GRIM_AVA} width="300" height="300" hspace="100"/>
                &nbsp; &nbsp;<img src={DiksyAva} width="300" height="300"/>

            </div>


            <div className={`${style.blokName} ${style.blokNameFont}`}>
                <p align="center" color="white">GRIM JUNIOR MONTAGNA & RUSSEN ROTTENSCHILD RUSTANA<br/>
                    д.р. 30.07.2022</p>

            </div>
            <div className={`${style.blokFont} ${style.fontLink}`}>
                <p className={style.NEWS} align="center">Все щенки проданы!</p>
                <div><p align="center">30.07.2022 Щенки от</p></div>
                <p align="center">

                    <NavLink to="/GRIM" activeClassName={style.activeLink}>GRIM JUNIOR MONTAGNA</NavLink>
                    &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&
                    <NavLink to="/RUSTANA" activeClassName={style.activeLink}>RUSSEN ROTTENSCHILD RUSTANA</NavLink></p>

                &nbsp; &nbsp; &nbsp; &nbsp; <img src={GRIM_AVA} width="300" height="300" hspace="100"/>
                &nbsp; &nbsp;<img src={Rusana} width="300" height="300"/>

            </div>






















            <div className={`${style.blokName} ${style.blokNameFont}`}>
                <p align="center" color="white">GRIM JUNIOR MONTAGNA & RUSSEN ROTTENSCHILD YAVA<br/>
                    д.р. 05.05.2022</p>

            </div>
            <div className={`${style.blokFont} ${style.fontLink}`}>
                <p className={style.NEWS} align="center">Все щенки проданы!</p>
                <div><p align="center">05.05.2022 Щенки от</p></div>
                <p align="center">

                    <NavLink to="/Grim" activeClassName={style.activeLink}>GRIM JUNIOR MONTAGNA</NavLink>&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&
                    <NavLink to="/Yava" activeClassName={style.activeLink}>RUSSEN ROTTENSCHILD YAVA</NavLink></p>

                &nbsp; &nbsp; &nbsp; &nbsp; <img src={GRIM_AVA} width="300" height="300" hspace="100"/>
                &nbsp; &nbsp;<img src={YAVA} width="300" height="300"/>

            </div>




            <div className={`${style.blokName} ${style.blokNameFont}`}>
                <p align="center" color="white">GRIM JUNIOR MONTAGNA & RUSSEN ROTTENSCHILD KRETTA<br/>
                    д.р. 08.03.2022</p>

            </div>
            <div className={`${style.blokFont} ${style.fontLink}`}>
                <p className={style.NEWS} align="center">Все щенки проданы!</p>
                <div><p align="center">08.03.2022 Щенки от</p></div>
                <p align="center">

                    <NavLink to="/Grim" activeClassName={style.activeLink}>GRIM JUNIOR MONTAGNA</NavLink> &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp; &
                    <NavLink to="/KRETA" activeClassName={style.activeLink}>RUSSEN ROTTENSCHILD KRETTA</NavLink></p>

                &nbsp; &nbsp; &nbsp; &nbsp; <img src={GRIM_AVA} width="300" height="300" hspace="100"/>
                &nbsp; &nbsp;<img src={KRETA} width="300" height="300"/>

            </div>

            <div className={`${style.blokName} ${style.blokNameFont}`}>
                <p align="center" color="white">ZHAK V HAUS BELOGORIE & RUSSEN ROTTENSCHILD  KONCORDIA<br/>
                    д.р. 02.05.2021</p>

            </div>
            <div className={`${style.blokFont} ${style.fontLink}`}>
                <p className={style.NEWS} align="center">Все щенки проданы!</p>
                <div><p align="center">02.05.2021 Щенки от</p></div>
                <p align="center">

                    <NavLink to="/ZHAK" activeClassName={style.activeLink}>ZHAK V HAUS BELOGORIE</NavLink> &
                    <NavLink to="/KONCORDIA" activeClassName={style.activeLink}>RUSSEN ROTTENSCHILD  KONCORDIA</NavLink></p>

                &nbsp; &nbsp; &nbsp; &nbsp; <img src={ZHAK} width="300" height="300" hspace="100"/>
                &nbsp; &nbsp;<img src={RR_KONCORDIA} width="300" height="300"/>

            </div>

            <div className={`${style.blokName} ${style.blokNameFont}`}>
                <p align="center" color="white">RUSSEN ROTTENSCHILD DOMINATOR & RUSSEN ROTTENSCHILD DIAMANTA<br/>
                    д.р. 23.02.2021</p>

            </div>
            <div className={`${style.blokFont} ${style.fontLink}`}>
                <p className={style.NEWS} align="center">Все щенки проданы!</p>
                <div><p align="center">23.02.2021 Щенки от</p></div>
                <p align="center">

                    <NavLink to="/DOMINATOR" activeClassName={style.activeLink}>RUSSEN ROTTENSCHILD DOMINATOR</NavLink> &
                    <NavLink to="/DIAMANTA" activeClassName={style.activeLink}>RUSSEN ROTTENSCHILD DIAMANTA</NavLink></p>

                &nbsp; &nbsp; &nbsp; &nbsp; <img src={DOMINATOR} width="300" height="300" hspace="100"/>
                &nbsp; &nbsp;<img src={RR_DIAMANTA} width="300" height="300"/>

            </div>

            <div className={`${style.blokName} ${style.blokNameFont}`}>
                <p align="center" color="white">RUSSEN ROTTENSCHILD DORHAN & RUSSEN ROTTENSCHILD YAVA<br/>
                    д.р. 22.07.2020</p>

            </div>
            <div className={`${style.blokFont} ${style.fontLink}`}>
                <p className={style.NEWS} align="center">Все щенки проданы!</p>
                <div><p align="center">22.07.2020 Щенки от</p></div>
                <p align="center">


                        <NavLink to="/Dorhan" activeClassName={style.activeLink}>RUSSEN ROTTENSCHILD DORHAN</NavLink> &
                        <NavLink to="/Yava" activeClassName={style.activeLink}> RUSSEN ROTTENSCHILD YAVA</NavLink></p>

                     &nbsp; &nbsp; &nbsp; &nbsp; <img src={dorhan} width="300" height="300" hspace="100"/>
                     &nbsp; &nbsp;<img src={YAVA} width="300" height="300"/>

            </div>


            <div className={`${style.blokName} ${style.blokNameFont}`}>
                <p align="center" color="white">RUSSEN ROTTENSCHILD HIGHLANDER & RUSSEN ROTTENSCHILD ASTARTA <br/>
                    д.р. 28.02.2020</p>

            </div>
            <div className={`${style.blokFont} ${style.fontLink}`}>
                <p className={style.NEWS} align="center">Все щенки проданы!</p>
                <div><p align="center">28.02.2020 Щенки от</p></div>
                <p align="center">

                <NavLink to="/Highlander" activeClassName={style.activeLink}>RUSSEN ROTTENSCHILD HIGHLANDER</NavLink> &
                <NavLink to="/Astarta" activeClassName={style.activeLink}> RUSSEN ROTTENSCHILD ASTARTA</NavLink></p>

                <img src={SherifrotthausHoward} width="300" height="300" hspace="100"/>
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<img src={RussenRottenschildMarylin} width="300" height="300"/>

            </div>

        </div>
    );
}
export default Puppies;