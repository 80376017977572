import React from "react";
import style from "./RottweilerEducation.module.css"


const RottweilerEducation = () => {
    return(
        <div>
            <div ><h1 className={style.h1}>Статьи</h1></div>
            <div ><h1 >Воспитание ротвейлером</h1></div>
            <div  align="right">
                «Сначала поговорите о своих ошибках,<br/>
                а потом уже критикуйте другого».<br/>
                Д. Карнеги
            </div>
            <div className={style.font}>
                <p align="justify">
                    &nbsp;&nbsp;&nbsp;Итак, решение приобрести собаку принято и из более 400 видов всевозможных пород Ваш
                    выбор остановился
                    именно на ротвейлере. Превосходно! Выбор отличный, но вместе с новым членом семьи Ваша жизнь получит
                    совершенно иную окраску и впереди Вам предстоит путь выращивания и воспитания Вашего питомца.<br/><br/>

                    &nbsp;&nbsp;&nbsp;О том, как вырастить хорошего ротвейлера, написано немало: вопросы кормления и содержания щенков
                    освещены многогранно, большим количеством изданий. Но, как ни странно, в большинстве из них
                    отсутствуют доступные и понятные всем правила, четко регламентирующие нормы отношений человека и
                    ротвейлера. Когда поступило предложение изложить свои взгляды на эту тему, я, не скрою, очень
                    заинтересовалась такой возможностью. Во-первых, потому, что как уже было сказано выше, информации
                    на эту тему явно недостаточно, а во-вторых, общаясь с владельцами собак этой породы на ринге, я
                    очень часто бываю свидетелем довольно печальных примеров отсутствия или совершенно неправильного
                    воспитания этих замечательных существ. Мне так многим хотелось бы поделиться с владельцами этих
                    «неудачных» собак, но чаще всего на выставках просто не хватает времени, а слова, сказанные в
                    атмосфере выставочной спешки и суеты, не всегда остаются в сознании. Надеюсь, что мой опыт, станет
                    полезным для тех, кто решил серьезно отнестись к делу воспитания щенка-ротвейлера, а возможно,
                    и окажет реальную помощь владельцам, у которых уже есть пес, но что-то не заладилось в отношениях
                    с ним. Моя теория проста - в ней всего шесть правил, но все они -неделимая часть того, что
                    называется правильным воспитанием. Нельзя соблюсти, к примеру, некоторые из них, а другими
                    пренебречь. Прошу отнестись к этому замечанию ответственно. Итак, правила этой «игры» - просты,
                    стоит только попробовать!<br/><br/>

                    &nbsp;&nbsp;&nbsp; Продолжим цитатой: «Быть владельцем ротвейлера может всякий, кто сможет найти ему какое-либо
                    применение, либо, по крайней мере, займется его обучением. Он не высок, не выглядит величественным,
                    утонченным или элегантным. Он является статным молодцом, способным выполнить любую порядочную
                    работу. В прошлом путь ротвейлера нигде не был устлан розами. Поэтому он скромен, невзыскателен
                    и всегда привержен служить своему хозяину. Кто сумеет оценить его качества, его характер и золотой
                    чеканки сердце, увлечется его могучим, мускулистым телосложением, рассчитывая на дружбу с собакой,
                    тот сделает только добро, признав в ротвейлере своего друга. На любовь, подаренную хозяином, собака
                    заплатит тысячекратно своим усердием, преданностью и привязанностью». Эти замечательные слова были
                    написаны много лет назад немецкой графиней Агнес фом Хаген, большой поклонницей этой породы. Я бы
                    назвала эту цитату «Кратким моральным кодексом» для владельцев ротвейлера. Суть ее такова:
                    ротвейлер -прекрасная собака, но он требует к себе внимательного и последовательного отношения,
                    и только в этом случае он станет вашим другом.<br/><br/>

                    &nbsp;&nbsp;&nbsp;После этого довольно долгого, но, надеюсь, также полезного читателю вступления, настало время
                    перейти непосредственно к нашей теме.<br/><br/>

                    &nbsp;&nbsp;&nbsp;В общем смысле правило ¹1 гласит: «Ваше общение с собакой начинается еще до того, как она у вас
                    появилась».<br/><br/>

                    &nbsp;&nbsp;&nbsp;Звучит несколько противоречиво,но, тем не менее, это так! Советую никогда не покупать собаку
                    (и в особенности ротвейлера) спонтанно. Ваше решение должно быть взвешено, четко продумано и
                    обсуждено на семейном совете. Перед приобретением щенка обязательно почитайте соответствующую
                    литературу, посетите выставку собак и познакомьтесь там с «бывалыми» владельцами взрослых, уже
                    «состоявшихся собак». Никогда не покупайте щенка по объявлению в газете или на рынке! Это ведь не
                    подержанный автомобиль или мешок овощей. Будет просто замечательно, если вы станете членом
                    породного клуба или примкнете к известному питомнику, занимающемуся разведением ротвейлеров.
                    Там вам дадут реальные гарантии качества и здоровья будущего щенка. При покупке собаки отдавайте
                    себе отчет, что ваш будущий член семьи - это своего рода «ТаЬu!а Rаsа» - чистый лист, на который
                    вы, как владелец, нанесете все, что захотите. Если быть более точным, то, безусловно, наследственные
                    черты характера играют не последнюю роль, но в ваших силах оказать необходимое воздействие
                    и изменить негативные черты в лучшую сторону.<br/><br/>

                    &nbsp;&nbsp;&nbsp;Итак: приобретая щенка постарайтесь побольше узнать о нем самом, о его родителях и о породе в целом
                    из компетентных источников.<br/><br/>

                    &nbsp;&nbsp;&nbsp;Вот щенок ротвейлера появился у вас в доме. Правило ¹2: «Никогда не позволяйте делать маленькому
                    щенку того, чего вы бы не хотели ожидать от взрослой собаки».<br/><br/>

                    &nbsp;&nbsp;&nbsp;Пагубные привычки: хватание хозяев за руки, беспричинное порыкивание, бесцеремонное отношение к
                    пище - могут перерасти со временем в очень большую проблему. Сразу замечу: бить ротвейлера не стоит,
                    это ни приведет ни к чему хорошему! Психика щенка необратимо изменится, и воспитание сразу пойдет
                    по ложному пути. Наказание (а точнее - прекращение нежелательных действий), если оно необходимо,
                    лучше всего преподнести щенку в виде легкой встряски. Взяв рукой за холку чуть приподнимите
                    строптивца и несильно встряхните его. Свои действия обязательно сопровождайте командой «Фу»,
                    поданной в угрожающей интонации. Кому-то из щенков будет достаточно одной лишь ко манды, а с
                    кем-то придется повоевать, несколько раз встряхнув его.<br/><br/>

                    &nbsp;&nbsp;&nbsp;Но имейте в виду: воспитание не должно быть однобоким - только ласка и похвала или, наоборот,
                    одни «встряски». Прежде всего, оно должно быть справедливым и правильно мотивированным. Несколько
                    слов о похвале. Имейте в виду, что собака не понимает значение слов, решающую роль здесь играет
                    интонация. Для моих собак, к примеру, это радостное «Брав!» (от немецкого «Duist Brav!» -
                    «ты молодец»; кто-то успешно восклицает традиционные «Хорошо!», «Молодец!», «Умница!». Не важно
                    какое слово вы выберите, но оно должно быть произнесено вовремя в очень оптимистической интонации
                    и, желательно, сопровождаться поглаживанием или ласковым похлопыванием вашего воспитанника по спине
                    или бокам.<br/><br/>

                    &nbsp;&nbsp;&nbsp;Не забудьте про лакомство. Ротвейлер -большой любитель покушать и, поэтому, с самых первых дней
                    при себе вы должны иметь маленькие кусочки чего-то вкусного. Это могут быть тонко нарезанный сыр,
                    колбаса или мясо, сухой корм и пр. Поощрите щенка кусочком каждый раз, когда он был молодцом, не
                    скупитесь на поглаживание и волшебные слова похвалы.<br/><br/>

                    &nbsp;&nbsp;&nbsp;Итак: Начальное воспитание щенка и взрослого ротвейлера базируется на «трех китах» дрессировки:
                    ваше разумное, уверенное и справедливое отношение к животному + своевременное поощрение (лакомством,
                    словом, поглаживанием) + мотивированное наказание (встряской, угрожающей интонацией).<br/><br/>

                    &nbsp;&nbsp;&nbsp; Правило ¹3: «Правильное отношение вашего ротвейлера к пище».<br/><br/>

                    &nbsp;&nbsp;&nbsp;О качестве кормления сказано немало. Кто-то выбирает для своего питомца готовые корма, производимые
                    различными фирмами, кто-то кормит пищей собственного приготовления. Но, в любом случае,
                    придерживайтесь одного правила: вне зависимости от количества кормлений, пища должна быть съедена,
                    а емкость, из которой ест собака, убрана. Никогда не оставляйте недоеденный корм и не позволяйте
                    своей собаке «копаться» в предлагаемой еде. Предложенная ей пища должна быть быстро съедена.
                    Если собака начинает разборчиво относится к еде - уменьшите порцию или пропустите кормление.
                    Разумеется, здесь вы должны обратить внимание на общее состояние щенка. Если раньше он ел с
                    удовольствием, а теперь уныло подходит к миске и выбирает кусочки, проверьте - не болен ли он.
                    Но в любом случае - никогда не предлагайте другой корм. Если собака просто не голодна, не стоит
                    пытаться накормить ее сверх меры, а если больна - отказ от пищи это первейшая защитная реакция
                    организма на плохое самочувствие и ни одна заболевшая собака есть не будет, настаивать тем более не
                    стоит. А если щенок угрюм и вял при этом - лучше обратитесь к ветеринару. В промежутках между
                    кормлениями в миске должна быть только вода.<br/><br/>

                    &nbsp;&nbsp;&nbsp;Итак: Формируйте у щенка правильное отношение к пище - корм должен приниматься собакой всегда с
                    большим интересом и желанием. В промежутках между кормлениями собаке можно давать лишь крошечные
                    кусочки лакомства в качестве поощрения.<br/><br/>

                    &nbsp;&nbsp;&nbsp;Важнейшее место в формировании характера ротвейлера играет правильное начальное воспитание. Щенка не
                    стоит доверять тем, кто утверждает, что воспитание собаки нужно начинать ближе к году. К основному
                    воспитанию щенка надо подготовить.<br/><br/>

                    &nbsp;&nbsp;&nbsp;Итак, правило ¹4. «Хорошие манеры» следует прививать щенку с того самого момента, когда он попал в
                    ваш дом. И если вы заинтересованы получить в дальнейшем послушную и управляемую во всех отношениях
                    собаку, привить эти манеры должны только вы, как владелец.<br/><br/>

                    &nbsp;&nbsp;&nbsp;Список команд, который будет в дальнейшем изучен вашим питомцем довольно обширен, так же различны и
                    системы дрессировок, следуя которым вы создадите желательный тип психики вашего ротвейлера. Но при
                    любых будущих обстоятельствах щенок к 7-8 месяцам должен твердо знать следующее:<br/><br/>

                    &nbsp;&nbsp;&nbsp;1.     На свою кличку он должен откликнуться внимательным взглядом в сторону владельца
                    (замечу особенно! Не подойти к хозяину, а только обратить внимание, как бы ответить: «Да, я слышу,
                    что меня зовут, я весь во внимании, жду дальнейших указаний».<br/><br/>

                    &nbsp;&nbsp;&nbsp;2.  По команде «Ко мне» питомец должен подбежать к вам в любом случае, вне зависимости от того,
                    чем он был занят в то время, когда услышал эту команду.<br/><br/>

                    &nbsp;&nbsp;&nbsp; 3.  По команде «Лежать, место» щенок должен послушно лечь из любого положения и ждать ваших
                    дальнейших действий.<br/><br/>

                    &nbsp;&nbsp;&nbsp;4.     Простая на первый взгляд, но так жизненно необходимая команда «Зубы», услышав которую ваш
                    щенок позволит открыть себе пасть, осмотреть передние зубы (резцы) в сомкнутом состоянии (прикус),
                    а также боковые зубы (премоляры и моляры), находящиеся в глубине рта. Для осмотра последних нужно
                    приоткрыть пасть и немного оттянуть в сторону уголок рта.<br/><br/>

                    &nbsp;&nbsp;&nbsp; 5.     Ну и, наконец, команда «Фу», направленная на прекращение щенком любых нежелательных действий.
                    В ответ на нее животное должно безукоризненно прекратить любые действия.<br/><br/>

                    &nbsp;&nbsp;&nbsp; Эти пять команд жизненно необходимы тому, кто хочет получить в будущем надежную собаку, способную
                    спокойно сосуществовать в семье и обществе. Безусловно, что есть владельцы, которые практикуют
                    раннее обучение щенков командам «След», «Рядом», «Апорт». Но это, скорее всего, -хорошее исключение
                    и применяется, в основном, профессионалами, готовящими собак к дальнейшей спортивной дрессировке.
                    Для владельца, который хочет иметь просто послушную собаку вышеперечисленных команд будет вполне
                    достаточно для того возраста, когда начнется серьезная дрессировка.<br/><br/>

                    &nbsp;&nbsp;&nbsp;Немного о деталях обучения пяти основным командам. Приучение к кличке - простое и приятное дело.
                    Следует лишь подойти к щенку и дать ему вкусный кусочек каждый раз, когда вы повторяете кличку.
                    Подчеркиваю, не подозвать кличкой, а подойти и дать лакомство. Через 20-30 таких поощрений щенок
                    будет неизбежно искать вас взглядом каждый раз, когда услышит свое имя.<br/><br/>

                    &nbsp;&nbsp;&nbsp; Не менее приятным представляется мне обучение команде «Ко мне». Секрет опять же лишь в вовремя
                    данном кусочке лакомства. Если начать занятия с совсем маленьким щенком (60-80 дней) и следовать
                    правилу: подход собаки + неизменная похвала и лакомство, повторение команды «Ко мне», то через
                    неделю - две ежедневных занятий щенок будет не только спешить к вам, получив команду, но и
                    непрерывно следить за вами краем глаза в ожидании вкусного и приятного «Ко мне»!<br/><br/>

                    &nbsp;&nbsp;&nbsp;Команда «Лежать, место» является по сути не самым приятным моментом в жизни щенка, но при
                    правильном подходе эту обязанность можно легко превратить в интересную игру, сходную с нашей
                    человеческой «Замри»! Начать можно, например, используя время кормления или прогулок. Щенок
                    подбегает к миске и в это время вы мягко берете его за ошейник и укладываете перед миской с едой,
                    сначала удерживая руками. Трех-пяти секунд в самом начале обучения будет вполне достаточно, в
                    последствии стоит развивать выдержку и далее до 1 мин. Взрослая собака должна уметь показывать
                    выдержку до 30 мин. Так же легко можно проделать этот прием и со щенком, спешащим на прогулку, -
                    укладка перед открытой дверью, те же первоначальные секунды, нарастающие с каждым днем до 1-2 мин.
                    И в том и в другом случае по окончании выдержки - приятная награда (в первом случае - вкуснейшая
                    еда, во втором - увлекательная прогулка с играми и новыми впечатлениями). Кстати, на прогулке тоже
                    можно практиковаться «в выдержке»: уложив щенка командой и удерживать его некоторое время, потом
                    отпустить веселой командой и поощрив всеми возможными способами. Будьте осторожны! Не увлекайтесь
                    сразу длительной выдержкой, все нарабатывается очень постепенно.<br/><br/>

                    &nbsp;&nbsp;&nbsp;Со способностью показывать зубы (как впрочем и со многими другими) не родилась еще на свет ни одна
                    собака. Этому нужно учить, причем, чем раньше, тем лучше, потому что явно проще научить открывать
                    пасть кроткого щенка, чем пыхтящего от негодования крупного и самоуверенного взрослого ротвейлера.
                    Знать эту команду необходимо по двум основным причинам:<br/><br/>

                    &nbsp;&nbsp;&nbsp;1.     Спокойное отношение вашей собаки к манипуляциям в пасти служит верным доказательством доверия
                    вашего ротвейлера к вам и является во многом свидетельством общей воспитанности животного.<br/><br/>

                    &nbsp;&nbsp;&nbsp; 2.     Если вы планируете заняться выставочной карьерой вашего питомца, то без знания команды «Зубы»
                    на выставки вам лучше не ходить. Дело в том, что оценка щенка (или взрослой собаки) начинается с
                    осмотра зубов. И то, как вы покажете зубы оставит первое (и как известно очень важное!) впечатление
                    у эксперта от вашей собаки.<br/><br/>

                    &nbsp;&nbsp;&nbsp; Каких только ситуаций мне не приходилось наблюдать в рингах! Владельцы садились на своих собак
                    верхом, вставали на колени и даже умоляли своих невоспитанных питомцев «показать тете зубки», но
                    в ответ, в большинстве своем, они получали в лучшем случае презрительный и прохладно оценивающий
                    взгляд, а в худшем - хищный оскал и щелканье молодыми, но уже вполне способными укусить зубами.
                    Каков результат? Испорченное настроение, испачканная в результате этой «зубной корриды» одежда,
                    а хуже того - покусанные в кровь руки. Зрелище, смею вас заверить, не из приятных. Поэтому призываю
                    всех, кто хочет иметь хорошо воспитанную собаку, дабы не попасть с ней в глупейшее положение -
                    учите щенка показывать зубы с 45-дневного возраста. Это очень просто. Следует посадить щенка рядом
                    с собой или взять его на руки, затем приоткрыть пасть и внимательно осмотреть его зубы, оттянуть
                    уголок рта в сторону и ощупать пальцем каждый зуб. Малейшее сопротивление этой процедуре жестко
                    пресекайте и снова повторяйте свои действия. После окончания осмотра похвалите щенка и дайте ему
                    лакомство.<br/><br/>

                    &nbsp;&nbsp;&nbsp;Команда «фу» должна действовать в любых ситуациях на любую «горячую голову». Вырабатывайте эту
                    команду методично, в течение 2-4 недель. Если вы заметили, что > щенок делает что-то нежелательное
                    для вас (грызет мебель, обувь, подбирает что-то на его взгляд съедобное на улице) следует подойти
                    к воспитаннику и прекратить его действия настойчиво повторяя команду «фу» в угрожающей интонации.
                    Вне дома приучать щенка этой команде лучше всего на поводке.<br/><br/>

                    &nbsp;&nbsp;&nbsp; Для тех, кто решит участвовать в выставках, следует также научить щенка выставочной стойке, но это
                    уже тема отдельного разговора.<br/><br/>

                    &nbsp;&nbsp;&nbsp;Итак, подведем итог правилу ¹4: «Тот, кто хочет иметь послушного ротвейлера, должен научить своего
                    щенка следующим приемам: откликание на кличку, подход «ко мне», «лежать, место», «зубы», «фу».
                    Запомните, что без этих элементарных «контактных» команд дальнейшее обучение будет очень затруднено».<br/><br/>

                    &nbsp;&nbsp;&nbsp;Пятым пунктом мне хотелось бы отметить социализацию молодого животного. Для того, чтобы ваш щенок
                    психологически полноценно развивался, необходимо как можно чаще гулять с ним в людных местах,
                    приучать к желаемому вами поведению в автомобиле, позволить общаться щенку с людьми, к которым
                    он должен в последствии относиться безразлично. Первоначальный «щенячий» восторг от общения быстро
                    исчезнет, если посторонние отнесутся к интересу щенка с пониманием; посторонний человек может лишь
                    легко погладить щенка, но не следует позволять давать ему лакомство.<br/><br/>

                    &nbsp;&nbsp;&nbsp;Итак: «Уравновешенного и спокойного ротвейлера можно воспитать лишь при условии
                    своевременной и грамотной социальной адаптации».<br/><br/>

                    &nbsp;&nbsp;&nbsp;Последним, шестым пунктом (самое важное, как известно говорится в последнюю очередь, так как
                    запоминается лучше) я бы назвала вопросы, непосредственно касающиеся пресловутого «подчеркнуто
                    доминантного» поведения ротвейлера или, про ще говоря, врожденной агрессивности. Не скрою, что в
                    миру бытует мнение о ротвейлере, как о безнадежно тупой, агрессивной и упрямой собаке. Со своей
                    стороны замечу, что назвать ротвейлера агнцем невозможно, ведь даже стандарт породы констатирует
                    хорошие охранные и боевые качества собаки. Порода и создавалась для того, чтобы охранять своего
                    хозяина и его имущество от недоброжелателей. Все, кто знаком с историей ротвейлера (тех, кто не
                    знаком, отсылаю к массовой кинологической литературе) знают легендарные истории о том, как
                    столетиями немецкие мясники доверяли своим ротвейлерам кошельки с деньгами, прикрепляя их к ошейнику
                    собаки, а также, ставшую, если можно так сказать, классической историю о «гамбургской драке».
                    Эти факты свидетельствуют о том, что ротвейлер - хороший телохранитель и сторож. Разумеется, чтобы
                    соответствовать стандарту, собаки этой породы имеют достаточно высокий врожденный уровень
                    доминантности. И в силах человека - хозяина направить эту доминантность в нужное русло.<br/><br/>

                    &nbsp;&nbsp;&nbsp;Ежедневные физические тренировки, пробежки, прыжки, дрессировка и участие
                    в выставках отнимут у собаки избытки энергии. Если же ваш ротвейлер недостаточно физически загружен, не дрессирован и
                    хронически запущен в интеллектуальном развитии - не удивлюсь, если мнение неопытной толпы совпадет
                    с реальностью. Думаю, что приведенное ниже сравнение будет уместным: хорошо воспитанный,
                    образованный и окруженный надлежащим вниманием ребенок - радость семьи. Запущенные, или, наоборот,
                    излишне заласканные или невоспитанные дети в 99 случаев из 100 поднимут в последствии руку на своих
                    же родителей или других членов общества. Другими словами - ваш ротвейлер такой же член вашей семьи
                    и общества, как и вы сами. И в ваших же интересах приложить все усилия для того, чтобы красотой и
                    умом этой замечательной собаки наслаждались не только вы, ной все окружающие. Решить вопрос с
                    несанкционированным проявлением агрессии очень просто. Соблюдайте все вышеизложенные правила! И,
                    скорее всего, каких-либо специальных мер вам не потребуется. Но если все же конфликт возник, то
                    достаточно будет двух-трех серьезных «встрясок» за холку или резких рывков за удавку и конфликт
                    будет исчерпан. Поверьте, что действительно психически ненормальные ротвейлеры встречаются так
                    же редко, как и собаки с отклонениями в психике в других породах. Это редкое, к счастью, исключение.
                    Во всех остальных случаях вопросы послушания ротвейлера -всего лишь вопросы грамотного воспитания.<br/><br/>

                    &nbsp;&nbsp;&nbsp;Итак: «Запомните! Если вы хотите иметь послушного и хорошо управляемого ротвейлера - будьте
                    требовательными прежде всего к себе! Не упускайте возможность физически и интеллектуально
                    нагрузить вашего питомца, с детства подавляйте в нем животную агрессивность и прививайте
                    те черты по ведения, которые не заставят вас краснеть за своего любимца, а будут приносить
                    радость и гордость за чудесно воспитанное, сильное и красивое животное».<br/><br/>

                    &nbsp;&nbsp;&nbsp; Подведем итог. Надеюсь, что правила, приведенные в этой статье, просты и понятны
                    каждому.
                    Тем, кто считает себя неспособным выполнить их самостоятельно, лучше обратиться за консультацией к
                    опытному инструктору-дрессировщику. Занятия с ротвейлером потребуют от вас достаточно много времени
                    и сил, но, повторяю еще раз, - в конце обучения вы получите чувство огромного удовлетворения от
                    общения со своим замечательным питомцем.<br/><br/>

                    &nbsp;&nbsp;&nbsp;Мудрые слова знатока человеческих душ Д. Карнеги, вынесенные в эпиграф этой статьи,
                    справедливы и
                    в нашем случае. Неправильно воспитанная собака - это лишь слепок ошибок хозяина и не стоит негативно
                    критиковать целую породу из-за ошибочных действий одиночных владельцев. Завершить эти строки хотелось
                    бы моей любимой цитатой гениального знатока собачьих душ - немецкого кинологах. Й.Сваровски:
                    “Jeder hat den Hund, den er verdient” - «Каждый имеет ту собаку, которую заслуживает». Думаю,
                    что прочитанные строки этой статьи помогут «заслужить» владельцу только самого воспитанного и
                    лучшего ротвейлера.
                </p>
            </div>
            <div ><p>Ольга ГРИНЬ</p></div>
        </div>
    );
}
export default RottweilerEducation;